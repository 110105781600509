import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styles from './styles.module.scss';

const Spinner: React.FC = () => (
  <div className={styles.spinnerContainer}>
    <CircularProgress />
  </div>
);

export default Spinner;
