import React,
{
  useState,
} from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  useStores,
  // useLocalization,
} from 'hooks';
import {
  Container,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  // Link,
  Typography,
} from '@material-ui/core';
import {
  Assets,
} from 'assets';
import {
  useFormik,
} from 'formik';
import * as Yup from 'yup';
import {
  useHistory,
} from 'react-router-dom';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import {
  useObserver,
} from 'mobx-react';
import {
  baseScreen,
} from 'hoc';
// import {
//   LanguagesKey,
// } from 'src/translations/types';
import i18n from 'i18n-js';
import {
  Styles,
} from './styles';

const useStyles = makeStyles(Styles);
const login: React.FC = () => {
  const validationSchema = Yup.object({
    email: Yup
      .string()
      .email(i18n.t('INVALID_FIELD'))
      .required(i18n.t('REQUIRED_FIELD')),
    password: Yup
      .string()
    // .min(8, 'Password should be of minimum 8 characters length')
      .required(i18n.t('REQUIRED_FIELD')),
  });

  const {
    backend: {
      auth,
    },
  } = useStores();
  const [
    error,
    setError,
  ] = useState<string>();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        // setLoading(true)
        await auth.login(values);
        await auth.getUser();
        history.replace('/');
      } catch (err) {
        setError(err.toString());
        throw err;
      } finally {
        // setLoading(false);
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const classes = useStyles();
  // const {
  //   currentLanguage,
  //   setLanguage,
  // } = useLocalization();
  return useObserver(() => (
    <Container className={classes.root}>
      {/* eslint-disable-next-line */}
        {/* <Link href="#" onClick={() => setLanguage({
          isRTL: currentLanguage.key !== LanguagesKey.AR,
          key: currentLanguage.key === LanguagesKey.AR ? LanguagesKey.EN : LanguagesKey.AR,
          title: '',
        })}
        >
          {currentLanguage.key === LanguagesKey.AR ? 'English' : 'العربيه'}
        </Link> */}

      <img src={Assets.images.common.logo} alt="logo" className={classes.logo} />

      <Container component="form" onSubmit={formik.handleSubmit} maxWidth="sm" className={classes.loginForm}>
        <TextField
          id="email"
          name="email"
          placeholder={i18n.t('EMAIL')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          variant="filled"
          fullWidth
        />
        <TextField
          id="password"
          name="password"
          placeholder={i18n.t('PASSWORD')}
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          variant="filled"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  onMouseDown={toggleShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* eslint-disable-next-line */}
        <Typography
          style={{
            marginTop: 10,
            width: '100%',
          }}
          color="error"
        >
          {error}
        </Typography>
        {/* <a
          href="/forget-password"
          className={classes.forgetPassword}
          // onClick={() => alert('for get password')}
        >
          {i18n.t('FORGOT_PASSWORD')}
        </a> */}
        <Button type="submit" variant="outlined" className={classes.loginButton}>
          {i18n.t('LOGIN')}
        </Button>
      </Container>
    </Container>
  ));
};

export const Login = baseScreen(login, {
  allowedRoles: ['ADMIN', 'NONE'],
});
