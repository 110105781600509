import {
  InsuranceCompaniesDTO,
} from 'src/stores/backend/InsuranceCompaniesStore/types';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  BaseFormViewModel,
} from '../types';

export class InsuranceCompany extends BaseFormViewModel<InsuranceCompaniesDTO> {
  id?: number;

  englishName?: string;

  arabicName?: string;

  logo?: string;

  constructor(data: InsuranceCompaniesDTO) {
    super();
    this.id = data.id;
    this.englishName = data.translations?.filter((item) => item.languageCode === LanguagesKey.EN)[0]?.title;
    this.arabicName = data.translations?.filter((item) => item.languageCode === LanguagesKey.AR)[0]?.title;
    this.logo = data.logoURL;
  }

  toDTO(): InsuranceCompaniesDTO {
    return { } as InsuranceCompaniesDTO;
  }
}
