import {
  createStyles,
  InputLabel,
  withStyles,
} from '@material-ui/core';

const CustomSelectInputLabel = withStyles(() => createStyles({
  formControl: {
    transform: 'translate(0, 20px) scale(1)',
  },
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.75);',
  },
}))(InputLabel);

export default CustomSelectInputLabel;
