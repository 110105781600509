/* eslint-disable */
import {
  LanguagesKey,
} from './types';

export const TRANSLATIONS = {
  [LanguagesKey.EN]: {
    '': '',
    EMAIL: 'Email',
    EMAIL_VALIDATION: 'Email is not valid',
    PHONE_VALIDATION: 'Mobile number is not valid',
    PASSWORD: 'Password',
    NEED_HELP: 'Need Help?',
    FORGOT_PASSWORD: 'Forgot Password?',
    LOGIN: 'Login',
    SIGNUP: 'Sign in',
    REQUIRED_FIELD: 'This field is required',
    ACCEPTS_LETTERS_ONLY: 'Accepts letters only',
    ACCEPTS_NUMBERS_ONLY: 'Accepts numbers only',
    INVALID_FIELD: 'This field is invalid',
    WRONG_CREDENTIALS: 'Wrong email or password',
    RECENTLY_VIEWED: 'Recently Viewed',
    ACTIVATE_PATIENT_CONFIRMATION: 'Are you sure you want to activate this patient?',
    BLOCK_PATIENT_CONFIRMATION: 'Are you sure you want to block this patient?',
    DEACTIVATE_PATIENT_CONFIRMATION: 'Are you sure you want to deactivate this patient?',
    UNBLOCK_PATIENT_CONFIRMATION: 'Are you sure you want to unblock this patient?',
    HOME: 'Home',
    LOGOUT: 'Logout',
    SEARCH: 'Search',
    TRANSACTION_TYPE: 'Transaction Type',
    TRANSACTION_ID: 'Transaction ID',
    BANK_NAME: 'Bank Name',
    SETTLEMENT: 'Settlement',
    DEACTIVATE_DR: 'Deactivate Doctor',
    FIXED: 'Fixed',
    SYNDICATE_ID_VERIFICATION: 'Sydicate ID Verification',
    INVALID_NATIONAL_ID: 'Invalid national ID',
    ACTIVATE_DR: 'Activate Doctor',
    RECEIPT: 'Receipt',
    SERVICES: 'Services',
    IS_ACCEPTING_DONATION: 'Accept Donation',
    IS_ACTIVE: 'Active',
    IS_BANNED: 'Ban',
    DOCTORS: 'Doctors',
    PATIENTS: 'Patients',
    PAYMENT: 'Payment',
    PROFILE: 'Profile',
    ALL: 'All',
    TYPE: 'Type',
    HOME_VISITS: 'Home visits',
    ONLINE_CONSULTATION: 'Online consultation',
    VIEW: 'View',
    DASHBOARD: 'Dashboard',
    EXPORT_DATA: 'Export Data in external csv file',
    CLINICS: 'Clinic',
    DATE: 'Date',
    ID: 'ID',
    DOCTOR_PHONE: 'Doctor Phone',
    DOCTOR: 'Doctor',
    STATUS: 'Status',
    TIME: 'Time',
    PATIENT: 'Patient',
    PATIENT_PHONE: 'Patient Phone',
    DELETE: 'Delete',
    Male: 'Male',
    Female: 'Female',
    DELETE_DOCTOR_CONFIRMATION: 'Are you sure you want to delete this doctor?',
    DELETE_PATIENT_CONFIRMATION: 'Are you sure you want to delete this patient?',
    DELETE_SPECIALITY_CONFIRMATION: 'Are you sure you want to delete this speciality?',
    DELETE_INSURANCE_COMPANY_CONFIRMATION: 'Are you sure you want to delete this insurance company?',
    DELETE_PROMO_CODES_CONFIRMATION: 'Are you sure you want to delete this promocode?',
    DELETE_SERVICE_PROVIDER_TYPE_CONFIRMATION: 'Are you sure you want to delete this service provider type?',
    DELETE_GRADES_CONFIRMATION: 'Are you sure you want to delete this grade?',
    EDIT: 'Edit',
    CONSULTATION_TYPE: 'Consultation Type',
    DISCOUNT_TYPE: 'Discount type',
    AMOUNT: 'Amount',
    MAX_TIMES_OF_USAGE: 'Max. times of usage',
    PERCENTAGE: 'Percentage',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    ADD_NEW_PROMO_CODE: 'Add new promocode',
    BANK_ACCOUNT: 'Bank Account',
    WALLET: 'Wallet',
    ADD_NEW_DOCTOR: 'Add new doctor',
    ADD_NEW_PATIENT: 'Add new patient',
    ADD_NEW_GRADE: 'Add new grade',
    ADD_NEW_ADMIN: 'Add new admin',
    ADD_NEW_ACCOUNTANT: 'Add new accountant',
    ADD_NEW_INSURANCE_COMPANY: 'Add new Insurance company',
    CODE: 'Code',
    DUE_DATE: 'Due Date',
    DEACTIVATE_DOCTOR_CONFIRMATION: 'Are you sure you want to deactivate this doctor?',
    ACTIVATE_DOCTOR_CONFIRMATION: 'Are you sure you want to activate this doctor?',
    ACTIVATE_ADMIN_CONFIRMATION: 'Are you sure you want to activate this admin?',
    ACTIVATE_ACCOUNTANT_CONFIRMATION: 'Are you sure you want to activate this accountant?',
    DEACTIVATE_ADMIN_CONFIRMATION: 'Are you sure you want to deactivate this admin?',
    DEACTIVATE_ACCOUNTANT_CONFIRMATION: 'Are you sure you want to deactivate this accountant?',
    DEACTIVATE_PROMOCODE_CONFIRMATION: 'Are you sure you want to deactivate this promo code?',
    ACTIVATE_SERVICE_PROVIDER_CONFIRMATION: 'Are you sure you want to activate this service provider?',
    ACCEPT_DONATION_SERVICE_PROVIDER_CONFIRMATION: 'Are you sure you want to confirm accepting donation for this service provider?',
    REJECT_DONATION_SERVICE_PROVIDER_CONFIRMATION: 'Are you sure you want to reject accepting donation this service provider?',
    DEACTIVATE_SERVICE_PROVIDER_CONFIRMATION: 'Are you sure you want to deactivate this service provider?',
    DELETE_ADMINS_CONFIRMATION: 'Are you sure you want to delete this admin?',
    DELETE_ACCOUNTANTS_CONFIRMATION: 'Are you sure you want to delete this accountant?',
    DELETE_SERVICE_PROVIDERS_CONFIRMATION: 'Are you sure you want to delete this service provider?',
    ACTIVATE_SERVICE_PROVIDERS_CONFIRMATION: 'Are you sure you want to activate this service provider?',
    ACTIVATE_PROMOCODE_CONFIRMATION: 'Are you sure you want to activate this promo code?',
    PROMOCODE_CONFIRMATION_MESSAGE: 'Promo Code has been added successfully',
    ADMIN_CONFIRMATION_MESSAGE: 'Admin has been added successfully',
    SAVE: 'Save',
    AVATAR: 'Avatar',
    NAME: 'Name',
    SYNDICATE_ID: 'Syndicate ID',
    WEBSITE: 'Website',
    INSTAPAY: "Instapay Url",
    PROMO_CODE_CONFIRMATION: 'Promocode confirmarion',
    REQUEST_ID: 'Request ID',
    DR_ID: 'Doctor ID',
    TOTAL: 'Total',
    KASHF_PERCENTAGE: 'Kashf Percentage',
    SUBTOTAL: 'Subtotal',
    SELECT_SERVICE_PROVIDER: 'Select service provider',
    SPECIALITY: 'Speciality',
    AUDIENCE: 'Audiences',
    SPECIALITIES: 'Specialities',
    ROLE: 'Role',
    ADD_NEW_SERVICE_PROVIDER: 'Add new service provider',
    ADD_NEW_TYPE: 'New Type',
    GRADE: 'Grade',
    Clinic: 'Clinic',
    'Home Visit': 'Home Visit',
    'Online Consultation': 'Online Consultation',
    HOME_VISIT: 'Home Visit',
    CLINIC: 'Clinic',
    MOBILE_NUMBER: 'Mobile Number',
    COUNTRY: 'Country',
    ACTIVATE: 'Activate',
    APPROVE: 'Approve',
    REJECT: 'Reject',
    LAST_CHECKIN: 'Last check in',
    SyndicateID_NOT_APPLIED_TOOLTIP: 'Syndicate ID not provided yet',
    SETTLE: 'Settle',
    NATIONAL_ID: 'National ID',
    ACCESSIBILITY: 'Accessibility',
    SyndicateID_NEW_TOOLTIP: 'Click to approve the syndicate ID',
    EASY_CASH_LINK: 'Add link',
    SyndicateID_APPROVED_TOOLTIP: 'Syndicate ID is approved',
    PASSWORD_MUST_MATCH: `Password doesn't match`,
    SyndicateID_REJECTED_TOOLTIP: 'Syndicate ID is rejected',
    NOT_APPROVED_TOOLTIP: 'Click to approve the syndicate ID',
    ABOUT_LENGTH_VALIDATION: 'Bio should be at most 500 characters',
    APPROVED_TOOLTIP: 'Syndicate ID is approved',
    ADD_DOCTOR_VERIFICATION_MESSAGE: 'An email will be sent to the doctor shortly, Thank you.',
    NOTES: 'Notes',
    CERTIFICATE_ID: 'Certificate ID',
    LANDLINE_NUMBER: 'Landline Number',
    CUSTOM_NOTIFIVATION_CONFIRMATION_MESSAGE: 'Notification has been added successfully',
    CLICK_TO_COPY: 'Click to copy',
    GENDER: 'Gender',
    SERVICE_PROVIDER: 'Service Provider',
    HOSPITALS_AND_CENTERS: 'Hospitals/centers',
    INVALID_PASSWORD: 'Password should be at least 8 characters',
    INVALID_EMAIL: 'Invalid E-mail',
    GRADES: 'Grades',
    ADD: 'Add',
    INVALID_SYNDICATE_ID: 'Invalid Syndicate ID',
    SEND_NOTIFICATION: 'Send Notification',
    ARABIC_TEXT: 'Arabic Text',
    ENGLISH_TEXT: 'English Text',
    CASH: 'Cash',
    CREDIT_CARD: 'Credit card',
    DOCTOR_NOT_FOUND: 'Doctor Not Found',
    REQUESTS_LIST: 'Consultation Requests:',
    CHANGE_LANGUAGE: 'Change Language',
    DESCRIPTION: 'Description',
    NATIONAL_ID_FRONT: 'National ID Front',
    ABOUT_DOCTOR_EN: 'About Doctor (English)',
    ABOUT_DOCTOR_AR: 'About Doctor (Arabic)',
    PATIENT_DETAILS: 'Patient Details',
    INVALID_WEBSITE: 'invalid value ex(http://www.google.com)',
    ENGLISH_NAME: 'English name',
    NATIONAL_ID_BACK: 'National ID Back',
    FILE_PICKER_FILED_LENGTH: 'Attach only 1 item',
    ARABIC_NAME: 'Arabic name',
    SPECIFIC_COUNTRY: 'Specific country',
    TAX_ID: 'Tax ID',
    AGE: 'Age',
    IMAGE: 'Image',
    CITY: 'City',
    FROM: 'From',
    TO: 'To',
    ADDRESS: 'Address',
    CERTIFICATES: 'Certificates',
    LOGO: 'Logo',
    COUNTRY_CODE: 'Country Code',
    DOCTOR_CONFIRMATION_MESSAGE: 'Doctor saved successfully',
    SERVICE_PROVIDER_CONFIRMATION_MESSAGE: 'Service provider added successfully',
    INVALID_VALUE: 'Invalid Value',
    CEMMERCIAL_RECORD: 'Commercial Record',
    SERVICE_PROVIDERS_FORM: 'Service Provider',
    RESET: 'Reset',
    GOVERNORATE: 'Governorate',
    OK: 'Ok',
    AREA: 'Area',
    LOCATION: 'Location',
    COORDINATOR_NAME: 'Coordinator name',
    FILTER: 'Filter',
    CONFIRM_PASSWORD: 'Confirm password',
    ADD_ADMIN: 'Add Admin',
    HOSPITALS: 'Hospitals',
    COORDINATOR_PHONE: 'Coordinator phone',
    PROMOCODE: 'Promocode',
    COMMERCIAL_RECORD: 'Commercial Record',
    SUBMIT: 'SUBMIT',
    ACTIVITY_LOGS: 'Activity Logs',
    STARTED_AT: 'Started at',
    FINISHED_AT: 'Finished at',
    COPIED: 'Copied!',
    GENERATE_LINK: 'Generate link',
    CERTIFICATES_AND_OTHER_DOCS: 'Certificates and Other Docs',
    TO_NEXT_LANGUAGE: 'To next language',
    DROP_FILES_HERE: 'Drop files here... ',
    TERMS_AND_CONDITIONS_AGREEMENT: 'I agree to all terms and conditions',
    DRAG_AND_DROP_FILES_HERE: 'Drag and Drop Files here',
    HOME_VISIT_SCHEDULE: 'Home Visit Schdecule',
    ONLINE_CONSULTATION_SCHEDULE: 'Online Consultation Schdecule',
    CLINIC_SCHEDULE: 'Clinic Schdecule',
    HOME_VISIT_FEES: 'Home Visit Fees',
    ONLINE_CONSULTATION_FEES: 'Online Consultation Fees',
    SCHEDULE_WRONG_ENTRY: 'Wrong entry, Please try again',
    KASHF: 'Kashf',
    REGISTERATION_THANK_YOU_MESSAGE: 'Thank you for registering with kashf. We will contact you soon.',
    CLINIC_FEES: 'Clinic Fees',
    USERS: 'Users',
    BOOKINGS: 'Booking Requests',
    BOOKING_REQUESTS: 'Booking Requests',
    SYSTEM_SETUP: 'System Setup',
    PROMO_CODES: 'Promo Codes',
    NOTIFICATIONS: 'Notifications',
    NOTIFICATIONS_FORM: 'Send Notifications',
    DR: 'Doctor',
    BALANCE: 'Balance',
    SERVICE_PROVIDERS: 'Service providers',
    SERVICE_PROVIDER_TYPES: 'Service Provider Types',
    SERVICE_PROVIDER_TYPE_FORM: 'Service Provider Types',
    ADMINS: 'Admins',
    ACCOUNTANTS: 'Accountants',
    ARABIC_TITLE: 'Arabic Title',
    ENGLISH_TITLE: 'English Title',
    ADD_NEW_SPECIALITY: 'New speciality',
    LANGUAGES: 'Languages',
    BASIC_DETAILS: 'Basic Details',
    CLINICS_DETAILS: 'Clinics Details:',
    FEES: 'Fees',
    DOCTOR_DETAILS: 'Doctor Details',
    INSURANCE_COMPANIES: 'Insurance Companies',
    MEMBERSHIP_DISCOUNTS: 'Membership Discount',
    MEMBERSHIPS: 'Memberships',
    DISCOUNT_VALUE: 'Discount Value',
    VALID_UNTIL: 'Valid Until',
    ADD_NEW_MEMBERSHIP: 'add new membership',
    USER_COMPLETED_DATA: 'Completed',
    ACCEPTS_ARABIC_LETTERS_ONLY: 'Accepts Arabic letters only',
    DONATION: 'Donations',
    DONATION_MONTHLY_BREAKDOWN: 'Donation Monthly breakdown',
    GROSS_REVENUE: 'Gross revenue',
    NET_REVENUE: 'Net revenue',
    REFUND_PERCENTAGE: 'Refund Percentage',
    NATIONALITY: 'Nationality',
    NATIONALITIES: 'Nationalities',
    HIGH_PRICE : 'Higher Pricing',
    SECRETERY_EMAIL: 'Secretary Email',
    HELP_CENTER:'Help Center',
    CATEGORIES: 'Categories',
    CATEGORY: 'Category',
    USER: 'User',
    COMPLAINTS: 'Complaints',
    PHONE: 'Phone',
  },
  [LanguagesKey.AR]: {
    '': '',
    ARABIC_TITLE: 'الاسم بالعربى',
    CLINICS_DETAILS: ':العيادات',
    INSURANCE_COMPANIES: 'شركات التأمين',
    ENGLISH_TITLE: 'الاسم بالانجليزى',
    ADMINS: 'المسئولين',
    ACTIVATE: 'تفعيل',
    FEES: 'الاتعاب',
    BASIC_DETAILS: 'التفاصيل',
    DOCTOR_DETAILS: 'التفاصيل',
    LANGUAGES: 'اللغات',
    PATIENT_DETAILS: 'تفاصيل المريض',
    SERVICE_PROVIDERS: 'مقدمي الخدمة',
    USERS: 'المستخدمين',
    BOOKINGS: 'طلبات الحجز',
    ADD_NEW_SPECIALITY: 'إضافة تخصص',
    ADD_NEW_GRADE: 'إضافة درجة علمية',
    ADD_NEW_INSURANCE_COMPANY: 'إضافة شركة تأمين',
    BOOKING_REQUESTS: 'طلبات الحجز',
    SYSTEM_SETUP: 'النظام',
    PROMO_CODES: 'برمو كود',
    SYNDICATE_ID_VERIFICATION: 'فحص بطاقة النقابة',
    SERVICE_PROVIDER_TYPES: 'أنواع مقدمى الخدمه',
    SERVICE_PROVIDER_TYPE_FORM: 'أنواع مقدمى الخدمه',
    NOTIFICATIONS: 'الاشعارات',
    NOTIFICATIONS_FORM: 'ارسال اشعار',
    AUDIENCE: 'المستقبل',
    EMAIL_VALIDATION: 'البريد الالكترونى غير صالح',
    PHONE_VALIDATION: 'رقم الهاتف غير صالح',
    BALANCE: 'الرصيد',
    LANDLINE_NUMBER: 'رقم الخط الارضي',
    SCHEDULE_WRONG_ENTRY: 'خطأ حاول مره اخرى',
    INVALID_WEBSITE: 'غير صحيح مثال: http://www.google.com',
    INVALID_PASSWORD: 'كلمة المرور يجب الا تقل عن 8 حروف',
    EMAIL: 'البريد الالكترونى',
    ARABIC_NAME: 'الاسم العربي',
    REGISTERATION_THANK_YOU_MESSAGE: 'شكرا للتسجيل لدى كشف. سوف نتصل بك لاحقا',
    KASHF: 'كشف',
    INVALID_SYNDICATE_ID: 'رقم النقابة غير صالح',
    COPIED: 'تم النسخ!',
    PROMO_CODE_CONFIRMATION: 'برومو كود',
    ACCEPTS_LETTERS_ONLY: 'يقبل حروف فقط',
    ACCEPTS_NUMBERS_ONLY: 'يقبل أرقام فقط',
    HOME_VISIT_SCHEDULE: 'جدول الزياره المنزليه',
    FILE_PICKER_FILED_LENGTH: 'قم بإدخال ملف واحد فقط',
    INVALID_EMAIL: 'البريد الالكترونى غير صالح',
    ONLINE_CONSULTATION_SCHEDULE: 'جدول الاستشارات الاونلاين',
    CLINIC_SCHEDULE: 'جدول العيادة',
    HOME_VISIT_FEES: 'أتعاب الزياره المنزليه',
    ONLINE_CONSULTATION_FEES: 'أتعاب الاستشاره الاونلاين',
    HOSPITALS_AND_CENTERS: 'المستشفيات/المراكز الطبية',
    SELECT_SERVICE_PROVIDER: 'من فضلك أختر مقدم الخدمة',
    CLINIC_FEES: 'أتعاب العيادة',
    ENGLISH_NAME: 'الاسم الانجليزى',
    CERTIFICATES_AND_OTHER_DOCS: 'شهادات ووثائق اخري',
    ADD_DOCTOR_VERIFICATION_MESSAGE: 'سوف يتم إرسال ايميل للدكتور. شكرا لك.',
    DESCRIPTION: 'الوصف',
    CASH: 'كاش',
    CREDIT_CARD: 'بطاقة ائتمانية',
    PROMOCODE: 'بروموكود',
    TERMS_AND_CONDITIONS_AGREEMENT: 'أوافق على الشروط والاحكام',
    ROLE: 'الدور',
    DRAG_AND_DROP_FILES_HERE: 'اسحب الملفات وضعها هنا',
    DROP_FILES_HERE: 'ضع الملفات هنا',
    CITY: 'المدينه',
    DOCTOR_PHONE: 'رقم الطبيب',
    ABOUT_LENGTH_VALIDATION: 'يجب الا يزيد هذا الحقل عن 500 حرف',
    OK: 'موافق',
    SUBMIT: 'إرسال',
    AREA: 'المنطقه',
    COMMERCIAL_RECORD: 'الرقم التجاري',
    DUE_DATE: 'تاريخ الانتهاء',
    DASHBOARD: 'لوحة التحكم',
    TO_NEXT_LANGUAGE: 'اللغه التاليه',
    TRANSACTION_TYPE: 'نوع التحويل البنكى',
    SERVICE_PROVIDER_CONFIRMATION_MESSAGE: 'تم إضافة مقدم الخدمة بنجاح',
    TRANSACTION_ID: 'رقم التحويل',
    BANK_NAME: 'أسم البنك',
    SETTLEMENT: 'التسوية',
    COORDINATOR_PHONE: 'رقم الهاتف',
    SPECIALITIES: 'التخصصات',
    NATIONAL_ID_FRONT: 'الصوره الاماميه لبطاقة الرقم القومى',
    ABOUT_DOCTOR_EN: 'عن الطبيب(انجليزى)',
    ABOUT_DOCTOR_AR: 'عن الطبيب(عربي)',
    TAX_ID: 'البطاقه الضريبيه',
    REQUEST_ID: 'رقم الطلب',
    DR_ID: 'رقم الطبيب',
    TOTAL: 'المجموع الكلي',
    KASHF_PERCENTAGE: 'نسبة كشف',
    SUBTOTAL: 'المجموع الجزئى',
    DEACTIVATE_DOCTOR_CONFIRMATION: 'هل انت متأكد من الغاء تفعيل هذا الطبيب؟',
    ACTIVATE_ADMIN_CONFIRMATION: 'هل انت متأكد من تفعيل هذا المسئول؟',
    DEACTIVATE_ADMIN_CONFIRMATION: 'هل انت متأكد من الغاء تفعيل هذا المسئول؟',
    ACTIVATE_DOCTOR_CONFIRMATION: 'هل انت متأكد من تفعيل هذا الطبيب؟',
    DEACTIVATE_PROMOCODE_CONFIRMATION: 'هل انت متأكد من الغاء تفعيل هذا الكود؟',
    ACTIVATE_SERVICE_PROVIDER_CONFIRMATION: 'هل انت متأكد من تفعيل مقدم الخدمة؟',
    DEACTIVATE_SERVICE_PROVIDER_CONFIRMATION: 'هل انت متأكد من الغاء تفعيل مقدم الخدمة؟',
    ACTIVATE_PROMOCODE_CONFIRMATION: 'هل انت متأكد من تفعيل هذا الكود؟',
    DELETE_ADMINS_CONFIRMATION: 'هل انت متأكد من حذف هذا المستخدم؟',
    PROMOCODE_CONFIRMATION_MESSAGE: 'تم إضافة الكود بنجاح',
    ADMIN_CONFIRMATION_MESSAGE: 'تم إضافة الكود بنجاح',
    CUSTOM_NOTIFIVATION_CONFIRMATION_MESSAGE: 'تم إضافة الاشعار بنجاح',
    GRADES: 'الدرجات العلمية',
    SAVE: 'حفظ',
    ACCESSIBILITY: 'إمكانية الوصول',
    PASSWORD_MUST_MATCH: `كلمة السر غير مطابقة`,
    HOME_VISIT: 'زيارة منزلية',
    RECENTLY_VIEWED: 'شوهدت مؤخرا',
    ACTIVATE_PATIENT_CONFIRMATION: 'هل أنت متأكد من تفعيل هذا المريض؟',
    DEACTIVATE_PATIENT_CONFIRMATION: 'هل أنت متأكد من الغاء تفعيل هذا المريض؟',
    BLOCK_PATIENT_CONFIRMATION: 'هل أنت متأكد من حظر هذا المريض؟',
    UNBLOCK_PATIENT_CONFIRMATION: 'هل أنت متأكد من الغاء حظر هذا المريض؟',
    CLINIC: 'عيادة',
    NOT_APPROVED_TOOLTIP: 'اضغط هنا لتفعيل البطاقة النقابيه',
    APPROVED_TOOLTIP: 'البطافه النقابيه مفعله',
    NATIONAL_ID_BACK: 'الصوره الخلفيه لبطاقة الرقم القومى',
    CHANGE_LANGUAGE: 'تغيير اللغه',
    REQUESTS_LIST: ':طلبات الاستشاره',
    RESET: 'إعادة تعيين',
    DOCTOR_CONFIRMATION_MESSAGE: 'تم حفظ الطبيب بنجاح',
    CERTIFICATE_ID: 'الشهاده',
    CLICK_TO_COPY: 'انسخ الرابط',
    CONFIRM_PASSWORD: 'تأكيد كلمة السر',
    INVALID_NATIONAL_ID: 'الرقم القومى غير صحيح',
    NATIONAL_ID: 'الرقم القومى',
    LOCATION: 'الموقع',
    WEBSITE: 'الموقع الالكترونى',
    INSTAPAY: "Instapay عنوان",
    PATIENT_PHONE: 'رقم المريض',
    CERTIFICATES: 'الشهادات',
    LOGO: 'اللوجو',
    COUNTRY_CODE: 'كود الدولة',
    INVALID_VALUE: 'غير صالح',
    CEMMERCIAL_RECORD: 'السجل التجاري',
    SERVICE_PROVIDERS_FORM: 'مقدم خدمة',
    COORDINATOR_NAME: 'الاسم',
    HOSPITALS: 'المستشفيات',
    FROM: 'من',
    DR: 'الطبيب',
    TO: 'الى',
    PICKER_FILED_LENGTH: 'يجب ارفاق صورة واحده',
    BANK_ACCOUNT: 'حساب بنكى',
    WALLET: 'المحفظة',
    SETTLE: 'تسوية',
    GOVERNORATE: 'المحافظه',
    FILTER: 'فلتر',
    STARTED_AT: 'بدأت فى',
    FINISHED_AT: 'انتهى فى',
    ACTIVITY_LOGS: 'سجل الانشطه',
    PASSWORD: 'كلمة المرور',
    ADD_ADMIN: 'إضافة مسئول',
    GRADE: 'الدرجه العلميه',
    GENDER: 'النوع',
    SYNDICATE_ID: 'رقم النقابه',
    AGE: 'العمر',
    DOCTOR_NOT_FOUND: 'غير موجود',
    LAST_CHECKIN: 'أخر تسجيل دخول',
    Male: 'ذكر',
    Female: 'أنثي',
    MOBILE_NUMBER: 'رقم الهاتف',
    ADDRESS: 'العنوان',
    IMAGE: 'الصوره',
    GENERATE_LINK: 'إنشاء رابط',
    SPECIALITY: 'التخصص',
    EASY_CASH_LINK: 'أضف رابط',
    PATIENT: 'المريض',
    DOCTOR: 'الدكتور',
    NEED_HELP: 'تحتاج الى مساعده؟',
    SERVICE_PROVIDER: 'مقدم الخدمة',
    ADD_NEW_TYPE: 'إضافة نوع جديد',
    ADD_NEW_ADMIN: 'إضافة مسئول جديد',
    CODE: 'الكود',
    AVATAR: 'الصوره',
    NOTES: 'ملاحظات',
    NAME: 'الاسم',
    DELETE: 'حذف',
    STATUS: 'الحاله',
    VIEW: 'التفاصيل',
    PERCENTAGE: 'نسبة مئوية',
    ADD_NEW_DOCTOR: 'إضافة دكتور جديد',
    ADD_NEW_PATIENT: 'إضافة مريض جديد',
    TIME: 'الوقت',
    ID: 'الرقم',
    ADD: 'أضف',
    FORGOT_PASSWORD: 'نسيت كلمة المرور',
    EDIT: 'تعديل',
    DATE: 'التاريخ',
    TYPE: 'النوع',
    EXPORT_DATA: 'أستخراج البيانات فى ملف خارجي',
    ADD_NEW_SERVICE_PROVIDER: 'إضافة مقدم خدمة جديد',
    ALL: 'الكل',
    SPECIFIC_COUNTRY: 'دولة محددة',
    REJECT: 'رفض',
    DELETE_SPECIALITY_CONFIRMATION: 'هل أنت متأكد من حذف هذا التخصص؟',
    DELETE_SERVICE_PROVIDERS_CONFIRMATION: 'هل أنت متأكد من حذف مقدم الخدمة؟',
    ACTIVATE_SERVICE_PROVIDERS_CONFIRMATION: 'هل أنت متأكد من تفعيل مقدم الخدمة؟',
    FIXED: 'رقم ثابت',
    APPROVE: 'تأكيد',
    DELETE_DOCTOR_CONFIRMATION: 'هل أنت متأكد من حذف هذا الطبيب؟',
    DELETE_PATIENT_CONFIRMATION: 'هل أنت متأكد من حذف هذا المريض؟',
    DELETE_PROMO_CODES_CONFIRMATION: 'هل أنت متأكد من حذف هذا الكود؟',
    CONSULTATION_TYPE: 'النوع',
    DISCOUNT_TYPE: 'نوع الخصم',
    AMOUNT: 'الكمية',
    MAX_TIMES_OF_USAGE: 'أقصى عدد الاستخدام',
    START_DATE: 'يبدأ فى',
    END_DATE: 'ينتهى فى',
    ADD_NEW_PROMO_CODE: 'إضافة كود جديد',
    DELETE_SERVICE_PROVIDER_TYPE_CONFIRMATION: 'هل أنت متأكد من حذف هذا النوع؟',
    DELETE_GRADES_CONFIRMATION: 'هل أنت متأكد من حذف هذه الدرجة العلمية؟',
    RECEIPT: 'الفاتوره',
    DELETE_INSURANCE_COMPANY_CONFIRMATION: 'هل أنت متأكد من حذف هذه الشركة؟',
    Clinic: 'عياده',
    'Home Visit': 'زيارة منزليه',
    'Online Consultation': 'أستشاره اونلاين',
    LOGIN: 'تسجيل الدخول',
    ONLINE_CONSULTATION: 'أستشارات اونلاين',
    CLINICS: 'عيادات',
    IS_ACCEPTING_DONATION: 'تفعيل استقبال التبرعات',
    IS_ACTIVE: 'مفعل',
    IS_BANNED: 'حظر',
    SIGNUP: 'إنشاء حساب جديد',
    HOME_VISITS: 'زيارات منزليه',
    REQUIRED_FIELD: 'هذا الحقل مطلوب',
    INVALID_FIELD: 'غير صالح',
    WRONG_CREDENTIALS: 'خطأ فى البريد الالكترونى او كلمة المرور',
    HOME: 'الرئيسية',
    LOGOUT: 'تسجيل الخروج',
    SEARCH: 'البحث',
    COUNTRY: 'الدولة',
    SERVICES: 'الخدمات',
    DEACTIVATE_DR: ' الغاء تفعيل',
    ACTIVATE_DR: 'تفعيل',
    DOCTORS: 'الاطباء',
    PATIENTS: 'المرضى',
    PAYMENT: 'الدفع',
    PROFILE: 'الصفحه الشخصيه',
    SyndicateID_NOT_APPLIED_TOOLTIP: 'البطاقة النقابية غيز موجودة',
    SyndicateID_NEW_TOOLTIP: 'اضغط للموافقة على البطاقة النقابية',
    SyndicateID_APPROVED_TOOLTIP: 'تم الموافقة على البطاقة النقابية',
    SyndicateID_REJECTED_TOOLTIP: 'تم رفض البطاقة النقابية',
    MEMBERSHIP_DISCOUNTS: 'خصم للعضويات',
    MEMBERSHIPS: 'العضويات',
    DISCOUNT_VALUE: 'قيمة الخصم',
    VALID_UNTIL: 'تاريخ انتهاء التفعيل',
    ADD_NEW_MEMBERSHIP: 'اضافه عضويه جديده',
    USER_COMPLETED_DATA: 'اكتمل',
    ACCEPTS_ARABIC_LETTERS_ONLY: 'يقبل حروف عربي فقط',
    DONATION: 'التبرعات',
    DONATION_MONTHLY_BREAKDOWN: 'الايراد الشهري من التبرعات',
    GROSS_REVENUE: 'إجمالي الإيرادات',
    NET_REVENUE: 'صافي الإيرادات',
    ACCOUNTANTS: 'المحاسبين',
    ADD_NEW_ACCOUNTANT: 'تم إضافة الكود بنجاح',
    ACTIVATE_ACCOUNTANT_CONFIRMATION: 'هل انت متأكد من تفعيل هذا المسئول؟',
    DEACTIVATE_ACCOUNTANT_CONFIRMATION: 'هل انت متأكد من الغاء تفعيل هذا المحاسب؟',
    DELETE_ACCOUNTANTS_CONFIRMATION: 'هل انت متأكد من حذف هذا المحاسب؟',
    REFUND_PERCENTAGE: 'نسبة الاسترجاع',
    NATIONALITY: 'الجنسيه',
    NATIONALITIES: 'الجنسيات',
    HIGH_PRICE : 'تسعير اعلي',
    SECRETERY_EMAIL: 'البريد الالكتروني للسكرتارية',
    HELP_CENTER: 'المساعدة',
    CATEGORIES: 'مصنفات',
    COMPLAINTS: 'شكاوي',
    PHONE: 'الهاتف',
  },
};
