import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  Assets,
} from 'assets';
import AddIcon from '@material-ui/icons/Add';
import {
  useHistory,
} from 'react-router';
import TableToggleButton from 'src/components/TableToggleButton';
import {
  Promocode,
} from './types';

const promocodes: React.FC = () => {
  const {
    backend: {
      promocodes: promocodesStore,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  return (
    <div>
      <GeneralTableView<Promocode>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: false,
            paging: false,
          },
          title: i18n.t('PROMO_CODES'),
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const item: any = data;
                history.push(`/promocodes-form/${item.id}`);
              },
            },
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                history.push('/promocodes-form/:id');
              },
              tooltip: i18n.t('ADD_NEW_PROMO_CODE'),
            },
          ],
        }}
        options={{
          search: false,
          pageSize: 10,
          filtering: false,
        }}
        get={async (query) => {
          const res = await promocodesStore.getAll(query);
          return {
            data: toJS(res).map((item) => new Promocode(item)),
            totalCount: 10,
            page: 1,
          };
        }}
        tableColumns={[
          {
            field: 'id',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'code',
            title: i18n.t('PROMOCODE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'consultationType',
            title: i18n.t('CONSULTATION_TYPE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'discountType',
            title: i18n.t('DISCOUNT_TYPE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'amount',
            title: i18n.t('AMOUNT'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'maxNumberOfUsage',
            title: i18n.t('MAX_TIMES_OF_USAGE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'startDate',
            title: i18n.t('START_DATE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'endDate',
            title: i18n.t('END_DATE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'isActive',
            title: i18n.t('IS_ACTIVE'),
            type: TableColumnTypes.BOOLEAN,
            columnProps: {
              editable: 'always',
              render: (data) => (
                <TableToggleButton
                  isActive={data.isActive}
                  activationConfirmationText={i18n.t('ACTIVATE_PROMOCODE_CONFIRMATION')}
                  deactivationConfirmationText={i18n.t('DEACTIVATE_PROMOCODE_CONFIRMATION')}
                  onChange={async (response) => {
                    if (response) {
                      const code: any = data;
                      await promocodesStore.updatePromocode(code.id, {
                        isActive: !data.isActive,
                      });
                      if (ref && ref.current) {
                        ref.current.onQueryChange();
                      }
                    }
                  }}
                />
              ),
            },
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const Promocodes = baseScreen(promocodes, {
  allowedRoles: ['ADMIN', 'NONE'],
});
