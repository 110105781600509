import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({
  item: {
    display: 'flex',
  },
  itemDetail: {
    flex: 0.5,
    margin: 8,
  },
}));
