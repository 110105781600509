import {
  baseScreen,
} from 'hoc';
import React,
{
  useEffect,
  useState,
} from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  useHistory,
  useParams,
} from 'react-router';
import * as Yup from 'yup';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import {
  Accountant,
} from '../accountants/types';
import {
  ONLY_LETTERS_REGEX,
  ONLY_NUMBERS_REGEX,
} from '../utils';

const accountantsForm: React.FC = () => {
  const {
    backend: {
      accountants,
      users,
    },
  } = useStores();
  const {
    mode,
  } = useParams() as any;
  const history = useHistory();
  useEffect(() => {
    users.getRoles();
  }, []);
  const [
    viewModeEnabled,
  ] = useState(mode === 'view');
  const form = new FormFieldsBuilder<Accountant>()
    .addField('name', {
      type: GeneralFormFields.INPUT_FIELD,
      hasTranslations: true,
      title: i18n.t('NAME'),
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD'))
        .matches(ONLY_LETTERS_REGEX, i18n.t('ACCEPTS_LETTERS_ONLY')) as any,
      fieldOptions: {},
    })
    .addField('email', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('EMAIL'),
      validationSchema: Yup.string().email(i18n.t('EMAIL_VALIDATION')).required(i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {},
    })
    .addField('phone', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('MOBILE_NUMBER'),
      validationSchema: Yup.string().matches(ONLY_NUMBERS_REGEX, i18n.t('PHONE_VALIDATION'))
        .required(i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {},
    })
    .addField('accessibility', {
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')) as any,
      title: i18n.t('ACCESSIBILITY'),
      fieldOptions: {},
    });
    // .addField('role', {
    //   type: GeneralFormFields.SELECT_FIELD,
    //   title: i18n.t('ROLE'),
    //   fieldOptions: {
    //     isMultiple: false,
    //     items: users.roles.map((role: any) => ({
    //       title: role,
    //       value: role,
    //     })),
    //   },
    // });
  if (!viewModeEnabled) {
    form.addField('password', {
      title: i18n.t('PASSWORD'),
      validationSchema: Yup.string().min(8, i18n.t('INVALID_PASSWORD')).required(i18n.t('REQUIRED_FIELD')) as any,
      type: GeneralFormFields.INPUT_FIELD,
      fieldOptions: {
        type: 'password',
      },
    })
      .addField('confirmPassword', {
        title: i18n.t('CONFIRM_PASSWORD'),
        validationSchema: Yup.string().oneOf([Yup.ref('password')], i18n.t('PASSWORD_MUST_MATCH'))
          .required(i18n.t('REQUIRED_FIELD')) as any,
        type: GeneralFormFields.INPUT_FIELD,
        fieldOptions: {
          type: 'password',
        },
      });
  }

  return (
    <GeneralFormView<any, Accountant>
      title={i18n.t('ADD_ACCOUNTANT')}
      identifier="id"
      defaultLang=""
      otherLanguages={[]}
      viewMode={viewModeEnabled}
      formData={form.formFields}
      isFetchSuccessful
      update={async (id, data) => {
        try {
          const accountant = data;
          await accountants.updateAccount(Number.parseInt(id, 10), accountant);
          history.push('/users/:id/accountants');
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      create={async (data) => {
        try {
          const accountant = data;
          await accountants.create(
            accountant,
          );
          history.push('/accountant-confirmation');
          return Promise.resolve(1);
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async (id) => {
        const account = await accountants.getOne(id);
        return Promise.resolve(new Accountant(account));
      }}
    />
  );
};

export const AccountantsForm = baseScreen(accountantsForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
