import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import SendIcon from '@material-ui/icons/Send';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import AddIcon from '@material-ui/icons/Add';
import {
  useHistory,
} from 'react-router';
import {
  Notification,
} from './types';

const notifications: React.FC = () => {
  const {
    backend: {
      notifications: notificationsStore,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  return (
    <div>
      <GeneralTableView<Notification>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: false,
            paging: false,
          },
          title: i18n.t('NOTIFICATIONS'),
          actions: [
            {
              icon: SendIcon,
              onClick: (ev, data) => {
                const notification: any = data;
                notificationsStore.send(notification.id);
                if (ref && ref.current) {
                  ref.current.onQueryChange();
                }
              },
              tooltip: i18n.t('SEND_NOTIFICATION'),
            },
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                history.push('/notifications-form');
              },
              tooltip: i18n.t('SEND_NOTIFICATION'),
            },
          ],
        }}
        options={{
          search: false,
          pageSize: 10,
          filtering: false,
        }}
        get={async (query) => {
          const res = await notificationsStore.getAll(query);
          return {
            data: toJS(res).map((item: any) => new Notification(item)),
            totalCount: 10,
            page: 1,
          };
        }}
        tableColumns={[
          {
            field: 'id',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'createdAt',
            title: i18n.t('DATE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'arabicText',
            title: i18n.t('ARABIC_TEXT'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'englishText',
            title: i18n.t('ENGLISH_TEXT'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'audience',
            title: i18n.t('AUDIENCE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const Notifications = baseScreen(notifications, {
  allowedRoles: ['ADMIN', 'NONE'],
});
