import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Props,
} from './types';
import {
  useStyles,
} from './styles';

const SearchFilterDropdown: React.FC<Props> = (props) => {
  const {
    label,
    searchFilterDropdown,
    style,
  } = props;
  const [
    status,
    setStatus,
  ] = useState(searchFilterDropdown!.value);
  const classes = useStyles();
  const selectChange = (event: any): void => {
    setStatus(event.target.value);
  };
  useEffect(() => {
    props.searchFilterDropdown!.onChange(status);
  }, [status]);

  return (
    <div className={classes.container} style={style}>
      <form className={classes.form} autoComplete="off">
        <FormControl className={classes.select}>
          <InputLabel htmlFor="status">{label}</InputLabel>
          <Select
            value={status}
            onChange={selectChange}
          >
            {searchFilterDropdown!.filters.map((filter) => (
              <MenuItem key={filter.value} value={filter.value}>
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
    </div>
  );
};
export default SearchFilterDropdown;
