import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import {
  ForgetPassword,
  Login,
} from 'screens';

const AuthRoutes = () => (
  <>
    <Switch>
      <Route exact key="login" path="/" component={Login} />
      <Route exact key="forget-password" path="/forget-password" component={ForgetPassword} />
      <Redirect to="/" />
    </Switch>
  </>
);

export default withRouter(AuthRoutes);
