import {
  BaseDTO,
} from 'shared';

export class BillsDTO extends BaseDTO {
  id?: number;

  balance: number;

  dueDate: Date;

  email: string;

  mobileNumber: string;

  start: Date;

  status: BillStatus;

  doctorId: number;

  doctorName: string;

  serviceProviderName: string;

  serviceProviderId: number;

  type: string;
}

export enum BillStatus {
  NOT_SETTLED = 'NotSettled',
  SETTLED = 'Settled',
}

export class HospitalSettlementDTO extends BaseDTO {
  date: Date;

  transactionId: string;

  billId: number;

  receipt: string;

  bankName: string;
}
