import {
  observable,
  action,
} from 'mobx';
import {
  BackendStores,
} from '..';
import {
  BaseBackendStore,
} from '../types';
import {
  cities,
} from './requests';
import {
  City,
} from './types';

export class CityStore extends BaseBackendStore {
  @observable cities: City[] = [];

  constructor(
    public parent: BackendStores,
  ) {
    super();
  }

  @action getCities = async (governorateId: number): Promise<City[]> => {
    try {
      this.cities = await this.connections.backend.httpGet(cities, {
        params: {
          governorateId,
        },
      });
      return this.cities;
    } catch (err) {
      return Promise.reject(err);
    }
  };
}
