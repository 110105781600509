/* eslint-disable */

import React,
{
  useRef,
  useState,
} from 'react';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  useHistory,
} from 'react-router';
import AddIcon from '@material-ui/icons/Add';
import i18n from 'i18n-js';
import {
  Assets,
} from 'assets';
import {
  baseScreen,
} from 'hoc';
import {
  Button,
  Dialog,
  IconButton,
  TextField,
} from '@material-ui/core';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import DeleteIcon from '@material-ui/icons/Delete';
import RejectedIcon from '@material-ui/icons/Block';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import NotAppliedIcon from '@material-ui/icons/CloudOff';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import {
  CopyToClipboard,
} from 'react-copy-to-clipboard';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import TableToggleButton from 'src/components/TableToggleButton';
import {
  Doctor,
  DoctorsTable,
  SyndicateIdStatus,
} from './types';
import GeneralTableView from '../../components/GeneralTableView';

const doctorsScreen: React.FC = () => {
  const {
    backend: {
      doctors,
    },
  } = useStores();
  const [
    copiedId,
    setCopiedId,
  ] = useState(0);
  const history = useHistory();
  const ref = useRef<any|null>(null);
  const getSyndicateStatusIcon = (status: SyndicateIdStatus) => {
    switch (status) {
      case SyndicateIdStatus.NEW:
        return NewReleasesIcon;
      case SyndicateIdStatus.APPROVED:
        return VerifiedUserIcon;
      case SyndicateIdStatus.REJECTED:
        return RejectedIcon;
      default:
        return NotAppliedIcon;
    }
  };
  const getSyndicateStatusIconTooltip = (status: SyndicateIdStatus) => {
    switch (status) {
      case SyndicateIdStatus.NEW:
        return i18n.t('SyndicateID_NEW_TOOLTIP');
      case SyndicateIdStatus.APPROVED:
        return i18n.t('SyndicateID_APPROVED_TOOLTIP');
      case SyndicateIdStatus.REJECTED:
        return i18n.t('SyndicateID_REJECTED_TOOLTIP');
      default:
        return i18n.t('SyndicateID_NOT_APPLIED_TOOLTIP');
    }
  };

  const [
    linkData,
    setLinkData,
  ] = useState<{
    id: number;
  }>({} as any);
  const [
    isModalOpen,
    setModalOpen,
  ] = useState(false);
  const [
    link,
    setLink,
  ] = useState('');
  return (
    <div>
      <Dialog
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            margin: 30,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 112,
            width: 400,
          }}
        >
          <TextField
            id="standard-basic"
            label={i18n.t('EASY_CASH_LINK')}
            value={link}
            onChange={(ev) => {
              setLink(ev.target.value);
            }}
          />
          <Button
            color="primary"
            onClick={async () => {
              await doctors.generateDeepLink(linkData.id, link);
              setLink('');
              setLinkData({} as any);
              setModalOpen(false);
              if (ref && ref.current) {
                ref.current.onQueryChange();
              }
            }}
          >
            {i18n.t('SAVE')}
          </Button>
        </div>
      </Dialog>
      <GeneralTableView<DoctorsTable>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 11,
            pageSize: 20,
          },
          title: i18n.t('DOCTORS'),
          actions: [
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                //console.log("add new  doctor")
                history.push('/doctor-details/new/:id');
              },
              tooltip: i18n.t('ADD_NEW_DOCTOR'),
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.trash}
                  alt={i18n.t('DELETE')}
                />
              ),
              onClick: async (event, data) => {
                //console.log(data);
                if (!Array.isArray(data)) {
                  const response = window.confirm(i18n.t('DELETE_DOCTOR_CONFIRMATION'));
                  if (response) {
                    await doctors.delete(data.doctorId);
                    if (ref && ref.current) {
                      ref.current.onQueryChange();
                    }
                  }
                }
              },
            },
            (data: any) => ({
              icon: getSyndicateStatusIcon(data.syndicateIdStatus),
              tooltip: getSyndicateStatusIconTooltip(data.syndicateIdStatus),
              onClick: async () => {
                if (data.syndicateIdStatus === SyndicateIdStatus.NEW) {
                  history.push(`/syndicate-ID-verification/${data.id}`);
                }
              },
            }),
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (event, data) => {
                const dr: any = data;
                history.push(`/doctor-details/view/${dr.id as any}`);
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const dr: any = data;
                //console.log("edit doctor",data)
                history.push(`/doctor-details/edit/${dr.id as any}`);
              },
            },
            {
              isFreeAction: true,
              icon: ImportExportIcon,
              onClick: () => {
                window.open(doctors.download(), '_blanck');
              },
              tooltip: i18n.t('EXPORT_DATA'),
            },
          ],
        }}
        options={{
          search: false,
          pageSize: 10,
          filtering: false,
        }}
        get={async (query) => {
          await doctors.entityList.updateOptions(query);
          return {
            data: toJS(doctors.entityList.data).map((item) => Doctor.fromDTO(item)),
            totalCount: doctors.entityList.meta.itemCount,
            page: doctors.entityList.meta.page - 1,
          };
        }}
        tableColumns={[
          {
            field: 'code',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'avatar',
            title: i18n.t('AVATAR'),
            type: TableColumnTypes.STRING,
            columnProps: {
              render: (data) => (
                <img
                  src={data.avatar}
                  style={{
                    width: 40,
                  }}
                  alt={data.name}
                />
              ),
            },
          },
          {
            field: 'name',
            title: i18n.t('NAME'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'syndicateNumber',
            title: i18n.t('SYNDICATE_ID'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'specialty',
            title: i18n.t('SPECIALITY'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'grade',
            title: i18n.t('GRADE'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'phone',
            title: i18n.t('MOBILE_NUMBER'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'status',
            title: i18n.t('STATUS'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'isComplete',
            title: i18n.t('USER_COMPLETED_DATA'),
            type: TableColumnTypes.BOOLEAN,
            // columnProps: {
            //   editable: 'always',
            //   render: (data) => (
            //     <TableToggleButton
            //       isActive={data.isActive}
            //       activationConfirmationText={i18n.t('ACTIVATE_DOCTOR_CONFIRMATION')}
            //       deactivationConfirmationText={i18n.t('DEACTIVATE_DOCTOR_CONFIRMATION')}
            //       onChange={async (response) => {
            //         if (response) {
            //           const dr: any = data;
            //           await doctors.updateDoctor(dr.id, {
            //             isActive: !data.isActive,
            //           });
            //           if (ref && ref.current) {
            //             ref.current.onQueryChange();
            //           }
            //         }
            //       }}
            //     />
            //   ),
            // }
          },
          {
            field: 'createdAt',
            title: 'Register Date',
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'lastCheckIn',
            title: i18n.t('LAST_CHECKIN'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'isActive',
            title: i18n.t('IS_ACTIVE'),
            type: TableColumnTypes.BOOLEAN,
            columnProps: {
              editable: 'always',
              render: (data) => (
                <TableToggleButton
                  isActive={data.isActive}
                  activationConfirmationText={i18n.t('ACTIVATE_DOCTOR_CONFIRMATION')}
                  deactivationConfirmationText={i18n.t('DEACTIVATE_DOCTOR_CONFIRMATION')}
                  onChange={async (response) => {
                    if (response) {
                      const dr: any = data;
                      await doctors.updateDoctor(dr.id, {
                        isActive: !data.isActive,
                      });
                      if (ref && ref.current) {
                        ref.current.onQueryChange();
                      }
                    }
                  }}
                />
              ),
            },
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'isBanned',
            title: i18n.t('IS_BANNED'),
            type: TableColumnTypes.BOOLEAN,
            columnProps: {
              editable: 'always',
              render: (data) => (
                <TableToggleButton
                  isActive={data.isBanned}
                  activationConfirmationText={i18n.t('BLOCK_PATIENT_CONFIRMATION')}
                  deactivationConfirmationText={i18n.t('UNBLOCK_PATIENT_CONFIRMATION')}
                  onChange={async (response) => {
                    if (response) {
                      await doctors.update(data.id, {
                        isBanned: !data.isBanned,
                      });
                      if (ref && ref.current) {
                        ref.current.onQueryChange();
                      }
                    }
                  }}
                />
              ),
            },
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'link',
            title: i18n.t('EASY_CASH_LINK'),
            type: TableColumnTypes.STRING,
            columnProps: {
              render: (data) => (data.link ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CopyToClipboard
                    text={data.link}
                    onCopy={() => {
                      setCopiedId(data.doctorId);
                      setTimeout(() => {
                        setCopiedId(0);
                      }, 1000);
                    }}
                  >
                    <div
                      title={i18n.t('CLICK_TO_COPY')}
                      style={{
                        cursor: 'pointer',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      {copiedId === data.doctorId
                        ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <CheckIcon
                              style={{
                                alignSelf: 'center',
                              }}
                            />
                            {i18n.t('COPIED')}
                          </div>
                        ) : (
                          <FileCopyIcon />
                        )}
                    </div>
                  </CopyToClipboard>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this link ?')) {
                        doctors.update(data.id, {
                          directLink: null,
                          link: null,
                        } as any);
                        if (ref && ref.current) {
                          ref.current.onQueryChange();
                        }
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ) : (
                <Button
                  onClick={async () => {
                    setLinkData({
                      id: data.doctorId,
                    });
                    setModalOpen(true);
                  }}
                  style={{
                    background: '#3f51b5',
                    color: 'white',
                  }}
                >
                  {i18n.t('GENERATE_LINK')}
                </Button>
              )),
            },
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const Doctors = baseScreen(doctorsScreen, {
  allowedRoles: ['NONE', 'ADMIN'],
});
