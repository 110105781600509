import React,
{
  useState,
} from 'react';
import {
  useLocalization,
} from 'hooks';
import {
  Assets,
} from 'assets';
import {
  useObserver,
} from 'mobx-react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  Styles,
} from './styles';

export const HeaderLanguages = () => {
  const useStyles = Styles;
  const [
    displayFlag,
    setDisplayFlag,
  ] = useState(false);
  const classes = useStyles();
  const {
    currentLanguage,
    setLanguage,
  } = useLocalization();
  const changeLanguage = async () => {
    if (displayFlag) {
      const lang = currentLanguage.key === LanguagesKey.AR ? LanguagesKey.EN : LanguagesKey.AR;
      // await updateUser({
      //   appLanguage: lang,
      // });
      setLanguage({
        isRTL: currentLanguage.key !== LanguagesKey.AR,
        title: '',
        key: lang,
      });
      setDisplayFlag(false);
    }
  };
  const {
    images: {
      components: {
        header: {
          egypt,
          usa,
        },
      },
    },
  } = Assets;
  return useObserver(() => (
    <div
      style={{
        display: 'flex',
      }}
      role="button"
      onClick={() => setDisplayFlag(!displayFlag)}
      onKeyDown={() => {}}
      tabIndex={0}
    >
      <div
        style={{
          flexDirection: currentLanguage.key === LanguagesKey.AR ? 'column' : 'column-reverse',
        }}
        className={classes.container}
      >
        {(currentLanguage.key === LanguagesKey.AR || (currentLanguage.key === LanguagesKey.EN && displayFlag))
      && (
      <div
        role="button"
        onClick={() => changeLanguage()}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        <img src={egypt} alt="العربية" className={classes.flag} />
      </div>
      )}
        {(currentLanguage.key === LanguagesKey.EN || (currentLanguage.key === LanguagesKey.AR && displayFlag))
      && (
      <div
        role="button"
        onClick={() => changeLanguage()}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        <img src={usa} alt="English" className={classes.flag} />
      </div>
      )}
      </div>
      <KeyboardArrowDownIcon
        style={{
          marginTop: 15,
          marginRight: currentLanguage.isRTL ? -40 : 0,
          marginLeft: currentLanguage.isRTL ? 0 : -40,
        }}
      />
    </div>
  ));
};
