import {
  Typography,
} from '@material-ui/core';
import React from 'react';
import {
  FieldViewProps,
} from './types';

export const FieldView: React.FC<FieldViewProps> = ({
  title,
  value,
  marginTop = 8,
}) => (
  <div
    style={{
      margin: 8,
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      {
          typeof title === 'string'
            ? (
              <Typography variant="h6">{`${title}: `}</Typography>
            )
            : (
              title()
            )
        }
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginLeft: 8,
        marginTop,
        flex: 1,
      }}
    >
      {
          (typeof value === 'string' || typeof value === 'number' || typeof value === 'object')
            ? (
              <Typography variant="body2">{`${value}`}</Typography>
            )
            : (
              value()
            )
        }
    </div>
  </div>
);

export * from './types';
