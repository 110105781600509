import {
  observer,
} from 'mobx-react';
import React from 'react';
import {
  useStores,
} from 'hooks';
// import {
//   AccessDenied,
// } from 'screens';
import {
  BaseScreenConfig,
} from './types';

export function baseScreen<Props, Roles extends string>(Component: React.FC<Props>, config: BaseScreenConfig<Roles>) {
  const BaseScreen: React.FC<Props> = (props) => {
    const stores = useStores();
    const ObserverComponent = observer(Component);
    if (!config.allowedRoles.includes('NONE' as any)) {
      return (
        <button onClick={() => stores.backend.users.setRole('ADMIN')} type="button"> GRANT ACCESS </button>
      );
    }
    return (
      <ObserverComponent
        {...props}
      />
    );
  };
  return observer(BaseScreen);
}

export * from './types';
