import {
  AccountantDTO,
} from 'src/stores/backend/AccountantsStore/types';
import {
  BaseFormViewModel,
} from '../types';

export class Accountant extends BaseFormViewModel<AccountantDTO> {
  id?: number;

  name: string;

  phone: string;

  email: string;

  isActive: boolean;

  password: boolean;

  confirmPassword?: boolean;

  accessibility: string;

  constructor(data: AccountantDTO) {
    super();
    this.id = data.id;
    this.email = data.email;
    this.isActive = data.isActive;
    this.name = data.name;
    this.phone = data.phone;
    this.accessibility = data.accessibility;
  }

  toDTO(): AccountantDTO {
    return { } as AccountantDTO;
  }
}
