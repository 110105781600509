import {
  baseScreen,
} from 'hoc';
import {
  useObserver,
} from 'mobx-react';
import React from 'react';

const balance: React.FC = () => useObserver(() => (
  <div>
    balance
  </div>
));

export const Balance = baseScreen(balance, {
  allowedRoles: ['ADMIN', 'NONE'],
});
