/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  BaseBackendStore,
} from '../types';
import {
  specialities,
  speciality,
} from './requests';
import {
  SpecialitiesDTO,
} from './types';
import {
  BackendStores,
} from '..';

export class SpecialitiesStore extends BaseBackendStore {
  @observable private _entityList: SpecialitiesDTO[] = [];

  @action public getAll = async (options: any): Promise<SpecialitiesDTO[]> => {
    console.log(options);
    const response = await this.connections.backend
      .httpGet(`${specialities}`);
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: SpecialitiesDTO;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: SpecialitiesDTO): Promise<void> {
    try {
      await this.connections.backend.httpPost(specialities, data);
      return Promise.resolve();
    } catch (error:any) {
      return Promise.reject(error.response.data.message);
    }
  }

  @action
  public async getOne(id: string): Promise<ModelOf<SpecialitiesDTO>> {
    const response = await this.connections.backend.httpGet(speciality(parseInt(id, 10)));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action updateSpeciality = async (id: number, data: Partial<SpecialitiesDTO>) => {
    try {
      const response = await this.connections.backend.httpPut(speciality(id), data);
      return Promise.resolve(response);
    } catch (error:any) {
      return Promise.reject(error.response.data.message);
    }
  };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(speciality(id));
    return Promise.resolve(response);
  }
}
