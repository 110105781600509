import {
  FieldFileCommonTypes,
  RemoteFile,
} from 'src/components/GeneralFormView/FormField/types';
import {
} from 'utils';
import {
  Patient,
} from 'shared';
import i18n from 'i18n-js';
import {
  BaseFormViewModel,
} from '../types';
import {
  calculateAge,
} from './utils';

export class PatientModelView extends BaseFormViewModel<Patient> {
  id?: number;

  name: string;

  gender: string;

  age: number;

  avatar: RemoteFile[];

  email: string;

  phone: string;

  constructor(dto: Patient) {
    super();
    if (dto.user) {
      this.name = dto.user.name;
      this.gender = dto.user.gender ? i18n.t(dto.user.gender) : '';
      this.phone = dto.user.phone;
      if (dto.user.avatar) {
        this.avatar = [{
          source: dto.user.avatar,
          type: FieldFileCommonTypes.IMAGE,
        }];
      }
      this.email = dto.user.email;
      this.age = calculateAge(new Date(dto.dateOfBirth));
      this.id = dto.id;
    }
  }

  toDTO() {
    return {} as Patient;
  }
}
