import {
  Modal,
} from '@material-ui/core';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Assets,
} from 'assets';
import CloseIcon from '@material-ui/icons/Close';
import Spinner from '../../../../Spinner';
import {
  FieldFile,
  isRemoteFile,
} from '../../types';
import {
  getFilePreview,
} from '../utils';

const ImagePreview: React.FC<FieldFile> = (props) => {
  const [preview, setPreview] = useState<string>('');

  useEffect(() => {
    if (!isRemoteFile(props)) {
      const getPreview = async () => {
        const source = await getFilePreview(props.file);
        setPreview(source);
      };
      getPreview();
    }
  }, [
    props,
  ]);
  const [open, setOpen] = React.useState(false);

  if (isRemoteFile(props)) {
    const {
      source,
    } = props;
    const [
      loadingError,
      setLoadingError,
    ] = useState(false);
    return (
      <div
        onKeyDown={() => {}}
        onClick={() => {
          if (loadingError) {
            window.open(source, '_blank');
          } else {
            setOpen(true);
          }
        }}
        tabIndex={0}
        role="button"
      >
        <img
          style={{
            height: 128,
            objectFit: 'contain',
          }}
          src={loadingError ? Assets.images.common.file : source}
          alt="preview"
          onError={() => {
            setLoadingError(true);
          }}
        />
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <CloseIcon
              onClick={(ev) => {
                ev.stopPropagation();
                setOpen(false);
              }}
              style={{
                color: 'white',
                fontSize: 38,
                position: 'absolute',
                right: 20,
                top: 10,
              }}
            />
            <img
              style={{
                alignSelf: 'center',
                objectFit: 'contain',
              }}
              src={source}
              alt="preview"
            />

          </div>
        </Modal>
      </div>
    );
  }
  if (preview) {
    return (
      <img
        style={{
          height: 128,
          objectFit: 'contain',
        }}
        src={preview}
        alt="preview"
      />
    );
  }
  return (
    <div
      style={{
        height: 128,
        objectFit: 'contain',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner />
    </div>
  );
};

export default ImagePreview;
