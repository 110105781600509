import React, {
  useState,
  useEffect,
} from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  DateTimePicker,
  TimePicker,
} from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import ClearIcon from '@material-ui/icons/Clear';
import {
  DateTime,
} from 'luxon';
import {
  MaterialUiPickersDate,
} from '@material-ui/pickers/typings/date';
import {
  DateFilterProps,
} from './types';
import {
  TableColumnTypes,
} from '../../types';

const formatDateDefault = (date: Date, type: TableColumnTypes) => {
  if (type === TableColumnTypes.DATE) {
    return DateTime.fromJSDate(date).toFormat('dd/MM/yyyy');
  }
  if (type === TableColumnTypes.DATETIME) {
    return DateTime.fromJSDate(date).toFormat('dd/MM/yyyy HH:mm');
  }
  return DateTime.fromJSDate(date).toFormat('HH:mm');
};

const selectComponentToRender = (type: TableColumnTypes) => {
  if (type === TableColumnTypes.DATE) {
    return DatePicker;
  }
  if (type === TableColumnTypes.DATETIME) {
    return DateTimePicker;
  }
  return TimePicker;
};

const DateFilter = <RowData extends Record<string, unknown>>(props: DateFilterProps<RowData>) => {
  const {
    columnDef,
    onFilterChanged,
  } = props;

  const {
    tableData,
    type = TableColumnTypes.DATE,
    filterProps = {},
  } = columnDef;

  const {
    formatDate = formatDateDefault,
  } = filterProps;

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [filter, setFilter] = useState<Date | undefined>();

  const openDialog = () => {
    setModalOpen(true);
  };

  const closeDialog = () => {
    setModalOpen(false);
  };

  const clearFilter = () => {
    setFilter(undefined);
    onFilterChanged(tableData.id.toString(), undefined);
  };

  const resetModalValues = () => {
    if (filter) {
      setSelectedDate(filter);
    } else {
      setSelectedDate(new Date());
    }
  };

  const onValueChange = (date: MaterialUiPickersDate) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  const onPressOpenFilter = () => {
    openDialog();
  };

  const onPressClear = () => {
    setSelectedDate(new Date());
    clearFilter();
  };

  const onPressCancel = () => {
    closeDialog();
  };

  const onPressFilter = () => {
    closeDialog();
    setFilter(selectedDate);
    onFilterChanged(columnDef.tableData.id.toString(), selectedDate);
  };

  const onDialogClose = () => {
    closeDialog();
  };

  useEffect(() => {
    if (!modalOpen) {
      resetModalValues();
    }
  }, [
    modalOpen,
  ]);

  const Component = selectComponentToRender(type as any);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={onPressOpenFilter}
        >
          <Typography variant="button">
            {
              (filter)
                ? (
                  `${formatDate(selectedDate, type as any)}`
                )
                : (
                  'Filter'
                )
            }
          </Typography>
        </Button>
        {
          !!filter && (
            <IconButton
              onClick={onPressClear}
            >
              <ClearIcon />
            </IconButton>
          )
        }
      </div>
      <Dialog
        open={modalOpen}
        onClose={onDialogClose}
        maxWidth={false}
      >
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Component
                variant="static"
                value={selectedDate || new Date()}
                onChange={onValueChange}
              />
            </div>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onPressFilter}
          >
            <Typography>Filter</Typography>
          </Button>
          <Button
            onClick={onPressCancel}
          >
            <Typography>Cancel</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DateFilter;
