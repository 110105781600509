/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  Doctor,
  SyndicateIdStatus,
} from 'src/screens/doctors/types';
import {
  Settings,
} from 'settings';
import {
  Connections,
} from 'connections';
import {
  BaseBackendStore,
} from '../types';
import {
  deleteDoctor,
  doctors,
  doctorUrl,
  syndicateIDApproval,
  download,
  // addDoctor,
} from './requests';
import {
  BackendStores,
} from '..';
import {
  SearchObj,
} from './types';
import {
  User,
} from '../auth/types';

export class DoctorsStore extends BaseBackendStore {
  @observable private _entityList: Doctor[] = [];

  @observable searchResult: Doctor[] = [];

  filter: SearchObj = {};

  @action public getAll = async (options: any): Promise<Doctor[]> => {
    // const {
    //   pageSize,
    //   page,
    // } = options;
    // const query: SearchObj = {
    //   filters: [{
    //     type: 'FIXED',
    //     by: 'service_provider',
    //     value: this.parent.auth.currentUser?.serviceProvider.id.toString(),
    //   }],
    // };
    // const groupSort = options?.tableData?.groupSort ?? 'desc';
    // const query: SearchObj = {};
    // if (options.search) {
    //   query.search = {
    //     value: options.search,
    //   };
    // }
    // query.sort = {
    //   by: options?.orderBy?.field ?? 'created_at',
    //   type: groupSort === 'desc' ? 'DESC' : 'ASC',
    // };
    // this.filter = query;
    // const response = await this.connections.backend
    //   .httpGet(`${doctors}?take=${pageSize}&page=${page + 1}&query=${JSON.stringify(query)}`);
    // return response;
    const query: SearchObj = { };
    if (options.search) {
      query.search = {
        value: options.search,
      };
    }
    query.sort = {
      by: 'created_at',
      type: 'DESC',
    };
    this.filter = query;
    const response = await this.connections.backend
      .httpGet(`${doctors}?take=${options.pageSize}&page=${options.page + 1}&query=${JSON.stringify(query)}`);
    return response;
  };

  @action searchDoctors = async (filter: SearchObj) => {
    this.searchResult = await this.connections.backend.httpGet(`${doctors}?query=${JSON.stringify(filter)}`);
    return Promise.resolve(this.searchResult);
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: Doctor;

  @action
  public getOne = async (id: string): Promise<ModelOf<Doctor>> => {
    try {
      const response = await this.connections.backend.httpGet(doctorUrl(parseInt(id, 10)));
      this.selectedEntity = response;
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject();
    }
  };

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: Doctor): Promise<number> {
    try {
      const res = await this.connections.backend.httpPost(doctors, data);
      return res.doctorId;
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  }

  @action updateDoctor = async (id: number, data: Partial<User & Doctor>) => {
    try {
      await this.connections.backend.httpPut(doctorUrl(id), data);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(deleteDoctor(id));
    return Promise.resolve(response);
  }

  @action
  public async update(id: number, data: Partial<Doctor>): Promise<void> {
    await this.connections.backend.httpPut(doctorUrl(id), data);
    return Promise.resolve();
  }

  @action
  public approveSyndicateID = async (id: number): Promise<void> => {
    const response = await this.connections.backend
      .httpPut(syndicateIDApproval(id), {
        status: SyndicateIdStatus.APPROVED,
      });
    return Promise.resolve(response);
  };

  @action public async generateDeepLink(id: number, link: string) {
    const res: { shortLink: string } = await Connections.firebase.generateDeepLink(link);
    await this.updateDoctor(id, {
      link: res.shortLink,
      directLink: link,
    });
  }

  public download() {
    return `${Settings.config.REACT_APP_BASE_URL + download}?query=${JSON.stringify(this.filter)}&lang=${this.parent.parent.ui.localization.currentLanguage.key}`;
  }
}
