import {
  baseScreen,
} from 'hoc';
import React,
{
  useState,
} from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  useHistory,
  useParams,
} from 'react-router';
import GeneralFormFields,
{
  DateTimeFormFieldTypes,
} from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import * as Yup from 'yup';
import {
  DiscountType,
  PromocodesDTO,
} from 'src/stores/backend/PromoCodesStore/types';
import {
  RequestType,
} from 'src/stores/backend/RequestsStore/types';
import {
  Promocode,
} from '../promoCodes/types';

const promocodesForm: React.FC = () => {
  const {
    backend: {
      promocodes,
    },
  } = useStores();
  const [
    isCountryFiledEnabled,
    setCountryFiledEnabled,
  ] = useState<boolean>(true);
  const [
    minEndDate,
    setMinEndDate,
  ] = useState<Date>(new Date());
  const history = useHistory();
  const {
    id: promocodeId,
  } = useParams() as any;

  return (
    <GeneralFormView<any, Promocode>
      title={i18n.t('PROMO_CODES')}
      identifier="id"
      defaultLang=""
      otherLanguages={[]}
      formData={new FormFieldsBuilder<Promocode>()
        .addField('code', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('PROMOCODE'),
          fieldOptions: {},
        })
        .addField('discountType', {
          type: GeneralFormFields.SELECT_FIELD,
          title: i18n.t('DISCOUNT_TYPE'),
          fieldOptions: {
            isMultiple: false,
            items: [
              {
                title: i18n.t('PERCENTAGE'),
                value: DiscountType.PERCENTAGE,
              },
              {
                title: i18n.t('FIXED'),
                value: DiscountType.FIXED,
              },
            ],
          },
        })
        .addField('amount', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('AMOUNT'),
          fieldOptions: {
            type: 'number',
          },
        })
        .addField('maxNumberOfUsage', {
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')) as any,
          title: i18n.t('MAX_TIMES_OF_USAGE'),
          fieldOptions: {
            type: 'number',
          },
        })
        .addField('countryType', {
          type: GeneralFormFields.SELECT_FIELD,
          title: i18n.t('COUNTRY'),
          initialValue: ['All'],
          fieldOptions: {
            isMultiple: false,
            onValueChanged: (val) => {
              setCountryFiledEnabled(val === 'specific country');
            },
            items: [
              {
                title: i18n.t('ALL'),
                value: 'All',
              },
              {
                title: i18n.t('SPECIFIC_COUNTRY'),
                value: 'specific country',
              },
            ],
          },
        })
        .addField('country', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('COUNTRY'),
          validationSchema: Yup.string().notRequired() as any,
          fieldOptions: {
            disabled: !isCountryFiledEnabled,
          },
        })
        .addField('consultationType', {
          type: GeneralFormFields.SELECT_FIELD,
          title: i18n.t('CONSULTATION_TYPE'),
          initialValue: ['all'],
          fieldOptions: {
            isMultiple: false,
            items: [
              {
                title: i18n.t('ALL'),
                value: 'All',
              },
              {
                title: i18n.t('ONLINE_CONSULTATION'),
                value: RequestType.ONLINE_CONSULTATION,
              },
              {
                title: i18n.t('HOME_VISIT'),
                value: RequestType.HOME_VISIT,
              },
              {
                title: i18n.t('CLINIC'),
                value: RequestType.CLINIC,
              },
            ],
          },
        })
        .addField('startDate', {
          type: GeneralFormFields.DATE_TIME_FIELD,
          title: i18n.t('START_DATE'),
          validationSchema: Yup.date().required(i18n.t('REQUIRED_FIELD')).typeError(i18n.t('INVALID_FIELD')) as any,
          fieldOptions: {
            onValueChanged: (val: Date) => {
              setMinEndDate(val);
            },
            type: DateTimeFormFieldTypes.DATE,
            datePickerProps: {
              minDate: new Date(),
            } as any,
          },
        })
        .addField('endDate', {
          type: GeneralFormFields.DATE_TIME_FIELD,
          title: i18n.t('END_DATE'),
          validationSchema: Yup.date().required(i18n.t('REQUIRED_FIELD')).typeError(i18n.t('INVALID_FIELD')) as any,
          fieldOptions: {
            type: DateTimeFormFieldTypes.DATE,
            datePickerProps: {
              minDate: minEndDate,
            } as any,
          },
        })
        .addField('isActive', {
          type: GeneralFormFields.TOGGLE_FIELD,
          title: i18n.t('IS_ACTIVE'),
          fieldOptions: {},
        })
        .formFields}
      isFetchSuccessful
      update={async (id, data) => {
        try {
          const promocode = data;
          if (promocode.countryType[0] === 'All') {
            promocode.country = 'All';
          }
          if (!Number.isNaN(Number.parseInt(id, 10))) {
            await promocodes.updatePromocode(Number.parseInt(id, 10), PromocodesDTO.toDTO(promocode));
            history.push('/promo-codes');
          }
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      create={async (data) => {
        try {
          const promocode = data;
          if (promocode.countryType[0] === 'All') {
            promocode.country = 'All';
          }
          await promocodes.create(PromocodesDTO.toDTO(promocode));
          history.push('/promo-code-confirmation');
          return Promise.resolve(1);
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async () => {
        let res: any = {};
        if (promocodeId) {
          res = await promocodes.getOne(promocodeId);
          setCountryFiledEnabled(res.country !== 'All');
        }
        return Promise.resolve(new Promocode(res));
      }}
    />
  );
};

export const PromocodesForm = baseScreen(promocodesForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
