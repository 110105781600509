import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import {
  getIn,
  useFormikContext,
} from 'formik';
import React, {
  useEffect,
} from 'react';
import {
  Gmaps,
  Marker,
} from 'react-gmaps';
import {
  MapLocation,
} from '../../types';
import FieldError from '../../Utils/FieldError';
import {
  FieldView,
} from '../../Utils/FieldView';
import {
  FieldPropsCommon,
  LocationFieldValue,
} from '../types';
import {
  onChange,
} from '../utils';

const params = {
  v: '3.exp',
  key: 'AIzaSyAC1kD_cNz3nZnhHwRmZL4Zg5jkSCqaqXc',
};

type Props = FieldPropsCommon & LocationFieldValue;

const LocationField = <FormModel extends object>(props: Props) => {
  const {
    values,
    handleChange,
    setFieldTouched,
    isSubmitting,
    errors,
    touched,
  } = useFormikContext<FormModel>();

  const {
    title,
    location,
    viewMode,
  } = props;

  const value: MapLocation = getIn(values, location);
  const onValueChange = onChange(handleChange, location);
  const error = getIn(errors, location);
  const isTouched = getIn(touched, location);

  useEffect(() => {
    if (isSubmitting) {
      setFieldTouched(location);
    }
  }, [isSubmitting, location, setFieldTouched]);

  const onMapCreated = (map: any) => {
    map.setOptions({
      disableDefaultUI: false,
    });
  };

  const onDragEnd = (e: any) => {
    onValueChange({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
    setFieldTouched(location);
  };

  if (viewMode) {
    return (
      <FieldView
        title={title}
        value={() => (
          <div
            style={{
              flex: 1,
              width: '100%',
            }}
          >
            <Gmaps
              borderRadius="5px"
              width="100%"
              height="512px"
              lat={value.latitude}
              lng={value.longitude}
              zoom={15}
              loadingMessage="Loading..."
              params={params}
              onMapCreated={onMapCreated}
            >
              <Marker
                lat={value.latitude}
                lng={value.longitude}
              />
            </Gmaps>
          </div>
        )}
      />
    );
  }

  return (
    <Card
      style={{
        margin: 8,
      }}
    >
      <CardHeader
        title={title}
      />
      <CardContent>
        <Gmaps
          borderRadius="5px"
          width="100%"
          height="512px"
          lat={value.latitude}
          lng={value.longitude}
          zoom={15}
          loadingMessage="Loading..."
          params={params}
          onMapCreated={onMapCreated}
        >
          <Marker
            lat={value.latitude}
            lng={value.longitude}
            draggable
            onDragEnd={onDragEnd}
          />
        </Gmaps>
      </CardContent>
      <CardActions>
        <FieldError
          errors={error}
          touched={isTouched}
        />
      </CardActions>
    </Card>
  );
};

export default LocationField;
