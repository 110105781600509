import placeholder from './placeholder.png';
import logo from './logo.png';
import doctors from './doctors.png';
import edit from './edit.svg';
import eye from './eye.svg';
import trash from './trash.svg';
import chevronBottom from './chevron-bottom.png';
import bell from './bell.svg';
import file from './file.png';

export class Common {
  placeholder = placeholder;

  logo = logo;

  edit = edit;

  trash = trash;

  eye = eye;

  doctors = doctors;

  bell = bell;

  chevronBottom = chevronBottom;

  file = file;
}
