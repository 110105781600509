import {
  SpecialitiesDTO,
} from 'src/stores/backend/SpecialitiesStore/types';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  BaseFormViewModel,
} from '../types';

export class Speciality extends BaseFormViewModel<SpecialitiesDTO> {
  id?: number;

  englishName: string;

  arabicName: string;

  parent?: string[];

  constructor(data: SpecialitiesDTO) {
    super();
    this.id = data.id;
    this.englishName = data.translations?.filter((item) => item.languageCode === LanguagesKey.EN)[0].title;
    this.arabicName = data.translations?.filter((item) => item.languageCode === LanguagesKey.AR)[0].title;
    this.parent = data.parentId?.toString() ? [data.parentId?.toString()] : [];
  }

  toDTO(): SpecialitiesDTO {
    return { } as SpecialitiesDTO;
  }
}
