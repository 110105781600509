import {
  ExtendedAxios,
} from 'utils';
import {
  firebaseConfig,
  deepLinkingBaseUrl,
} from './environment';

class Firebase extends ExtendedAxios {
  constructor() {
    super({
      axiosRequestConfig: {
        baseURL: '',
        timeout: 100000,
      },
      defaultParams: {},
    });
  }

  generateDeepLink(link: string) {
    const body = {
      dynamicLinkInfo: {
        domainUriPrefix: firebaseConfig.databaseURL,
        link,
        androidInfo: {
          androidPackageName: firebaseConfig.androidPACKAGEName,
        },
        iosInfo: {
          iosBundleId: firebaseConfig.iosBundleId,
        },
        socialMetaTagInfo: {
          socialImageLink: process.env.REACT_APP_FIREBASE_DYNAMIC_LINK_SOCIAL_IMAGE,
          socialTitle: process.env.REACT_APP_FIREBASE_DYNAMIC_LINK_SOCIAL_TITLE,
        },
      },
    };
    return this.httpPost(deepLinkingBaseUrl, body);
  }
}
export default new Firebase();
