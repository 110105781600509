/* eslint-disable */
import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  Assets,
} from 'assets';
import AddIcon from '@material-ui/icons/Add';
import {
  useHistory,
} from 'react-router';
import {
  Membership,
} from './types';

const membershipsComponent: React.FC = () => {
  const {
    backend: {
      memberships,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  return (
    <div>
      <GeneralTableView<Membership>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: false,
            paging: false,
            sorting: false,
          },
          title: i18n.t('MEMBERSHIPS'),
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.trash}
                  alt={i18n.t('DELETE')}
                />
              ),
              onClick: async (event, data) => {
                if (!Array.isArray(data)) {
                  const response = window.confirm(i18n.t('DELETE_SPECIALITY_CONFIRMATION'));
                  if (response) {
                    await memberships.delete(data.id as number);
                    if (ref && ref.current) {
                      ref.current.onQueryChange();
                    }
                  }
                }
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const type: any = data;
                console.log(history, event, type);
                history.push(`/membership-form/${type.id}`);
              },
            },
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                history.push('/membership-form/:id');
              },
              tooltip: i18n.t('ADD_NEW_MEMBERSHIP'),
            },
        ],
      }}
      options={{
          search: false,
          pageSize: 10,
          filtering: false,
      }}
      get={async (query) => {
          const res = await memberships.getAll(query);
          return {
          data: toJS(res).map((item) => new Membership(item)),
          totalCount: 10,
          page: 1,
          };
      }}
      tableColumns={[
          {
          field: 'id',
          title: i18n.t('ID'),
          type: TableColumnTypes.NUMERIC,
          },
          {
          field: 'discountValue',
          title: i18n.t('DISCOUNT_VALUE'),
          type: TableColumnTypes.NUMERIC,
          },
          {
          field: 'validUntil',
          title: i18n.t('VALID_UNTIL'),
          type: TableColumnTypes.DATE,
          },
          {
          field: 'englishName',
          title: i18n.t('ENGLISH_TITLE'),
          type: TableColumnTypes.STRING,
          },
          {
          field: 'arabicName',
          title: i18n.t('ARABIC_TITLE'),
          type: TableColumnTypes.STRING,
          },
        ]}
      />
    </div>
  );
};

export const Memberships = baseScreen(membershipsComponent, {
  allowedRoles: ['ADMIN','NONE'],
});
