import {
  BaseDTO,
} from 'shared';
import {
  User,
} from 'src/screens/doctors/types';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  City,
  Governorate,
} from '../ClinicsStore/types';

export class ServiceProvidersDTO extends BaseDTO {
  id: number;

  logo: string;

  commercialRecord: string;

  area: string;

  signupCode?: string;

  website?: string;

  city: City;

  governorate: Governorate;

  isActive: boolean;

  isAcceptingDonation: boolean;

  serviceProviderType: ServiceProviderType;

  coordinatorName: string;

  coordinatorPhone: string;

  user: User;
}

export class ServiceProviderFormDTO extends BaseDTO {
  id?: number;

  translations: {
    name: string;
    description?: string;
    languageCode: LanguagesKey;
  }[];

  name: string;

  email: string;

  phone: string;

  password: string;

  avatar: string;

  serviceProviderTypeId: number;

  coordinatorPhone: string;

  coordinatorName: string;

  landlinePhone: string;

  cityId: number;

  governorateId: number;

  area: string;

  instapayUrl: string;

  location: {
    type: 'Point',
    coordinates: number[],
  };

  website?: string;

  commercialRecord: string;

  taxId: string;

  signupCode?: string;

  docs: string[];
}

interface ServiceProviderType {
  id: number;
  title: string;
}
