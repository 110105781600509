import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  baseScreen,
} from 'hoc';
import React from 'react';
import {
  useHistory,
} from 'react-router';
import {
  ContainerModuleProps,
} from './types';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  card: {
    minWidth: 196,
  },
});

const containerModule: React.FC<ContainerModuleProps> = (props) => {
  const {
    route,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid className={classes.root} container xs={12}>
      {route.children.map((child) => (
        <Grid item>
          <Card
            className={classes.card}
          >
            <CardContent>
              <Typography variant="h2">{child.config.name}</Typography>
            </CardContent>
            <CardActions>
              <Button size="large" onClick={() => history.push(child.getPath())}>Go</Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export const ContainerModule = baseScreen(containerModule, {
  allowedRoles: ['NONE', 'ADMIN'],
});
