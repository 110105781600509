import moment from 'moment';
import {
  PaymentDTO,
} from 'src/stores/backend/PaymentStore/types';
import {
  RequestType,
} from 'src/stores/backend/RequestsStore/types';
import {
  BaseFormViewModel,
} from '../../types';

export class Payment extends BaseFormViewModel<PaymentDTO> {
  id?: number;

  requestId: number;

  type: RequestType;

  date: string;

  time: string;

  doctorId: number;

  doctorName: string;

  patientName: string;

  total: string;

  kashfPercentage: number;

  subTotal: string;

  constructor(data: PaymentDTO) {
    super();
    this.id = data.id;
    this.date = moment(data.createdAt).format('DD-MM-YYYY');
    this.time = moment(data.createdAt).format('HH:mm A');
    this.doctorId = data.doctor.id;
    this.kashfPercentage = data.kashfPercentage;
    this.patientName = data.patient.user.name;
    this.requestId = data.request.id;
    this.doctorName = data.doctor.user.name;
    this.subTotal = data.subTotal;
    this.total = data.amount;
    this.type = data.request.type;
  }

  toDTO(): PaymentDTO {
    return { } as PaymentDTO;
  }
}
