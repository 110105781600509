import
{
  Connections,
} from 'connections';
import
{
  Stores,
} from '..';
import
{
  UserStore,
} from './users';
import
{
  AuthStore,
} from './auth';
import
{
  DoctorsStore,
} from './DoctorsStore';
import
{
  StaticTextStore,
} from './staticTexts';
import
{
  ServiceProviderTypesStore,
} from './ServiceProviderTypesStore';
import
{
  SpecialitiesStore,
} from './SpecialitiesStore';
import
{
  GradesStore,
} from './GradesStore';
import
{
  ClinicsStore,
} from './ClinicsStore';
import
{
  InsuranceCompaniesStore,
} from './InsuranceCompaniesStore';
import
{
  RequestsStore,
} from './RequestsStore';
import
{
  PromocodesStore,
} from './PromoCodesStore';
import
{
  PatientsStore,
} from './PatientsStore';
import
{
  BillsStore,
} from './BillsStore';
import
{
  AdminsStore,
} from './AdminsStore';
import
{
  GovernorateStore,
} from './Governorates';
import
{
  CityStore,
} from './Cities';
import
{
  NotificationsStore,
} from './NotificationsStore';
import
{
  ServiceProvidersStore,
} from './ServiceProvidersStore';
import
{
  PaymentStore,
} from './PaymentStore';
import
{
  MembershipsStore,
} from './MembershipStore';

import
{
  DonationStore,
} from './DonationStore';
import
{
  DonationBillsStore,
} from './DonationBillStore';
import
{
  AccountantsStore,
} from './AccountantsStore';

import
{
  NationalitiesStore,
} from './Nationalities';

import
{
  CategoriesStore,
} from './CategoriesStore';

import
{
  ComplaintsStore,
} from './ComplaintsStore';

export class BackendStores {
  connections: Connections;

  users = new UserStore(this);

  auth = new AuthStore(this);

  serviceProviders = new ServiceProvidersStore(this);

  doctors = new DoctorsStore(this);

  serviceProviderTypes = new ServiceProviderTypesStore(this);

  specialities = new SpecialitiesStore(this);

  governorates = new GovernorateStore(this);

  cities = new CityStore(this);

  grades = new GradesStore(this);

  admins = new AdminsStore(this);

  promocodes = new PromocodesStore(this);

  notifications = new NotificationsStore(this);

  clinics = new ClinicsStore(this);

  billsStore = new BillsStore(this);

  requests = new RequestsStore(this);

  insuranceCompaniesStore = new InsuranceCompaniesStore(this);

  staticTexts = new StaticTextStore(this);

  patientsStore = new PatientsStore(this);

  paymentStore = new PaymentStore(this);

  donationStore = new DonationStore(this);

  donationBillsStore = new DonationBillsStore(this);

  memberships = new MembershipsStore(this);

  accountants = new AccountantsStore(this);

  nationalities = new NationalitiesStore(this);

  categories = new CategoriesStore(this);

  complaints = new ComplaintsStore(this);

  constructor(
    public parent: Stores,
  ) { }

  hydrate() {
    return Promise.all([
      this.auth.hydrate(),
      this.users.hydrate(),
      this.patientsStore.hydrate(),
      this.staticTexts.hydrate(),
    ]);
  }

  updateConnections(connections: Connections) {
    this.connections = connections;
    this.requests.connections = connections;
    this.insuranceCompaniesStore.connections = connections;
    this.admins.connections = connections;
    this.notifications.connections = connections;
    this.paymentStore.connections = connections;
    this.governorates.connections = connections;
    this.cities.connections = connections;
    this.specialities.connections = connections;
    this.billsStore.connections = connections;
    this.clinics.connections = connections;
    this.serviceProviders.connections = connections;
    this.promocodes.connections = connections;
    this.grades.connections = connections;
    this.memberships.connections = connections;
    this.accountants.connections = connections;
    this.nationalities.connections = connections;
    this.users.connections = connections;
    this.serviceProviderTypes.connections = connections;
    this.patientsStore.connections = connections;
    this.doctors.connections = connections;
    this.auth.connections = connections;
    this.staticTexts.connections = connections;
    this.categories.connections = connections;
    this.complaints.connections = connections;
  }
}
