/* eslint-disable max-len */
import {
  action,
  observable,
  toJS,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  Patient,
} from 'shared';
import {
  Settings,
} from 'settings';
import {
  persist,
} from 'mobx-persist';
import {
  PatientsTable,
  RequestedPatient,
} from 'src/screens/patients/types';
import {
  BaseBackendStore,
} from '../types';
import {
  patientUrl,
  patientDeleteUrl,
  patients,
  download,
} from './requests';
import {
  BackendStores,
} from '..';
import {
  SearchObj,
} from '../DoctorsStore/types';

export class PatientsStore extends BaseBackendStore {
  @observable private _entityList: Patient[] = [];

  @observable searchResult: Patient[] = [];

  @persist('list') @observable _recentlyViewed: PatientsTable[] = [];

  @action getRecentlyViewed = async (): Promise<PatientsTable[]> => Promise.resolve(this._recentlyViewed);

  @observable recentlyViewed = new ListBackendEntity(
    this,
    '_recentlyViewed',
    this.getRecentlyViewed,
  );

  @observable filter: SearchObj = {
    filters: [],
  };

  @action updateRecentlyViewed = (patient: PatientsTable) => {
    const data = toJS(this._recentlyViewed) || [];
    if (!data.find((item) => item.id === patient.id)) {
      if (data.length >= 3) {
        data.shift();
      }
      data.unshift(patient);
      this._recentlyViewed = data;
      this.getRecentlyViewed();
    }
  };

  @action updateFilter = (item: SearchObj) => {
    this.filter = {
      ...this.filter,
      ...item,
    };
  };

  @action public getAll = async (options: any): Promise<Patient[]> => {
    if (options.search) {
      this.filter.search = {
        by: 'any',
        value: options.search,
      };
    }
    if (options.orderBy) {
      this.filter.sort = {
        by: options.orderBy.field,
        type: options.orderDirection.toUpperCase(),
      };
    }
    const response = await this.connections.backend.httpGet(`${patients}?take=${options.pageSize || 10}&page=${options.page + 1}&query=${JSON.stringify(this.filter)}`);
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: Patient;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: RequestedPatient): Promise<number> {
    const response = await this.connections.backend.httpPost(patients, data);
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action
  public async getOne(id: string): Promise<ModelOf<Patient>> {
    const response = await this.connections.backend.httpGet(patientUrl(id));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend.httpDelete(patientDeleteUrl(id.toString()));
    return Promise.resolve(response);
  }

  @action
  public async update(id: number, data: Partial<RequestedPatient>): Promise<void> {
    const response = await this.connections.backend.httpPut(patientUrl(id.toString()), data);
    return Promise.resolve(response);
  }

  public download() {
    return `${Settings.config.REACT_APP_BASE_URL + download}?query=${JSON.stringify(this.filter)}&lang=${this.parent.parent.ui.localization.currentLanguage.key}`;
  }
}
