import {
  baseScreen,
} from 'hoc';
import React from 'react';
import {
  ModuleTypes,
} from 'src/navigation/types';
import {
  ContainerModule,
} from '../container';
import {
  TabsModule,
} from '../tabs';
import {
  ModuleProps,
} from './types';

const localModule: React.FC<ModuleProps> = (props) => {
  const {
    route,
  } = props;
  if (route.config.type === ModuleTypes.CONTAINER) {
    if (route.config.Component) {
      return <route.config.Component />;
    }
    return (<ContainerModule route={route} />);
  }
  if (route.config.type === ModuleTypes.TABS) {
    return (<TabsModule route={route} />);
  }
  if (route.config.Component) {
    return <route.config.Component />;
  }
  return null;
};

export const Module = baseScreen(localModule, {
  allowedRoles: ['NONE', 'ADMIN'],
});
