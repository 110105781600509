import React from 'react';
import {
  Switch,
} from '@material-ui/core';
import {
  Props,
} from './types';

const TableToggleButton: React.FC<Props> = (props) => {
  const {
    activationConfirmationText,
    deactivationConfirmationText,
    onChange,
    isActive,
  } = props;

  return (
    <Switch
      checked={isActive}
      onChange={async () => {
        const response = window.confirm(isActive
          ? deactivationConfirmationText : activationConfirmationText);
        onChange(response);
      }}
      color="primary"
      inputProps={{
        'aria-label': 'secondary checkbox',
      }}
    />
  );
};

export default TableToggleButton;
