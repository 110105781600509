import {
  BillsDTO,
  BillStatus,
} from 'src/stores/backend/BillsStore/types';
import {
  BaseFormViewModel,
} from '../../types';

export class Bill extends BaseFormViewModel<BillsDTO> {
  id?: number;

  balance: number;

  date: Date;

  email: string;

  mobileNumber: string;

  start: Date;

  status: BillStatus;

  name: string;

  doctorId: number;

  service_provider_name: string;

  serviceProviderId: number;

  service_provider_type: string;

  constructor(data: BillsDTO) {
    super();
    this.id = data.id;
    this.balance = data.balance;
    this.date = data.dueDate;
    this.email = data.email;
    this.mobileNumber = data.mobileNumber;
    this.start = data.start;
    this.serviceProviderId = data.serviceProviderId;
    this.status = data.status;
    this.doctorId = data.doctorId;
    this.name = data.doctorName;
    this.service_provider_name = data.serviceProviderName;
    this.service_provider_type = data.type;
  }

  toDTO(): BillsDTO {
    return { } as BillsDTO;
  }
}

export enum ListFilter {
  ALL = 'all',
  BANK_ACCOUNT = 'bankAccount',
  WALLET = 'wallet',
}
