import React,
{
  useEffect,
  useState,
} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18n-js';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import {
  Props,
} from './types';

const DateRange: React.FC<Props> = (props) => {
  const {
    onChange,
    fromLabel,
    toLabel,
    defaultValue = {
      start: moment().startOf('year').toDate(),
      end: new Date(),
    },
  } = props;
  const [
    startDate,
    setStartDate,
  ] = useState(defaultValue.start);
  const [
    endDate,
    setEndDate,
  ] = useState(defaultValue.end);
  useEffect(() => {
    onChange(startDate, endDate);
  }, [startDate, endDate]);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          style={{
            marginRight: 20,
            marginLeft: 16,
          }}
          label={fromLabel || i18n.t('FROM')}
          value={startDate}
          onChange={(date) => {
            if (date && moment(date).isBefore(moment(endDate))) {
              setStartDate(date);
            }
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={toLabel || i18n.t('TO')}
          minDate={startDate}
          value={endDate}
          onChange={(date) => {
            if (date && moment(date).isAfter(moment(startDate))) {
              setEndDate(date);
            }
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DateRange;
