import moment from 'moment';

import {
  Patient,
} from 'shared';

import {
  FieldFileCommonTypes,
  RemoteFile,
} from 'src/components/GeneralFormView/FormField/types';

// import {
//   LanguagesKey,
// } from 'src/translations/types';
import {
  BaseFormViewModel,
} from '../types';
// import NationalitiesDTO from '../nationalities/types';

export class PatientsTable {
  name: string;

  mobileNumber: string;

  email: string;

  gender: string;

  age: number;

  id: number;

  isActive: boolean;

  isBanned: boolean;

  constructor(data: Patient) {
    this.age = data.dateOfBirth ? moment().diff(moment(data.dateOfBirth), 'years') : '' as any;
    this.email = data.user.email;
    this.gender = data.user.gender;
    this.mobileNumber = data.user.phone;
    this.name = data.user.name;
    this.id = data.id;
    this.isActive = data.isActive;
    this.isBanned = data.isBanned;
  }
}

export class PatientDTO extends BaseFormViewModel<Patient> {
  id?: number;

  name: string;

  phone: string;

  email: string;

  isActive: boolean;

  isBanned: boolean;

  gender: string[];

  avatar: RemoteFile[];

  dateOfBirth: Date;

  nationality?: string[];

  constructor(data: Patient) {
    super();
    this.id = data.id;
    this.gender = [data.user.gender];
    this.email = data.user.email;
    this.isActive = data.isActive;
    this.name = data.user.name;
    this.phone = data.user.phone;
    this.dateOfBirth = moment(data.dateOfBirth).toDate();
    this.nationality = [`${data.nationality.id}`];

    if (data.user.avatar) {
      this.avatar = [{
        source: data.user.avatar,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
  }

  toDTO(): Patient {
    return {} as Patient;
  }
}

export class RequestedPatient extends BaseFormViewModel<PatientDTO> {
  id?: number;

  name: string;

  phone: string;

  email: string;

  isActive: boolean;

  isBanned: boolean;

  gender: string;

  avatar: string;

  dateOfBirth: Date;

  constructor(data: PatientDTO) {
    super();
    this.id = data.id;
    this.gender = data.gender.shift() || '';
    this.email = data.email;
    this.isActive = data.isActive;
    this.name = data.name;
    this.phone = data.phone;
    this.dateOfBirth = data.dateOfBirth;
    if (data.avatar) {
      this.avatar = data.avatar[0]?.source;
    }
  }

  toDTO(): PatientDTO {
    return {} as PatientDTO;
  }
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}
