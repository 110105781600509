import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

export const Styles = makeStyles(() => createStyles({
  table: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderCollapse: 'collapse',
    margin: 24,
    marginTop: 0,
  },
  th: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderCollapse: 'collapse',
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    background: '#3f51b5',
    color: '#FFF',
    borderColor: '#000',
  },
  td: {
    borderWidth: 1,
    textAlign: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    borderCollapse: 'collapse',
    borderStyle: 'solid',
  },
  tr: {
    '&:hover': {
      backgroundColor: '#DDD',
    },
  },
  error: {
    color: '#f44336',
    paddingLeft: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
  },
}));
