import Path from 'path';

export enum ModuleTypes {
  SCREEN,
  CONTAINER,
  TABS,
}

interface RouteConfig<T extends ModuleTypes> {
  path: string;
  type: T;
  allowedRoles: string[];
  parent?: Route<ModuleTypes>;
  Component?: React.ComponentType<any>;
  name: string;
  sideNavConfig?: {
    icon?: any;
  }
}

export class Route<T extends ModuleTypes> {
  public children: Route<ModuleTypes>[] = [];

  constructor(
    public config: RouteConfig<T>,
  ) {
    if (!this.config.Component) {
      this.config.Component = (() => null) as any;
    }
  }

  getPath(): string {
    if (this.config.parent) {
      return Path.join(this.config.parent.getPath(), this.config.path);
    }
    return this.config.path;
  }

  addChild(route: Route<ModuleTypes>) {
    // if (this.config.allowedRoles.includes('ADMIN')) {
    route.config.parent = this;
    this.children.push(route);
    // }
    return this;
  }
}

export interface SideNavItem {
  name: string;
  icon: any;
  path: string;
  children?: SideNavItem[];
}
