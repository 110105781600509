import {
  EditorState,
} from 'draft-js';
import * as Yup from 'yup';
import ArrayFormField from './FormField/ArrayFormField';
import DateTimeField from './FormField/DateTimeField';
import imagePickerField from './FormField/FilePickerField';
import InputField from './FormField/InputField';
import LocationField from './FormField/LocationField';
import NestedFormField from './FormField/NestedFormField';
import RichTextField from './FormField/RichTextField';
import ScheduleField from './FormField/ScheduleField';
import SelectFormField from './FormField/SelectField';
import TextAreaField from './FormField/TextAreaFeild';
import ToggleField from './FormField/ToggleField';
import {
  GeneralFormFields,
} from './types';

export const FORM_FIELDS = {
  [GeneralFormFields.INPUT_FIELD]: {
    initialValue: '',
    validationSchema: Yup.string().required(),
    component: InputField,
  },
  [GeneralFormFields.TEXT_AREA]: {
    initialValue: '',
    validationSchema: Yup.string().required(),
    component: TextAreaField,
  },
  [GeneralFormFields.FILE_PICKER]: {
    initialValue: [],
    validationSchema: Yup.array(),
    component: imagePickerField,
  },
  [GeneralFormFields.NESTED_FORM]: {
    initialValue: {},
    validationSchema: Yup.object().required(),
    component: NestedFormField,
  },
  [GeneralFormFields.FIELD_ARRAY]: {
    initialValue: [],
    validationSchema: Yup.array().required().min(1),
    component: ArrayFormField,
  },
  [GeneralFormFields.SELECT_FIELD]: {
    initialValue: [],
    validationSchema: Yup.array().required().min(1),
    component: SelectFormField,
  },
  [GeneralFormFields.RICH_TEXT_FIELD]: {
    initialValue: EditorState.createEmpty(),
    validationSchema: Yup.object().required().test(
      'required',
      'field is required',
      (value: any) => {
        if (!value) {
          return false;
        }
        if (!value.getCurrentContent().hasText()) {
          return false;
        }
        return true;
      },
    ),
    component: RichTextField,
  },
  [GeneralFormFields.DATE_TIME_FIELD]: {
    initialValue: null,
    validationSchema: Yup.date().required(),
    component: DateTimeField,
  },
  [GeneralFormFields.TOGGLE_FIELD]: {
    initialValue: false,
    validationSchema: Yup.boolean().required(),
    component: ToggleField,
  },
  [GeneralFormFields.LOCATION_FIELD]: {
    initialValue: {
      latitude: 29.9577874,
      longitude: 31.3133217,
    },
    validationSchema: Yup.object({
      latitude: Yup.number().required(),
      longitude: Yup.number().required(),
    }).required(),
    component: LocationField,
  },
  [GeneralFormFields.SCHEDULE]: {
    initialValue: [],
    validationSchema: Yup.array().required(),
    component: ScheduleField,
  },
} as const;

export default GeneralFormFields;
