import {
  Patient,
} from 'shared';
import moment from 'moment';
import {
  Doctor,
} from 'src/screens/doctors/types';
import {
  ServiceProvider,
} from 'src/screens/requests/types';

export class Request {
  constructor(
    public agoraToken: string,
    public createdAt: Date,
    public diagnosis: string,
    public doctorNotes: string,
    public startedAt: string,
    public finishedAt: string,
    public isFreeRequest: boolean,
    public id: number,
    public isFeeCollected: boolean,
    public patient: Patient,
    public type: RequestType,
    public status: RequestStatus,
    public clinicId: number,
    public forOtherPatient: boolean,
    public from: Date | string,
    public otherPatientDateOfBirth: string,
    public otherPatientGender: string,
    public otherPatientNumber: string,
    public date: Date | string,
    public patientAddress: string,
    public isRated: boolean,
    public fees: number,
    public serviceProvider: ServiceProvider,
    public prescription: string,
    public doctorNotesImages: string[],
    public duration: number,
    public patientId: number,
    public doctorId: number,
    public doctor: Doctor,
    public paymentMethod: PaymentMethodType,
    public patientLocation: LocationValue,
    public otherPatientName?: string,
    public patientNotes?: string,
  ) {}

  public static toTableData = (data: Request): RequestTable => {
    let time = '';
    if (data.from) {
      time = moment(data.from, 'HH:mm:ss').format('HH:mm');
    } else if (moment(data.startedAt).isValid()) {
      time = moment(data.startedAt).format('HH:mm');
    }
    return {
      date: moment(data.date).format('YYYY-MM-DD'),
      status: data.status,
      time,
      doctor: data.doctor.user.name,
      doctorMobileNumber: data.doctor.user.phone,
      id: data.id,
      patient: data.patient.user.name,
      patientMobileNumber: data.patient.user.phone,
      consultation_type: data.type,
      doctorNotes: data.doctorNotes,
      startedAt: data.startedAt ? moment(data.startedAt).format('HH:mm') : '',
      finishedAt: data.finishedAt ? moment(data.finishedAt).format('HH:mm') : '',
      serviceProvider: data.serviceProvider?.coordinatorName,
    };
  };
}

export class RequestTable {
  id: number;

  consultation_type: string;

  date: string;

  startedAt: string;

  finishedAt: string;

  time: string;

  status: string;

  doctorMobileNumber: string;

  serviceProvider: string;

  doctor: string;

  patient: string;

  patientMobileNumber: string;

  doctorNotes: string;
}

export enum PaymentMethodType {
  CASH = 'Cash',
  CREDIT_CARD = 'Credit Card',
}

export enum RequestType {
  HOME_VISIT = 'Home Visit',
  ONLINE_CONSULTATION = 'Online Consultation',
  CLINIC = 'Clinic',
}

export interface LocationValue {
  latitude: number;
  longitude: number;
}

export enum RequestStatus {
  STARTED = 'Started',
  REQUESTED = 'Requested',
  CANCELLED = 'Cancelled',
  ACCEPTED = 'Accepted',
  PAID = 'Paid',
  FINISHED = 'Finished',
  EXPIRED = 'Expired',
}
