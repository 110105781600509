import {
  baseScreen,
} from 'hoc';
import React,
{
  useEffect,
  useState,
} from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  useHistory,
} from 'react-router';
import GeneralFormFields,
{
  FormFieldActions,
  SelectFieldItems,
} from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import * as Yup from 'yup';
import {
  FirebaseTopics,
  Notification,
} from '../types';
import Condition, {
  ConditionStatement,
  ConditionalStatementTypes,
  ConditionExpression,
} from '../../../components/GeneralFormView/Utils/Condition';

const notificationsForm: React.FC = () => {
  const {
    backend: {
      notifications,
      serviceProviders,
    },
  } = useStores();
  const audiences = [
    {
      title: i18n.t(FirebaseTopics.ALL),
      value: FirebaseTopics.ALL,
    },
    {
      title: i18n.t(FirebaseTopics.DOCTORS),
      value: FirebaseTopics.DOCTORS,
    },
    {
      title: i18n.t(FirebaseTopics.PATIENTS),
      value: FirebaseTopics.PATIENTS,
    },
    {
      title: i18n.t('HOSPITALS'),
      value: FirebaseTopics.SERVICE_PROVIDERS,
    },
  ];

  const [
    serviceProviderOptions,
    setServiceProviderOptions,
  ] = useState<SelectFieldItems<number>[]>([]);

  useEffect(() => {
    const loadServiceProviders = async () => {
      const data = await serviceProviders.getAll({});
      setServiceProviderOptions(data.map((item) => ({
        title: item.user.name,
        value: item.id,
      })));
    };
    loadServiceProviders();
  }, []);
  const history = useHistory();
  return (
    <GeneralFormView<any, Notification>
      title={i18n.t('Notifications')}
      identifier="id"
      defaultLang=""
      otherLanguages={[]}
      formData={new FormFieldsBuilder<Notification>()
        .addField('arabicText', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('ARABIC_TEXT'),
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')) as any,
          fieldOptions: {},
        })
        .addField('englishText', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('ENGLISH_TEXT'),
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')) as any,
          fieldOptions: {},
        })
        .addField('audience', {
          type: GeneralFormFields.SELECT_FIELD,
          title: i18n.t('AUDIENCE'),
          validationSchema: Yup.array().length(1, i18n.t('REQUIRED_FIELD')) as any,
          fieldOptions: {
            isMultiple: false,
            items: audiences,
          },
        })
        .addField('serviceProviderId', {
          type: GeneralFormFields.SELECT_FIELD,
          title: i18n.t('SELECT_SERVICE_PROVIDER'),
          validationSchema: Yup.array().when('audience', {
            is: (val: any) => val[0] === FirebaseTopics.SERVICE_PROVIDERS,
            then: Yup.array().length(1, i18n.t('REQUIRED_FIELD')),
            otherwise: Yup.array().notRequired(),
          }) as any,
          condition: (values, actions) => new Condition({
            values,
            initialStatement: new ConditionStatement({
              action: actions[FormFieldActions.ENABLE],
              type: ConditionalStatementTypes.IF,
              expression: new ConditionExpression({
                values,
                expression: (val) => val.audience[0] === FirebaseTopics.SERVICE_PROVIDERS,
              }),
            }),
          }).addElse(new ConditionStatement({
            action: actions[FormFieldActions.DISABLE],
            type: ConditionalStatementTypes.ELSE,
            expression: new ConditionExpression({
              values,
              expression: () => true,
            }),
          })),
          fieldOptions: {
            isMultiple: false,
            items: serviceProviderOptions,
          },
        })
        .formFields}
      isFetchSuccessful
      update={async () => Promise.resolve()}
      create={async (data) => {
        try {
          const values: any = data;
          const serviceProviderId = data.audience === FirebaseTopics.SERVICE_PROVIDERS && values.serviceProviderId
            ? values.serviceProviderId[0] : null;
          await notifications.create({
            ...data, audience: data.audience[0], serviceProviderId,
          });
          history.push('/custom-notification-confirmation');
          return Promise.resolve(1);
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async () => Promise.resolve({} as any)}
    />
  );
};

export const NotificationsForm = baseScreen(notificationsForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
