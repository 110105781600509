/* eslint-disable max-classes-per-file */
import React,
{
  useRef,
  useState,
} from 'react';
import {
  useStores,
} from 'hooks';
import {
  Assets,
} from 'assets';
import {
  baseScreen,
} from 'hoc';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import i18n from 'i18n-js';
import {
  useHistory,
} from 'react-router';
import SearchFilterDropdown from 'src/components/SearchFilterComponent';
import TableToggleButton from 'src/components/TableToggleButton';
import {
  PatientsTable,
} from './types';
import GeneralTableView from '../../components/GeneralTableView';
import {
  ListFilter,
} from '../doctors/types';

const patientsScreen: React.FC = () => {
  const {
    backend: {
      patientsStore,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  const [
    currentFilter,
    setCurrentFilter,
  ] = useState((history.location.state as any)?.filter);
  const onFilterChange = (val: string) => {
    setCurrentFilter(val as ListFilter);
    ref.current?.onQueryChange();
  };
  return (
    <div>
      <SearchFilterDropdown
        label="Filter"
        style={{
          margin: 16,
        }}
        searchFilterDropdown={{
          filters: [
            {
              name: i18n.t('ALL'),
              value: ListFilter.ALL,
            },
            {
              name: i18n.t('RECENTLY_VIEWED'),
              value: ListFilter.RECENTLY_VIEWED,
            },

          ],
          value: currentFilter || 'all',
          initialValue: currentFilter || 'all',
          onChange: onFilterChange,
        }}
      />
      <GeneralTableView<PatientsTable>
        tableProps={{
          tableRef: ref,
          title: i18n.t('PATIENTS'),
          actions: [
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                // console.log("add new  doctor")
                history.push('/patients-form/new/:id');
              },
              tooltip: i18n.t('ADD_NEW_PATIENT'),
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const item: any = data;
                history.push(`/patients-form/edit/${item.id}`);
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (event, data) => {
                const patient: any = data;
                history.push(`/patient-details/${patient.id}`);
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.trash}
                  alt={i18n.t('DELETE')}
                />
              ),
              onClick: async (event, data) => {
                if (!Array.isArray(data)) {
                  const response = window.confirm(i18n.t('DELETE_PATIENT_CONFIRMATION'));
                  if (response) {
                    await patientsStore.delete(data.id);
                    if (ref && ref.current) {
                      ref.current.onQueryChange();
                    }
                  }
                }
              },
            },
            {
              isFreeAction: true,
              icon: ImportExportIcon,
              onClick: () => {
                window.open(patientsStore.download(), '_blanck');
              },
              tooltip: i18n.t('EXPORT_DATA'),
            },
          ],
        }}
        options={{
          search: true,
          actionsColumnIndex: 10,
          pageSize: 10,
          filtering: false,
        }}
        get={async (query) => {
          if (currentFilter === ListFilter.RECENTLY_VIEWED) {
            const data = patientsStore._recentlyViewed || [];
            return ({
              data,
              totalCount: 20,
              page: 1,
            });
          }
          await patientsStore.entityList.updateOptions(query);
          return {
            data: patientsStore.entityList.data.map((item) => new PatientsTable(item)),
            totalCount: patientsStore.entityList.meta.itemCount,
            page: patientsStore.entityList.meta.page - 1,
          };
        }}
        tableColumns={[
          {
            field: 'name',
            title: i18n.t('NAME'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'mobileNumber',
            title: i18n.t('MOBILE_NUMBER'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'email',
            title: i18n.t('EMAIL'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'gender',
            title: i18n.t('GENDER'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'age',
            title: i18n.t('AGE'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'isActive',
            title: i18n.t('IS_ACTIVE'),
            type: TableColumnTypes.BOOLEAN,
            columnProps: {
              editable: 'always',
              render: (data) => (
                <TableToggleButton
                  isActive={!data.isActive}
                  activationConfirmationText={i18n.t('ACTIVATE_PATIENT_CONFIRMATION')}
                  deactivationConfirmationText={i18n.t('DEACTIVATE_PATIENT_CONFIRMATION')}
                  onChange={async (response) => {
                    if (response) {
                      await patientsStore.update(data.id, {
                        isActive: !data.isActive,
                      });
                      if (ref && ref.current) {
                        ref.current.onQueryChange();
                      }
                    }
                  }}
                />
              ),
            },
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'isBanned',
            title: i18n.t('IS_BANNED'),
            type: TableColumnTypes.BOOLEAN,
            columnProps: {
              editable: 'always',
              render: (data) => (
                <TableToggleButton
                  isActive={data.isBanned}
                  activationConfirmationText={i18n.t('ACTIVATE_PATIENT_CONFIRMATION')}
                  deactivationConfirmationText={i18n.t('DEACTIVATE_PATIENT_CONFIRMATION')}
                  onChange={async (response) => {
                    if (response) {
                      await patientsStore.update(data.id, {
                        isBanned: !data.isBanned,
                      });
                      if (ref && ref.current) {
                        ref.current.onQueryChange();
                      }
                    }
                  }}
                />
              ),
            },
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const Patients = baseScreen(patientsScreen, {
  allowedRoles: ['NONE', 'ADMIN'],
});
