import React, {
  useState,
  useEffect,
} from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Input,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Gmaps,
  Marker,
  Circle,
} from 'react-gmaps';
import {
  isNil,
} from 'lodash';
import {
  LocationRadiusFilterProps,
  FilterLocationRadius,
} from './types';
import {
  MapLocation,
} from '../../../GeneralFormView/types';

const params = {
  v: '3.exp',
  key: 'AIzaSyAC1kD_cNz3nZnhHwRmZL4Zg5jkSCqaqXc',
};

// eslint-disable-next-line max-len
const formatLocationDefault = (location: FilterLocationRadius) => `long: ${location.longitude}, lat: ${location.latitude}, Radius: ${location.radius}`;

const LocationRadiusFilter = <RowData extends Record<string, unknown>>(props: LocationRadiusFilterProps<RowData>) => {
  const {
    columnDef,
    onFilterChanged,
  } = props;

  const {
    tableData,
    filterProps = {},
  } = columnDef;

  const {
    formatLocation = formatLocationDefault,
  } = filterProps;

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<FilterLocationRadius>({
    latitude: 0,
    longitude: 0,
    radius: 5,
  });
  const [filter, setFilter] = useState<FilterLocationRadius | undefined>();

  const [selectedMapLocation, setSelectedMapLocation] = useState<MapLocation>({
    latitude: 0,
    longitude: 0,
  });
  const [mapRef, setMapRef] = useState<any>();
  const [zoomValue, setZoomValue] = useState<number>(5);

  useEffect(() => {
    setSelectedLocation({
      ...selectedLocation,
      ...selectedMapLocation,
    });
  }, [
    selectedMapLocation,
  ]);

  const openDialog = () => {
    setModalOpen(true);
  };

  const closeDialog = () => {
    setModalOpen(false);
  };

  const clearFilter = () => {
    setFilter(undefined);
    onFilterChanged(tableData.id.toString(), undefined);
  };

  const resetModalValues = () => {
    if (filter) {
      setSelectedLocation(filter);
    } else {
      setSelectedLocation({
        latitude: 0,
        longitude: 0,
        radius: 5,
      });
    }
  };

  const onPressOpenFilter = () => {
    openDialog();
  };

  const onPressClear = () => {
    setSelectedLocation({
      latitude: 0,
      longitude: 0,
      radius: 5,
    });
    clearFilter();
  };

  const onPressCancel = () => {
    closeDialog();
  };

  const onPressFilter = () => {
    closeDialog();
    setFilter(selectedLocation);
    onFilterChanged(columnDef.tableData.id.toString(), selectedLocation);
  };

  const onDialogClose = () => {
    closeDialog();
  };

  useEffect(() => {
    if (!modalOpen) {
      resetModalValues();
    }
  }, [
    modalOpen,
  ]);

  const onMapCreated = (map: any) => {
    map.setOptions({
      disableDefaultUI: false,
    });
  };

  function onDragEnd(e: any) {
    setSelectedMapLocation({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={onPressOpenFilter}
        >
          <Typography variant="button">
            {
              (filter)
                ? (
                  `${formatLocation(selectedLocation)}`
                )
                : (
                  'Filter'
                )
            }
          </Typography>
        </Button>
        {
          !!filter && (
            <IconButton
              onClick={onPressClear}
            >
              <ClearIcon />
            </IconButton>
          )
        }
      </div>
      <Dialog
        open={modalOpen}
        onClose={onDialogClose}
        maxWidth={false}
      >
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '80vw',
              maxWidth: 600,
            }}
          >
            <Input
              value={selectedLocation.radius}
              type="number"
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                if (!isNil(value) && !Number.isNaN(value) && value >= 1) {
                  setSelectedLocation({
                    ...selectedLocation,
                    radius: value,
                  });
                }
              }}
            />
            <Gmaps
              ref={setMapRef}
              borderRadius="5px"
              width="100%"
              height="512px"
              lat={selectedLocation.latitude}
              lng={selectedLocation.longitude}
              zoom={zoomValue}
              loadingMessage="Loading..."
              params={params}
              onMapCreated={onMapCreated}
              onZoomChanged={() => {
                if (mapRef && mapRef.map) {
                  setZoomValue(mapRef.map.zoom);
                }
              }}
            >
              <Marker
                lat={selectedLocation.latitude}
                lng={selectedLocation.longitude}
                draggable
                onDragEnd={onDragEnd}
              />
              <Circle
                lat={selectedLocation.latitude}
                lng={selectedLocation.longitude}
                radius={selectedLocation.radius}
              />
            </Gmaps>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onPressFilter}
          >
            <Typography>Filter</Typography>
          </Button>
          <Button
            onClick={onPressCancel}
          >
            <Typography>Cancel</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LocationRadiusFilter;
