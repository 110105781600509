import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  Assets,
} from 'assets';
import AddIcon from '@material-ui/icons/Add';
import {
  useHistory,
} from 'react-router';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import TableToggleButton from 'src/components/TableToggleButton';
import {
  Accountant,
} from './types';

const accountants: React.FC = () => {
  const {
    backend: {
      accountants: accountantStore,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  return (
    <div>
      <GeneralTableView<Accountant>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: true,
            paging: true,
            pageSize: 20,
          },
          title: i18n.t('ACCOUNTANTS'),
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.trash}
                  alt={i18n.t('DELETE')}
                />
              ),
              onClick: async (event, data) => {
                if (!Array.isArray(data)) {
                  const response = window.confirm(i18n.t('DELETE_ACCOUNTANT_CONFIRMATION'));
                  if (response) {
                    await accountantStore.delete(data.id as number);
                    if (ref && ref.current) {
                      ref.current.onQueryChange();
                    }
                  }
                }
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const accountant: any = data;
                history.push(`/accountants-form/edit/${accountant.id}`);
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (event, data) => {
                const accountant: any = data;
                history.push(`/accountants-form/view/${accountant.id}`);
              },
            },
            {
              isFreeAction: true,
              icon: ImportExportIcon,
              onClick: () => {
                window.open(accountantStore.download(), '_blanck');
              },
              tooltip: i18n.t('EXPORT_DATA'),
            },
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                history.push('/accountants-form/new/new');
              },
              tooltip: i18n.t('ADD_NEW_ACCOUNTANT'),
            },
          ],
        }}
        options={{
          search: false,
          filtering: false,
        }}
        get={async (query) => {
          await accountantStore.entityList.updateOptions(query);
          return {
            data: toJS(accountantStore.entityList.data).map((item: any) => new Accountant(item)),
            totalCount: accountantStore.entityList.meta.itemCount,
            page: accountantStore.entityList.meta.page - 1,
          };
        }}
        tableColumns={[
          {
            field: 'id',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'name',
            title: i18n.t('NAME'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'phone',
            title: i18n.t('MOBILE_NUMBER'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'email',
            title: i18n.t('EMAIL'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          // {
          //   field: 'role',
          //   title: i18n.t('ROLE'),
          //   type: TableColumnTypes.STRING,
          //   filterType: FilterTypes.VALUE,
          // },
          {
            field: 'isActive',
            title: i18n.t('IS_ACTIVE'),
            type: TableColumnTypes.BOOLEAN,
            columnProps: {
              editable: 'always',
              render: (data) => (
                <TableToggleButton
                  isActive={data.isActive}
                  activationConfirmationText={i18n.t('ACTIVATE_ACCOUNTANT_CONFIRMATION')}
                  deactivationConfirmationText={i18n.t('DEACTIVATE_ACCOUNTANT_CONFIRMATION')}
                  onChange={async (response) => {
                    if (response) {
                      const accountant: any = data;
                      await accountantStore.updateAccount(accountant.id, {
                        isActive: !data.isActive,
                      });
                      if (ref && ref.current) {
                        ref.current.onQueryChange();
                      }
                    }
                  }}
                />
              ),
            },
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const Accountants = baseScreen(accountants, {
  allowedRoles: ['ADMIN', 'NONE'],
});
