import {
  baseScreen,
} from 'hoc';
import React from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  useHistory,
  useParams,
} from 'react-router';
import * as Yup from 'yup';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  Nationality,
} from '../nationalities/types';

const nationalitiesForm: React.FC = () => {
  const {
    backend: {
      nationalities,
    },
  } = useStores();
  const history = useHistory();
  const {
    id: nationalityId,
  } = useParams() as any;
  return (
    <GeneralFormView<any, Nationality>
      title={i18n.t('MEMBERSHIP')}
      identifier="id"
      defaultLang=""
      otherLanguages={[]}
      formData={new FormFieldsBuilder<Nationality>()
        .addField('englishName', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('ENGLISH_TITLE'),
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
          fieldOptions: {},
        })
        .addField('arabicName', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('ARABIC_TITLE'),
          fieldOptions: {},
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
        })
        .formFields}
      isFetchSuccessful
      update={async (id, data) => {
        try {
          if (nationalityId) {
            await nationalities.update(nationalityId, {
              translations: [
                {
                  languageCode: LanguagesKey.AR,
                  name: data.arabicName,
                }, {
                  languageCode: LanguagesKey.EN,
                  name: data.englishName,
                },
              ],
            });
          }
          history.push('/system-setup/system-setup/nationalities');
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      create={async (data) => {
        try {
          await nationalities.create({
            translations: [
              {
                languageCode: LanguagesKey.AR,
                name: data.arabicName,
              }, {
                languageCode: LanguagesKey.EN,
                name: data.englishName,
              },
            ],
          });
          history.push('/system-setup/system-setup/nationalities');
          return Promise.resolve(1);
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async () => {
        let res: any = {};
        if (nationalityId) {
          res = await nationalities.getOne(nationalityId);
        }
        return Promise.resolve(new Nationality(res));
      }}
    />
  );
};

export const NaitonalitiesForm = baseScreen(nationalitiesForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
