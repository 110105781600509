import {
  NationalitiesDTO,
} from 'src/stores/backend/Nationalities/types';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  BaseFormViewModel,
} from '../types';

export class Nationality extends BaseFormViewModel<NationalitiesDTO> {
  id?: number;

  isWithHightPricing: boolean;

  englishName: string;

  arabicName: string;

  constructor(data:NationalitiesDTO) {
    super();
    this.id = data.id;
    this.isWithHightPricing = data.isWithHighPricing;
    this.englishName = data.translations?.filter((item) => item.languageCode === LanguagesKey.EN)[0].name;
    this.arabicName = data.translations?.filter((item) => item.languageCode === LanguagesKey.AR)[0].name;
  }

  toDTO(): NationalitiesDTO | Promise<NationalitiesDTO> {
    return {} as NationalitiesDTO;
  }
}

export default NationalitiesDTO;
