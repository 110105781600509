/* eslint-disable */
import {
  DAY,
} from 'src/components/GeneralFormView/FormField/ScheduleField/types';
import {
  FieldFileCommonTypes,
  isRemoteFile,
  // isRemoteFile,
  // LocalFile,
  RemoteFile,
} from 'src/components/GeneralFormView/FormField/types';
import {
} from 'utils';
import {
  identity,
  pickBy,
  sortBy,
} from 'lodash';
import {
  uploadFile,
} from 'src/utils/aws-file-manager';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  BaseFormViewModel,
} from '../types';
import {
  Clinic,
  Doctor,
  Membership,
  Rating,
} from '../doctors/types';
import {
  getCountryCode,
} from '../utils';
// import { uploadFile } from 'src/utils/aws-file-manager';

export interface Schedule {
  id?: number;
  fees: number;
  type: ScheduleType,
  workingDays: WorkingDay[];
}
export interface DoctorMembership{
  id: number;
  discountValue: number;
  validUntil?: Date;
}
export interface Grade {
  id: number;
  title: string;
}

export enum ScheduleType {
  HOME_VISIT = 'Home Visit',
  ONLINE_CONSULTATION = 'Online Consultation',
  CLINIC = 'Clinic',
}
export enum DoctorStatus {
  'ONLINE' = 'Online',
  'OFFLINE' = 'Offline',
  'BUSY' = 'Busy',
}

export interface WorkingDay {
  id: number;
  day: DAY;
  to: string;
  from: string;
  isActive: boolean;
  containsBreak: boolean;
  breakFrom?: string;
  breakTo?: string;
  order: number;
}
export class DoctorModelView extends BaseFormViewModel<Doctor> {
  id?: number;

  clinics: Clinic;

  membership: Membership;

  grade?: string[];

  homeVisitFees: number;

  scheduleHomeVisitFees: number;

  isASAPCalls: boolean;

  isFreeCall: boolean;

  nationalIdFrontURL: RemoteFile[];

  syndicateNumber: string;

  name: string;

  memberships?: DoctorMembership[];

  homeVisitSchedule: WorkingDay[];

  onlineConsultationSchedule: WorkingDay[];

  homeVisitScheduleId?: number;

  onlineConsultationScheduleId?: number;

  clinicScheduleId?: number;

  clinicSchedule: WorkingDay[];

  isFreeVisit: boolean;

  certificateId: RemoteFile[];

  syndicateIdURL: RemoteFile[];

  website: string;

  taxId: RemoteFile[];

  isInCall: boolean;

  languages?: string[];

  onlineConsultationFees: number;

  scheduleOnlineConsultationFees: number;

  refundPercentageOnCancellation:number;

  nationalId: string;

  scheduleClinicFees: number;

  nationalIdBackURL: RemoteFile[];

  ratings?: Rating[];

  specialities?: string[];

  status: DoctorStatus;

  schedules: Schedule[];

  avatar: RemoteFile[];

  aboutEn: string;

  aboutAr: string;

  email: string;

  arabicName?: string;

  phone: string;

  countryCode: string[];

  instapayUrl?: string;

  urgentReservationPercentage : number;

  higherPricingPercentage: number;

  constructor(dto: Doctor) {
    super();
    if (dto.user) {
      this.name = dto.user.name;
      if (dto.nationalIdBackURL) {
        this.nationalIdBackURL = [{
          source: dto.nationalIdBackURL,
          type: FieldFileCommonTypes.IMAGE,
        }];
      }
      if (dto.nationalIdFrontURL) {
        this.nationalIdFrontURL = [{
          source: dto.nationalIdFrontURL,
          type: FieldFileCommonTypes.IMAGE,
        }];
      }
      this.onlineConsultationFees = dto.onlineConsultationFees;

      this.refundPercentageOnCancellation = dto.refundPercentageOnCancellation;

      this.countryCode = [`+${getCountryCode(dto.user.phone)}`];
      this.phone = dto.user.phone.replace(`${this.countryCode[0]}`, '');
      this.status = dto.status;
      this.nationalId = dto.nationalID;
      this.instapayUrl = dto.instapayUrl;
      this.higherPricingPercentage = dto.higherPricingPercentage;
      this.urgentReservationPercentage = dto.urgentReservationPercentage;
      if (dto.taxIdURL) {
        this.taxId = [{
          source: dto.taxIdURL,
          type: FieldFileCommonTypes.IMAGE,
        }];
      }
      if (dto.syndicateIdURL) {
        this.syndicateIdURL = [{
          source: dto.syndicateIdURL,
          type: FieldFileCommonTypes.IMAGE,
        }];
      }
      this.languages = dto.languages;
      this.syndicateNumber = dto.syndicateNumber;
      this.website = dto.website;
      const homeVisitSchedule = dto.schedules
        ?.filter((schedule) => schedule.type === ScheduleType.HOME_VISIT)[0];
      const onlineConsultationSchedule = dto.schedules
        ?.filter((schedule) => schedule.type === ScheduleType.ONLINE_CONSULTATION)[0];
      const clinicSchedule = dto.schedules
        ?.filter((schedule) => schedule.type === ScheduleType.CLINIC)[0];
      if (homeVisitSchedule) {
        this.homeVisitScheduleId = homeVisitSchedule?.id;
        this.homeVisitSchedule = sortBy(homeVisitSchedule.workingDays, (o) => o.order);
        this.scheduleHomeVisitFees = homeVisitSchedule.fees;
      }
      if (onlineConsultationSchedule) {
        this.onlineConsultationSchedule = sortBy(onlineConsultationSchedule.workingDays, (o) => o.order);
        this.onlineConsultationScheduleId = onlineConsultationSchedule?.id;
        this.scheduleOnlineConsultationFees = onlineConsultationSchedule.fees;
      }
      if (clinicSchedule) {
        this.clinicScheduleId = clinicSchedule?.id;
        this.clinicSchedule = sortBy(clinicSchedule.workingDays, (o) => o.order);
        this.scheduleClinicFees = clinicSchedule.fees;
      }
      if (dto.user.avatar) {
        this.avatar = [{
          source: dto.user.avatar,
          type: FieldFileCommonTypes.IMAGE,
        }];
      }
      if (dto.certificates) {
        this.certificateId = dto.certificates.map((item) => ({
          source: item,
          type: FieldFileCommonTypes.IMAGE,
        }));
      }
      const {
        user: {
          translations,
        },
      } = dto;
      /* eslint-disable */
      this.aboutAr = translations?.filter((item) => item.languageCode === LanguagesKey.AR)[0]?.about;
      this.aboutEn = translations?.filter((item) => item.languageCode === LanguagesKey.EN)[0]?.about;
      this.email = dto.user.email;
      this.arabicName = dto.arabicName;
      this.grade = [dto.grade?.id.toString()];
      this.homeVisitFees = dto.homeVisitFees;
      this.id = dto.id;
      this.specialities = dto.specialities?.map((item) => item.id);
      this.memberships = dto.memberships?.map((item)=>({
        id:item.id,
        validUntil:item.validUntil,
        discountValue:item.discountValue
      }))
    }
  }

  static async formatObject(data: DoctorModelView): Promise<Doctor> {
    const values: any = data;
    const value: any = {
      name: data.name,
      syndicateNumber: data.syndicateNumber,
      phone: `${data.countryCode}${data.phone}`,
      email: data.email,
      specialityIds: values.specialities.map((el: string) => Number.parseInt(el, 10)),
      gradeId: Number.parseInt(values?.grade[0], 10),
      website: data.website,
      languages: data.languages,
      avatar: data.avatar[0]?.source,
      nationalID: data.nationalId,
      syndicateIdURL: data.syndicateIdURL[0]?.source,
      nationalIdBackURL: data.nationalIdBackURL[0]?.source,
      nationalIdFrontURL: data.nationalIdFrontURL[0]?.source,
      taxIdURL: data.taxId[0]?.source,
      certificates: data.certificateId.map((item) => item.source),
      memberships:data.memberships,
    };
    // if (!!data.avatar[0] && !isRemoteFile(data.avatar[0])) {
    //   value.avatar = await uploadFile({
    //     file: values.avatar[0].file, filePath: `avatar-${Date.now()}`,
    //   });
    // }
    // if (!!data.syndicateIdURL[0] && !isRemoteFile(data.syndicateIdURL[0])) {
    //   value.syndicateIdURL = await uploadFile({
    //     file: values.syndicateIdURL[0].file, filePath: `syndicateIdURL-${Date.now()}`,
    //   });
    // }
    // if (!!data.nationalIdFrontURL[0] && !isRemoteFile(data.nationalIdFrontURL[0])) {
    //   value.nationalIdFrontURL = await uploadFile({
    //     file: values.nationalIdFrontURL[0].file, filePath: `nationalIdFrontURL-${Date.now()}`,
    //   });
    // }
    // if (!!data.nationalIdBackURL[0] && !isRemoteFile(data.nationalIdBackURL[0])) {
    //   value.nationalIdBackURL = await uploadFile({
    //     file: values.nationalIdBackURL[0].file, filePath: `nationalIdBackURL-${Date.now()}`,
    //   });
    // }
    // if (!!data.taxId[0] && !isRemoteFile(data.taxId[0])) {
    //   value.taxIdURL = await uploadFile({
    //     file: values.taxId[0].file, filePath: `taxId-${Date.now()}`,
    //   });
    // }
    // value.certificates = await Promise.all(values.certificateId.map((item: any, index: number) => {
    //   if (!!item && !isRemoteFile(item)) {
    //     return uploadFile({
    //       file: item.file, filePath: `certificateId-${index}-${Date.now()}`,
    //     });
    //   }
    //   return Promise.resolve(item?.source);
    // }));
    const dr: any = pickBy({
      website: value.website,
      syndicateNumber: value.syndicateNumber,
      nationalIdFrontURL: value.nationalIdFrontURL,
      nationalIdBackURL: value.nationalIdBackURL,
      nationalID: data.nationalId,
      syndicateIdURL: value.syndicateIdURL,
      taxIdURL: value.taxIdURL,
      avatar: value.avatar,
      gradeId: value.gradeId,
      specialityIds: value.specialityIds,
      email: data.email,
      name: data.name,
      phone: value.phone,
      certificates: value.certificates,
      memberships:value.memberships,
      languages: data.languages,
      translations: [
        {
          languageCode: 'en',
          name: values.name,
          about: data.aboutEn,
        },
        {
          languageCode: 'ar',
          name: values.name,
          about: data.aboutAr,
        },
      ],
    }, identity);
    dr.homeVisitFees = 0;
    dr.onlineConsultationFees = 0;

    return dr as any;
  }

  toDTO(): Doctor {
    return {} as any;
  }
}

/* eslint-disable-next-line */
export enum SCREEN_MODES {
  EDIT = 'edit',
  CREATE = 'new',
  VIEW = 'view',
}

export enum Languages {
  AR = 'ar',
  EN = 'en',
  FR = 'fr',
  ES = 'es',
  IT = 'it',
}
