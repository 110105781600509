import React from 'react';
import {
  useStores,
  useConnections,
} from 'hooks';

export const ProviderBridge: React.FC = (props) => {
  const stores = useStores();
  const connections = useConnections();
  connections.backend.refresh = stores.backend.auth.refresh;
  connections.backend.getAccessToken = stores.backend.auth.getAccessToken;
  connections.backend.setAccessToken = stores.backend.auth.setAccessToken;
  connections.backend.getRefreshToken = stores.backend.auth.getRefreshToken;
  connections.backend.setRefreshToken = stores.backend.auth.setRefreshToken;
  connections.backend.logout = stores.backend.auth.logout;
  stores.backend.updateConnections(connections);
  stores.backend.auth.updateTokenHeader = connections.backend.updateTokenHeader;
  const {
    children,
  } = props;
  return (
    <>
      {
        children
      }
    </>
  );
};
