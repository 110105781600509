/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  ListBackendEntity,
} from 'utils';
import {
  BaseBackendStore,
} from '../types';
import {
  notifications,
  notification,
  sendNotification,
} from './requests';
import {
  NotificationsDTO,
} from './types';
import {
  BackendStores,
} from '..';

export class NotificationsStore extends BaseBackendStore {
  @observable private _entityList: NotificationsDTO[] = [];

  @action public getAll = async (options: any): Promise<NotificationsDTO[]> => {
    console.log(options);
    const response = await this.connections.backend
      .httpGet(`${notifications}`);
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: NotificationsDTO;

  // @observable selectedEntity = new BackendEntity(
  //   this,
  //   '_selectedEntity',
  //   this.getOne,
  // );

  @action
  public async create(data: NotificationsDTO): Promise<void> {
    try {
      await this.connections.backend.httpPost(notifications, data);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  }

  // @action
  // public async getOne(id: string): Promise<ModelOf<NotificationsDTO>> {
  //   const response = await this.connections.backend.httpGet(notification(parseInt(id, 10)));
  //   this.selectedEntity = response;
  //   return Promise.resolve(response);
  // }

  // @action updateNotification = async (id: number, data: Partial<NotificationsDTO>) => {
  //   const response = await this.connections.backend.httpPut(notification(id), data);
  //   return Promise.resolve(response);
  // };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(notification(id));
    return Promise.resolve(response);
  }

  @action send = async (id: number) => {
    await this.connections.backend.httpPost(sendNotification(id), {});
  };
}
