import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({
  container: {
    width: '100%',
    display: 'flex',
  },
  form: {
    width: '100%',
  },
  select: {
    width: '30%',
  },
}));
