/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  BaseBackendStore,
} from '../types';
import {
  servicProviderTypes,
  servicProviderType,
} from './requests';
import {
  BackendStores,
} from '..';
import {
  ServicProviderTypeDTO,
} from './types';

export class ServiceProviderTypesStore extends BaseBackendStore {
  @observable private _entityList: ServicProviderTypeDTO[] = [];

  @action public getAll = async (options: any): Promise<ServicProviderTypeDTO[]> => {
    const response = await this.connections.backend
      .httpGet(`${servicProviderTypes}?take=${options.pageSize}&page=${options.page + 1}`);
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: ServicProviderTypeDTO;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: ServicProviderTypeDTO): Promise<void> {
    await this.connections.backend.httpPost(servicProviderTypes, data);
    return Promise.resolve();
  }

  @action
  public async getOne(id: string): Promise<ModelOf<ServicProviderTypeDTO>> {
    const response = await this.connections.backend.httpGet(servicProviderType(parseInt(id, 10)));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action updateServiceProviderType = async (id: number, data: Partial<ServicProviderTypeDTO>) => {
    const response = await this.connections.backend.httpPut(servicProviderType(id), data);
    return Promise.resolve(response);
  };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(servicProviderType(id));
    return Promise.resolve(response);
  }
}
