import {
  baseScreen,
} from 'hoc';
import React from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  useHistory,
  useParams,
} from 'react-router';
import * as Yup from 'yup';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  isRemoteFile,
  LocalFile,
} from 'src/components/GeneralFormView/FormField/types';
import {
  uploadFile,
} from 'src/utils/aws-file-manager';
import {
  InsuranceCompanyFormViewModel,
} from './types';

const insuranceCompaniesForm: React.FC = () => {
  const {
    backend: {
      insuranceCompaniesStore,
    },
  } = useStores();
  const history = useHistory();
  const {
    id: insuranceCompanyId,
  } = useParams() as any;
  return (
    <GeneralFormView<any, InsuranceCompanyFormViewModel>
      title={i18n.t('INSURANCE_COMPANIES')}
      identifier="id"
      defaultLang=""
      otherLanguages={[]}
      formData={new FormFieldsBuilder<InsuranceCompanyFormViewModel>()
        .addField('englishName', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('ENGLISH_TITLE'),
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
          fieldOptions: {},
        })
        .addField('arabicName', {
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
          title: i18n.t('ARABIC_TITLE'),
          fieldOptions: {},
        })
        .addField('logo', {
          type: GeneralFormFields.FILE_PICKER,
          title: i18n.t('LOGO'),
          validationSchema: Yup.array().length(1, i18n.t('REQUIRED_FIELD')) as any,
          fieldOptions: {
            size: 200,
            accept: 'image/*',
            multipleImages: false,
          },
        })
        .formFields}
      isFetchSuccessful
      update={async (id, data) => {
        try {
          let logo: any = data.logo[0];
          if (!isRemoteFile(logo)) {
            logo = await uploadFile({
              file: (data.logo[0] as LocalFile).file, filePath: `avatar-${Date.now()}`,
            });
          } else {
            logo = data.logo;
          }
          if (insuranceCompanyId) {
            await insuranceCompaniesStore.updateInsuranceCompany(insuranceCompanyId, {
              logoURL: logo,
              translations: [
                {
                  languageCode: LanguagesKey.AR,
                  title: data.arabicName,
                }, {
                  languageCode: LanguagesKey.EN,
                  title: data.englishName,
                },
              ],
            });
          }
          history.push('/system-setup/system-setup/insurance-companies');
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      create={async (data) => {
        try {
          let logo: any = data.logo[0];
          if (!isRemoteFile(logo)) {
            logo = await uploadFile({
              file: (data.logo[0] as LocalFile).file, filePath: `avatar-${Date.now()}`,
            });
          } else {
            logo = data.logo;
          }
          await insuranceCompaniesStore.create({
            logoURL: logo,
            translations: [
              {
                languageCode: LanguagesKey.AR,
                title: data.arabicName,
              }, {
                languageCode: LanguagesKey.EN,
                title: data.englishName,
              },
            ],
          });
          history.push('/system-setup/system-setup/insurance-companies');
          return Promise.resolve(1);
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async () => {
        let res: any = {};
        if (insuranceCompanyId) {
          res = await insuranceCompaniesStore.getOne(insuranceCompanyId);
        }
        return Promise.resolve(new InsuranceCompanyFormViewModel(res));
      }}
    />
  );
};

export const InsuranceCompaniesForm = baseScreen(insuranceCompaniesForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
