import {
  baseScreen,
} from 'hoc';
import React,
{
  useState,
} from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  useHistory,
} from 'react-router';
import * as Yup from 'yup';
import GeneralFormFields, {
  DateTimeFormFieldTypes,
  FormFieldActions,
} from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import Condition,
{
  ConditionalStatementTypes,
  ConditionExpression,
  ConditionStatement,
} from 'src/components/GeneralFormView/Utils/Condition';
import {
  HospitalSettlementFormModel,
  TransactionType,
} from './types';

const settlement: React.FC = () => {
  const {
    backend: {
      billsStore,
    },
  } = useStores();
  const [
    isBankNameEnabled,
    setBankNameEnabled,
  ] = useState<boolean>(false);
  const history = useHistory();

  return (
    <GeneralFormView<any, HospitalSettlementFormModel>
      title={i18n.t('SETTLEMENT')}
      defaultLang=""
      identifier="transactionId"
      otherLanguages={[]}
      formData={new FormFieldsBuilder<HospitalSettlementFormModel>()
        .addField('transactionType', {
          type: GeneralFormFields.SELECT_FIELD,
          title: i18n.t('TRANSACTION_TYPE'),
          validationSchema: Yup.array().min(1, i18n.t('REQUIRED_FIELD')) as any,
          fieldOptions: {
            isMultiple: false,
            items: [
              {
                title: i18n.t('CASH'),
                value: TransactionType.CASH,
              },
              {
                title: i18n.t('BANK_ACCOUNT'),
                value: TransactionType.BANK_ACCOUNT,
              },
            ],
            onValueChanged: (value) => {
              setBankNameEnabled(value === TransactionType.BANK_ACCOUNT);
            },
          },
        })
        .addField('transactionId', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('TRANSACTION_ID'),
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')) as any,
          fieldOptions: {
            type: 'number',
          },
        })
        .addField('date', {
          type: GeneralFormFields.DATE_TIME_FIELD,
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')).nullable() as any,
          title: i18n.t('DATE'),
          fieldOptions: {
            type: DateTimeFormFieldTypes.DATE,
          },
        })
        .addField('bankName', {
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup.string().when('transactionType', {
            is: (val: any) => val[0] === TransactionType.BANK_ACCOUNT,
            then: Yup.string().required(i18n.t('REQUIRED_FIELD')),
            otherwise: Yup.string().notRequired(),
          }) as any,
          condition: (values, actions) => new Condition({
            values,
            initialStatement: new ConditionStatement({
              action: actions[FormFieldActions.DISABLE],
              type: ConditionalStatementTypes.IF,
              expression: new ConditionExpression({
                values,
                expression: (val) => val.transactionType[0] !== TransactionType.BANK_ACCOUNT,
              }),
            }),
          }).addElse(new ConditionStatement({
            action: actions[FormFieldActions.ENABLE],
            type: ConditionalStatementTypes.ELSE,
            expression: new ConditionExpression({
              values,
              expression: () => true,
            }),
          })),
          title: i18n.t('BANK_NAME'),
          fieldOptions: {
            disabled: !isBankNameEnabled,
          },
        })
        .addField('receipt', {
          title: i18n.t('RECEIPT'),
          type: GeneralFormFields.FILE_PICKER,
          validationSchema: Yup.array().required().length(1, i18n.t('FILE_PICKER_FILED_LENGTH')) as any,
          fieldOptions: {
            size: 200,
            accept: 'image/*',
            multipleImages: false,
          },
        })
        .formFields}
      isFetchSuccessful
      create={async () => Promise.resolve(1)}
      update={async (id, data) => {
        try {
          const values = new HospitalSettlementFormModel(data);
          const value = await values.toDTO();
          await billsStore.settle({
            ...value, billId: Number.parseInt(id, 10),
          });
          history.goBack();
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async () => Promise.resolve({} as any)}
    />
  );
};

export const Settlement = baseScreen(settlement, {
  allowedRoles: ['ADMIN', 'NONE'],
});
