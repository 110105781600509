/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  BaseBackendStore,
} from '../types';
import {
  clinics,
  clinic,
  doctorClinics,
} from './requests';
import {
  BackendStores,
} from '..';
import {
  ClinicDTO,
} from './types';

export class ClinicsStore extends BaseBackendStore {
  @observable private _entityList: ClinicDTO[] = [];

  @action public getAll = async (options: any): Promise<ClinicDTO[]> => {
    console.log(options);
    const response = await this.connections.backend
      .httpGet(`${clinics}`);
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: ClinicDTO;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async getDoctorClinics(id: number): Promise<ClinicDTO[]> {
    const res = await this.connections.backend.httpGet(doctorClinics(id));
    return Promise.resolve(res);
  }

  @action
  public async create(data: ClinicDTO): Promise<void> {
    await this.connections.backend.httpPost(clinics, data);
    return Promise.resolve();
  }

  @action
  public async getOne(id: string): Promise<ModelOf<ClinicDTO>> {
    const response = await this.connections.backend.httpGet(clinic(parseInt(id, 10)));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action updateClinic = async (id: number, data: Partial<ClinicDTO>) => {
    const response = await this.connections.backend.httpPut(clinic(id), data);
    return Promise.resolve(response);
  };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(clinic(id));
    return Promise.resolve(response);
  }
}
