import moment from 'moment';
import {
  DonationDTO,
} from 'src/stores/backend/DonationStore/types';
import {
  RequestType,
} from 'src/stores/backend/RequestsStore/types';
import {
  BaseFormViewModel,
} from '../../types';

export class Donation extends BaseFormViewModel<DonationDTO> {
  id?: number;

  requestId: number;

  type: RequestType;

  date: string;

  time: string;

  patientName: string;

  total: string;

  kashfPercentage: number;

  subTotal: string;

  constructor(data: DonationDTO) {
    super();
    this.id = data.id;
    this.date = moment(data.createdAt).format('DD-MM-YYYY');
    this.time = moment(data.createdAt).format('HH:mm A');
    this.kashfPercentage = data.kashfPercentage;
    this.patientName = data.patient.user.name;
    this.requestId = data.request.id;
    this.subTotal = data.subTotal;
    this.total = data.amount;
    this.type = data.request.type;
  }

  toDTO(): DonationDTO {
    return { } as DonationDTO;
  }
}
