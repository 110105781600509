/* eslint-disable max-classes-per-file */
import React,
{
} from 'react';
import {
  baseScreen,
} from 'hoc';

const usersScreen: React.FC = () => (
  <div>
    Users
  </div>
);

export const Users = baseScreen(usersScreen, {
  allowedRoles: ['NONE', 'ADMIN'],
});
