import {
  ServicProviderTypeDTO,
} from 'src/stores/backend/ServiceProviderTypesStore/types';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  BaseFormViewModel,
} from '../types';

export class ServiceProviderType extends BaseFormViewModel<ServicProviderTypeDTO> {
  id?: number;

  englishName: string;

  arabicName: string;

  constructor(data: ServicProviderTypeDTO) {
    super();
    this.id = data.id;
    this.englishName = data.translations?.filter((item) => item.languageCode === LanguagesKey.EN)[0].title;
    this.arabicName = data.translations?.filter((item) => item.languageCode === LanguagesKey.AR)[0].title;
  }

  toDTO(): ServicProviderTypeDTO {
    return { } as ServicProviderTypeDTO;
  }
}
