import {
  baseScreen,
} from 'hoc';
import React,
{
  useEffect,
  useState,
} from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  useHistory,
  useParams,
} from 'react-router';
import * as Yup from 'yup';
import GeneralFormFields,
{
  DateTimeFormFieldTypes,
} from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';

import {
  Gender,
  PatientDTO,
  RequestedPatient,
} from '../patients/types';

import {
  ONLY_LETTERS_REGEX,
  ONLY_NUMBERS_REGEX,
} from '../utils';

const patientsForm: React.FC = () => {
  const {
    backend: {
      patientsStore,
      nationalities,
    },
    ui: {
      localization,
    },
  } = useStores();
  const {
    mode,
  } = useParams() as any;
  const history = useHistory();
  const [
    viewModeEnabled,
  ] = useState(mode === 'view');
  const [
    selectedPatient,
    setSelectedPatient,
  ] = useState<PatientDTO>();
  const [
    nationalitiesList,
    setNationalitiesList,
  ] = useState<{title: string, value: string}[]>([]);

  const getNationalities = async () => {
    const list = await nationalities.getAll({});
    const nationalitiesData = list.map((item) => {
      const {
        name,
      } = item.translations
        .filter((el) => el.languageCode === localization.currentLanguage.key)[0];
      return {
        title: name,
        value: item?.id?.toString() as string,
      };
    });
    setNationalitiesList(nationalitiesData);
  };
  const form = new FormFieldsBuilder<PatientDTO>()
    .addField('avatar', {
      title: i18n.t('IMAGE'),
      type: GeneralFormFields.FILE_PICKER,
      validationSchema: Yup.array().required().length(1, i18n.t('FILE_PICKER_FILED_LENGTH')) as any,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addField('name', {
      type: GeneralFormFields.INPUT_FIELD,
      hasTranslations: true,
      title: i18n.t('NAME'),
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD'))
        .matches(ONLY_LETTERS_REGEX, i18n.t('ACCEPTS_LETTERS_ONLY')) as any,
      fieldOptions: {},
    })
    .addField('dateOfBirth', {
      type: GeneralFormFields.DATE_TIME_FIELD,
      title: 'Date of Birth',
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')).nullable() as any,
      fieldOptions: {
        type: DateTimeFormFieldTypes.DATE,
      },
    })
    .addField('gender', {
      type: GeneralFormFields.SELECT_FIELD,
      title: i18n.t('GENDER'),
      initialValue: selectedPatient?.gender,
      validationSchema: Yup.array().required(i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {
        isMultiple: false,
        items: [
          {
            title: 'Male',
            value: Gender.Male,
          },
          {
            title: 'Female',
            value: Gender.Female,
          },
        ],
      },
    })
    .addField('email', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('EMAIL'),
      validationSchema: Yup.string().email(i18n.t('EMAIL_VALIDATION')).required(i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {},
    })
    .addField('phone', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('MOBILE_NUMBER'),
      validationSchema: Yup.string().matches(ONLY_NUMBERS_REGEX, i18n.t('PHONE_VALIDATION'))
        .required(i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {},
    })
    .addField('nationality', {
      type: GeneralFormFields.SELECT_FIELD,
      title: i18n.t('NATIONALITY'),
      initialValue: selectedPatient?.nationality,
      validationSchema: Yup.array().min(1, i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {
        isMultiple: false,
        items: nationalitiesList,
      },
    });

  useEffect(() => {
    getNationalities();
  }, []);

  return (
    <GeneralFormView<any, PatientDTO>
      title={i18n.t('ADD_ACCOUNTANT')}
      identifier="id"
      defaultLang=""
      otherLanguages={[]}
      viewMode={viewModeEnabled}
      formData={form.formFields}
      isFetchSuccessful
      update={async (id, data) => {
        try {
          await patientsStore.update(Number.parseInt(id, 10), new RequestedPatient(data));

          history.push('/users/:id/patients');

          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      create={async (data) => {
        try {
          await patientsStore.create(
            new RequestedPatient(data),
          );
          history.push('/users/:id/patients');

          return Promise.resolve(1);
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async (id) => {
        const patient = await patientsStore.getOne(id);

        const patientUser = new PatientDTO(patient);

        setSelectedPatient(patientUser);

        return Promise.resolve(patientUser);
      }}
    />
  );
};

export const PatientsForm = baseScreen(patientsForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
