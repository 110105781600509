/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  Settings,
} from 'settings';
import {
  bills,
  bill,
  download,
  createBillTransaction,
} from './requests';
import {
  DonationBillsDTO,
  SettlementDTO,
} from './types';
import {
  BaseBackendStore,
} from '../types';
import {
  BackendStores,
} from '..';
import {
  SearchObj,
} from '../DoctorsStore/types';

export class DonationBillsStore extends BaseBackendStore {
  @observable private _entityList: DonationBillsDTO[] = [];

  filter: SearchObj = {};

  @action public getAll = async (options: any): Promise<DonationBillsDTO[]> => {
    const query: SearchObj = { };
    if (options.search) {
      query.search = {
        value: options.search,
      };
    }
    let type = '';
    if (options.type === 'service_providers') {
      type = '&type=service_providers';
    }
    if (options.orderBy) {
      query.sort = {
        by: options.orderBy.field,
        type: options.orderDirection.toUpperCase(),
      };
    }
    query.filters = options.filters;
    this.filter = query;
    const response = await this.connections.backend
      .httpGet(`${bills}?take=${options.pageSize}&page=${options.page + 1}&query=${JSON.stringify(query)}${type}`);
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: DonationBillsDTO;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: DonationBillsDTO): Promise<void> {
    await this.connections.backend.httpPost(bills, data);
    return Promise.resolve();
  }

  @action settle = async (data: SettlementDTO) => {
    try {
      await this.connections.backend.httpPost(createBillTransaction, data);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  };

  @action
  public async getOne(id: string): Promise<ModelOf<DonationBillsDTO>> {
    const response = await this.connections.backend.httpGet(bill(parseInt(id, 10)));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action updateBill = async (id: number, data: Partial<DonationBillsDTO>) => {
    const response = await this.connections.backend.httpPut(bill(id), data);
    return Promise.resolve(response);
  };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(bill(id));
    return Promise.resolve(response);
  }

  public download() {
    return `${Settings.config.REACT_APP_BASE_URL + download}?query=${JSON.stringify(this.filter)}&lang=${this.parent.parent.ui.localization.currentLanguage.key}`;
  }
}
