import {
  ServiceProvidersDTO,
} from 'src/stores/backend/ServiceProvidersStore/types';
import {
  BaseFormViewModel,
} from '../types';

export class ServiceProvider extends BaseFormViewModel<ServiceProvidersDTO> {
  id?: number;

  name: string;

  type: string;

  email: string;

  coordinatorName: string;

  coordinatorPhone: string;

  area: string;

  isActive: boolean;

  isAcceptingDonation: boolean;

  constructor(data: ServiceProvidersDTO) {
    super();
    this.id = data.id;
    this.name = data.user.name;
    this.type = data.serviceProviderType.title;
    this.email = data.user.email;
    this.coordinatorName = data.coordinatorName;
    this.isActive = data.isActive;
    this.isAcceptingDonation = data.isAcceptingDonation;
    console.log(this.isAcceptingDonation);
    this.coordinatorPhone = data.user.phone;
    this.area = data.area;
  }

  toDTO(): ServiceProvidersDTO {
    return { } as ServiceProvidersDTO;
  }
}
