import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import {
  drawerWidth,
} from 'navigation';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  appContainer: {
    display: 'flex',
    flex: 1,
    minWidth: `Calc(100%-${drawerWidth}px)`,
  },
  container: {
    minWidth: drawerWidth,
    height: '100vh',
    borderWidth: 2,
    borderColor: 'black',
    borderStyle: 'solid',
    position: 'fixed',
    zIndex: 11,
    background: 'white',
  },
  logoContainer: {
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 2,
    height: 220,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
    textDecoration: 'underline',
    color: 'gold',
    cursor: 'pointer',
  },
  chevron: {
    height: 24,
    width: 24,
  },
  kashfLogo: {
    width: 140,
    height: 140,
    display: 'block',
    alignSelf: 'center',
    marginTop: 24,
    marginBottom: 24,
  },
  sidemenuItem: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottomStyle: 'solid',
    borderTopStyle: 'solid',
    borderTopWidth: 0,
    cursor: 'pointer',
  },
  children: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 10,
    fontSize: 14,
  },
  child: {
    paddingTop: 10,
    paddingBottom: 10,
    cursor: 'pointer',
  },
  grandchild: {
    marginLeft: -20,
    marginRight: -20,
    fontSize: 12,
    cursor: 'pointer',
    paddingBottom: 10,
  },
  innerChevron: {
    width: 20,
    height: 20,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: -5,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  logo: {
    height: 80,
    width: 80,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: 'transparent',
  },
}));
