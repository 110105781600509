/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  LoadingState,
  ModelOf,
} from 'utils';
import {
  Settings,
} from 'settings';
import {
  BaseBackendStore,
} from '../types';
import {
  BackendStores,
} from '..';
import {
  serviceProviders,
  serviceProvider,
  download,
} from './requests';
import {
  ServiceProviderFormDTO,
  ServiceProvidersDTO,
} from './types';
import {
  SearchObj,
} from '../DoctorsStore/types';

export class ServiceProvidersStore extends BaseBackendStore {
  @observable private _entityList: ServiceProvidersDTO[] = [];

  filter: SearchObj = {};

  @observable loading: LoadingState;

  @action public getAll = async (options: any): Promise<ServiceProvidersDTO[]> => {
    const query: SearchObj = { };
    if (options.search) {
      query.search = {
        value: options.search,
      };
    }
    if (options.orderBy) {
      query.sort = {
        by: options.orderBy.field,
        type: options.orderDirection.toUpperCase(),
      };
    }
    this.filter = query;
    const response = await this.connections.backend
      .httpGet(`${serviceProviders}?query=${JSON.stringify(query)}`);
    return response;
  };

  public download() {
    return `${Settings.config.REACT_APP_BASE_URL + download}?query=${JSON.stringify(this.filter)}&lang=${this.parent.parent.ui.localization.currentLanguage.key}`;
  }

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: ServiceProvidersDTO;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: ServiceProviderFormDTO): Promise<void> {
    try {
      this.loading = LoadingState.LOADING;
      await this.connections.backend.httpPost(serviceProviders, data);
      this.loading = LoadingState.SUCCEEDED;
      return Promise.resolve();
    } catch (err) {
      this.loading = LoadingState.FAILED;
      return Promise.reject(err.response.data.message);
    }
  }

  @action
  public async getOne(id: string): Promise<ModelOf<ServiceProvidersDTO>> {
    const response = await this.connections.backend.httpGet(serviceProvider(parseInt(id, 10)));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action updateServiceProvider = async (id: number, data: Partial<ServiceProvidersDTO>) => {
    try {
      this.loading = LoadingState.LOADING;
      const response = await this.connections.backend.httpPut(serviceProvider(id), data);
      this.loading = LoadingState.SUCCEEDED;
      return Promise.resolve(response);
    } catch (err) {
      this.loading = LoadingState.FAILED;
      return Promise.reject(err.response.data.message);
    }
  };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(serviceProvider(id));
    return Promise.resolve(response);
  }
}
