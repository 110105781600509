import {
  BaseDTO,
} from '../types';

export class StaticText extends BaseDTO {
  id: string;

  key: string;

  value: string;

  language: string;
}
