import {
  PhoneNumberUtil,
} from 'google-libphonenumber';

export const ONLY_LETTERS_REGEX = /^[A-Za-z_\u0621-\u064A ]+$/;
export const ONLY_ARABIC_LETTERS_REGEX = /^[\u0621-\u064A ]+$/;
export const ONLY_NUMBERS_REGEX = /^[0-9+]+$/;

export const validatePhoneNumber = (phone: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const num = phoneUtil.parseAndKeepRawInput(phone);
  return phoneUtil.isValidNumber(num);
};

export const getCountryCode = (phone: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const num = phoneUtil.parseAndKeepRawInput(phone);
  return num.getCountryCode();
};
