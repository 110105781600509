import {
  BaseDTO,
} from 'shared';
import {
  Promocode,
} from 'src/screens/promoCodes/types';

export class PromocodesDTO extends BaseDTO {
  id?: number;

  code: string;

  discountType: string;

  amount: number;

  consultationType: string;

  country: string;

  endDate: Date;

  maxNumberOfUsage: number;

  startDate: Date;

  isActive: boolean;

  static toDTO(data: Promocode): PromocodesDTO {
    return {
      amount: Number.parseInt(data.amount, 10),
      code: data.code,
      consultationType: data.consultationType[0],
      country: data.country,
      discountType: data.discountType[0],
      endDate: data.endDate,
      maxNumberOfUsage: Number.parseInt(data.maxNumberOfUsage, 10),
      startDate: data.startDate,
      isActive: data.isActive,
    };
  }
}

export enum DiscountType {
  PERCENTAGE = 'Percentage',
  FIXED = 'Fixed',
}
