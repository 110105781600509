/* eslint-disable max-len */
import {
  action,
  observable,
  toJS,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  Settings,
} from 'settings';
import {
  Request,
} from './types';
import {
  BaseBackendStore,
} from '../types';
import {
  requests,
  getRequestById,
  deleteRequest,
  download,
} from './requests';
import {
  SearchObj,
} from '../DoctorsStore/types';
import {
  BackendStores,
} from '..';

export class RequestsStore extends BaseBackendStore {
  @observable private _entityList: Request[] = [];

  @observable searchResult: Request[] = [];

  @observable filter: SearchObj = {
    filters: [],
  };

  @action updateFilter = (item: SearchObj) => {
    this.filter = {
      ...this.filter,
      ...item,
    };
  };

  public download() {
    return `${Settings.config.REACT_APP_BASE_URL + download}?query=${JSON.stringify(this.filter)}&lang=${this.parent.parent.ui.localization.currentLanguage.key}`;
  }

  @action public getAll = async (options: any): Promise<Request[]> => {
    if (options.search) {
      this.filter.search = {
        by: 'any',
        value: options.search,
      };
    }
    if (options.orderBy) {
      this.filter.sort = {
        by: options.orderBy.field,
        type: this.filter.sort?.type === 'ASC' ? 'DESC' : 'ASC',
      };
    }
    const response = await this.connections.backend.httpGet(`${requests}?take=${options.pageSize || 10}&page=${options.page + 1 || 1}&query=${JSON.stringify(toJS(this.filter))}`);
    this.filter = {};
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: Request;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(testEntity: Request): Promise<number> {
    console.log('in create', testEntity);
    return 1;
  }

  @action
  public async getOne(id: string): Promise<ModelOf<Request>> {
    const response = await this.connections.backend.httpGet(getRequestById(id));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend.httpDelete(deleteRequest(id));
    return Promise.resolve(response);
  }

  @action
  public async update(): Promise<void> {
    return Promise.resolve();
  }
}
