import {
  baseScreen,
} from 'hoc';
import React from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  useHistory,
  useParams,
} from 'react-router';
import * as Yup from 'yup';
import GeneralFormFields,
{
  DateTimeFormFieldTypes,
} from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  Membership,
} from '../memberships/types';

const membershipsForm: React.FC = () => {
  const {
    backend: {
      memberships,
    },
  } = useStores();
  const history = useHistory();
  const {
    id: membershipId,
  } = useParams() as any;
  return (
    <GeneralFormView<any, Membership>
      title={i18n.t('MEMBERSHIP')}
      identifier="id"
      defaultLang=""
      otherLanguages={[]}
      formData={new FormFieldsBuilder<Membership>()
        .addField('discountValue', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('DISCOUNT_VALUE'),
          validationSchema: Yup.number().required(i18n.t('REQUIRED_FIELD')),
          fieldOptions: {},
        })
        .addField('validUntil', {
          type: GeneralFormFields.DATE_TIME_FIELD,
          title: i18n.t('VALID_UNTIL'),
          validationSchema: Yup.string().default(null).nullable() as any,
          fieldOptions: {
            type: DateTimeFormFieldTypes.DATE,
          },
        })
        .addField('englishName', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('ENGLISH_TITLE'),
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
          fieldOptions: {},
        })
        .addField('arabicName', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('ARABIC_TITLE'),
          fieldOptions: {},
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
        })
        .formFields}
      isFetchSuccessful
      update={async (id, data) => {
        try {
          if (membershipId) {
            await memberships.update(membershipId, {
              discountValue: Number(data.discountValue),
              validUntil: data.validUntil,
              translations: [
                {
                  languageCode: LanguagesKey.AR,
                  title: data.arabicName,
                }, {
                  languageCode: LanguagesKey.EN,
                  title: data.englishName,
                },
              ],
            });
          }
          history.push('/system-setup/system-setup/memberships');
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      create={async (data) => {
        try {
          await memberships.create({
            discountValue: Number(data.discountValue),
            validUntil: data.validUntil,
            translations: [
              {
                languageCode: LanguagesKey.AR,
                title: data.arabicName,
              }, {
                languageCode: LanguagesKey.EN,
                title: data.englishName,
              },
            ],
          });
          history.push('/system-setup/system-setup/memberships');
          return Promise.resolve(1);
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async () => {
        let res: any = {};
        if (membershipId) {
          res = await memberships.getOne(membershipId);
        }
        return Promise.resolve(new Membership(res));
      }}
    />
  );
};

export const MembershipsForm = baseScreen(membershipsForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
