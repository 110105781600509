import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  Assets,
} from 'assets';
import AddIcon from '@material-ui/icons/Add';
import {
  useHistory,
} from 'react-router';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import TableToggleButton from 'src/components/TableToggleButton';
import {
  Admin,
} from './types';

const admins: React.FC = () => {
  const {
    backend: {
      admins: adminsStore,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  return (
    <div>
      <GeneralTableView<Admin>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: true,
            paging: true,
            pageSize: 20,
          },
          title: i18n.t('ADMINS'),
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const admin: any = data;
                history.push(`/admins-form/edit/${admin.id}`);
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (event, data) => {
                const admin: any = data;
                history.push(`/admins-form/view/${admin.id}`);
              },
            },
            {
              isFreeAction: true,
              icon: ImportExportIcon,
              onClick: () => {
                window.open(adminsStore.download(), '_blanck');
              },
              tooltip: i18n.t('EXPORT_DATA'),
            },
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                history.push('/admins-form/new/new');
              },
              tooltip: i18n.t('ADD_NEW_ADMIN'),
            },
          ],
        }}
        options={{
          search: false,
          filtering: false,
        }}
        get={async (query) => {
          await adminsStore.entityList.updateOptions(query);
          return {
            data: toJS(adminsStore.entityList.data).map((item: any) => new Admin(item)),
            totalCount: adminsStore.entityList.meta.itemCount,
            page: adminsStore.entityList.meta.page - 1,
          };
        }}
        tableColumns={[
          {
            field: 'id',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'name',
            title: i18n.t('NAME'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'phone',
            title: i18n.t('MOBILE_NUMBER'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'email',
            title: i18n.t('EMAIL'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'role',
            title: i18n.t('ROLE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'isActive',
            title: i18n.t('IS_ACTIVE'),
            type: TableColumnTypes.BOOLEAN,
            columnProps: {
              editable: 'always',
              render: (data) => (
                <TableToggleButton
                  isActive={data.isActive}
                  activationConfirmationText={i18n.t('ACTIVATE_ADMIN_CONFIRMATION')}
                  deactivationConfirmationText={i18n.t('DEACTIVATE_ADMIN_CONFIRMATION')}
                  onChange={async (response) => {
                    if (response) {
                      const admin: any = data;
                      await adminsStore.updateAdmin(admin.id, {
                        isActive: !data.isActive,
                      });
                      if (ref && ref.current) {
                        ref.current.onQueryChange();
                      }
                    }
                  }}
                />
              ),
            },
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const Admins = baseScreen(admins, {
  allowedRoles: ['ADMIN', 'NONE'],
});
