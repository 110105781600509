/* eslint-disable max-classes-per-file */
import React,
{
  useState,
} from 'react';
import {
  useStores,
} from 'hooks';
import {
  baseScreen,
} from 'hoc';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import * as Yup from 'yup';
import i18n from 'i18n-js';
import {
  Request,
  RequestTable,
} from 'src/stores/backend/RequestsStore/types';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import GeneralTableView from 'src/components/GeneralTableView';
import {
  toJS,
} from 'mobx';
import {
  PatientModelView,
} from './types';
import {
  PatientsTable,
} from '../patients/types';

const patientDetails: React.FC = () => {
  const {
    backend: {
      patientsStore,
      requests: requestsStore,
    },
  } = useStores();
  const [
    patientId,
    setPatientId,
  ] = useState('');
  return (
    <div>
      <GeneralFormView<any, PatientModelView>
        title=""
        identifier="id"
        defaultLang=""
        otherLanguages={[]}
        formData={new FormFieldsBuilder<PatientModelView>()
          .addField('avatar', {
            title: i18n.t('IMAGE'),
            type: GeneralFormFields.FILE_PICKER,
            validationSchema: Yup.array().max(1) as any,
            fieldOptions: {
              size: 200,
              accept: 'image/*',
              multipleImages: false,
            },
          })
          .addField('name', {
            type: GeneralFormFields.INPUT_FIELD,
            title: i18n.t('NAME'),
            fieldOptions: {},
          })
          .addField('age', {
            type: GeneralFormFields.INPUT_FIELD,
            validationSchema: Yup.string().notRequired().nullable() as any,
            title: i18n.t('AGE'),
            fieldOptions: {},
          })
          .addField('phone', {
            type: GeneralFormFields.INPUT_FIELD,
            validationSchema: Yup.string().notRequired() as any,
            title: i18n.t('MOBILE_NUMBER'),
            fieldOptions: {},
          })
          .addField('email', {
            type: GeneralFormFields.INPUT_FIELD,
            title: i18n.t('EMAIL'),
            validationSchema: Yup.string().notRequired() as any,
            fieldOptions: {},
          })
          .addField('gender', {
            type: GeneralFormFields.INPUT_FIELD,
            title: i18n.t('GENDER'),
            fieldOptions: {},
          })
          .formFields}
        isFetchSuccessful
        update={async () => Promise.resolve()}
        create={async () => Promise.resolve(1)}
        viewMode
        get={async (id) => {
          setPatientId(id);
          const patient = await patientsStore.getOne(id);
          patientsStore.updateRecentlyViewed(new PatientsTable(patient));
          return Promise.resolve(new PatientModelView(patient));
        }}
      />
      <GeneralTableView<RequestTable>
        tableProps={{
          title: i18n.t('REQUESTS_LIST'),
          actions: [],
        }}
        options={{
          search: false,
          pageSize: 10,
          filtering: false,
        }}
        get={async () => {
          await requestsStore.updateFilter({
            filters: [
              {
                by: 'patient',
                type: 'FIXED',
                value: patientId,
              },
            ],
          });
          const {
            data,
          } = toJS(await requestsStore.getAll({}) as any);
          const requests = data.map((item: any) => Request.toTableData(item));
          return Promise.resolve({
            data: requests,
            totalCount: 10,
            page: 0,
          });
        }}
        tableColumns={[
          {
            field: 'id',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'consultation_type',
            title: i18n.t('TYPE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'date',
            title: i18n.t('DATE'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'doctor',
            title: i18n.t('DOCTOR'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'doctorNotes',
            title: i18n.t('NOTES'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};
export const PatientDetails = baseScreen(patientDetails, {
  allowedRoles: ['ADMIN', 'NONE'],
});
