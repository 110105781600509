/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  Settings,
} from 'settings';
import {
  omit,
} from 'lodash';
import {
  BaseBackendStore,
} from '../types';
import {
  accountant,
  accountants,
  download,
} from './requests';
import {
  AccountantDTO,
} from './types';
import {
  BackendStores,
} from '..';
import {
  SearchObj,
} from '../DoctorsStore/types';

export class AccountantsStore extends BaseBackendStore {
  @observable private _entityList: AccountantDTO[] = [];

  filter: SearchObj = {};

  @action public getAll = async (options: any): Promise<AccountantDTO[]> => {
    const query: SearchObj = { };
    if (options.search) {
      query.search = {
        value: options.search,
      };
    }
    if (options.orderBy) {
      query.sort = {
        by: options.orderBy.field,
        type: options.orderDirection.toUpperCase(),
      };
    }
    this.filter = query;
    const response = await this.connections.backend
      .httpGet(`${accountants}?take=${options.pageSize}&page=${options.page + 1}&query=${JSON.stringify(query)}`);
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: AccountantDTO;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: AccountantDTO): Promise<void> {
    try {
      await this.connections.backend.httpPost(accountants, omit(data, 'confirmPassword'));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  }

  @action
  public async getOne(id: string): Promise<ModelOf<AccountantDTO>> {
    const response = await this.connections.backend.httpGet(accountant(parseInt(id, 10)));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action updateAccount = async (id: number, data: Partial<AccountantDTO>) => {
    try {
      const response = await this.connections.backend.httpPut(accountant(id), data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(accountant(id));
    return Promise.resolve(response);
  }

  public download() {
    return `${Settings.config.REACT_APP_BASE_URL + download}?query=${JSON.stringify(this.filter)}&lang=${this.parent.parent.ui.localization.currentLanguage.key}`;
  }
}
