import {
  Chip,
  // CircularProgress,
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  getIn,
  useFormikContext,
} from 'formik';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  SelectFieldItems,
} from '../../types';
import FieldError from '../../Utils/FieldError';
import {
  FieldView,
} from '../../Utils/FieldView';
import {
  FieldPropsCommon,
  SelectFieldValue,
} from '../types';
import {
  onChange,
} from '../utils';
import CustomSelectInput from './CustomSelectInput';
import CustomSelectInputLabel from './CustomSelectInputLabel';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    margin: theme.spacing(1),
    flex: 1,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface SelectRenderProps {
  selected: any[];
  classes: any;
  isMultiple: boolean;
  fieldItems: SelectFieldItems<any>[];
  title: string;
  value: any[];
}

const SelectRender: React.FC<SelectRenderProps> = (props) => {
  const {
    selected,
    classes,
    isMultiple,
    fieldItems,
    value,
    title,
  } = props;
  if (isMultiple) {
    if (selected.length) {
      return (
        <div className={classes.chips}>
          {selected.map((innerValue, index) => (
            <Chip
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}`}
              label={fieldItems.find((item) => item.value === innerValue)?.title}
              className={classes.chip}
            />
          ))}
        </div>
      );
    }
    return (
      <Typography
        variant="subtitle1"
      >
        {title}
      </Typography>
    );
  }
  if (selected.length) {
    return (
      <Typography
        variant="subtitle1"
      >
        {fieldItems.find((item) => item.value === value[0])?.title}
      </Typography>
    );
  }
  return (
    <Typography
      variant="subtitle1"
    >
      {title}
    </Typography>
  );
};

type Props<T, K extends keyof T> = FieldPropsCommon & SelectFieldValue<T, K>;

const getSelectValue = (fieldItems: any[], value: any[], isMultiSelect: boolean) => {
  // console.log('field items', fieldItems);
  // console.log('value', value);
  if (!fieldItems.length || (!value.length && !isMultiSelect)) {
    return '';
  }
  return value;
};

const SelectFormField = <FormModel, FormModelKey extends keyof FormModel>(props: Props<FormModel, FormModelKey>) => {
  const form = useFormikContext<FormModel>();
  const {
    values,
    handleChange,
    isSubmitting,
    setFieldTouched,
    errors,
    touched,
  } = form;
  const {
    fieldOptions: {
      isMultiple,
      items,
      onValueChanged,
    },
    disabled,
    title,
    location,
    viewMode,
  } = props;
  useEffect(() => {
    if (isSubmitting) {
      setFieldTouched(location);
    }
  }, [isSubmitting, location, setFieldTouched]);

  const value: FormModel[] = getIn(values, location);
  const onValueChange = onChange(handleChange, location);
  const error = getIn(errors, location);
  const isTouched = getIn(touched, location);

  const classes = useStyles();
  const theme = useTheme();
  const getStyles = (formValue: FormModel, selectedValues: FormModel[], formTheme: Theme) => ({
    fontWeight:
        !selectedValues.includes(formValue)
          ? formTheme.typography.fontWeightRegular
          : formTheme.typography.fontWeightMedium,
  });

  const [fieldItems, setFieldItems] = useState<SelectFieldItems<any>[]>([]);

  useEffect(() => {
    if (Array.isArray(items)) {
      setFieldItems(items);
    } else {
      const asyncSetFieldItems = async () => {
        setFieldItems(await items);
      };
      asyncSetFieldItems();
    }
  }, [
    items,
  ]);
  if (viewMode) {
    return (
      <FieldView
        title={title}
        value={() => (
          <>
            {!isMultiple && (
            <Typography variant="body2">{value[0]}</Typography>
            )}
            {isMultiple && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {value.map((val) => (
                <Typography variant="body2">{`\u2022 ${val}`}</Typography>
              ))}
            </div>
            )}
          </>
        )}
      />
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'stretch',
          alignItems: 'center',
        }}
      >
        <FormControl className={classes.formControl}>
          <Select
            multiple={isMultiple}
            onBlur={() => setFieldTouched(location)}
            disabled={!fieldItems.length || disabled}
            value={getSelectValue(fieldItems, value, isMultiple)}
            placeholder={title}
            onChange={(event) => {
              let {
                value: eventValue,
              } = event.target as any;
              if (onValueChanged) {
                onValueChanged(event.target.value, form);
              }
              if (!isMultiple) {
                eventValue = [eventValue];
              }
              onValueChange(eventValue);
            }}
            input={<CustomSelectInput />}
            renderValue={((selected: FormModel[]) => (
              <SelectRender
                classes={classes}
                fieldItems={fieldItems}
                isMultiple={isMultiple}
                selected={selected}
                value={value}
                title={title}
              />
            )) as any}
            MenuProps={MenuProps}
          >
            {fieldItems.map((item) => (
              <MenuItem key={item.title} value={item.value} style={getStyles(item.value, value, theme)}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
          <CustomSelectInputLabel
            style={{
              paddingLeft: 14,
            }}
          >
            {title}
          </CustomSelectInputLabel>
        </FormControl>
        {/* {
          !fieldItems.length && (
            <CircularProgress style={{
              width: 24,
              height: 24,
            }}
            />
          )
        } */}
      </div>
      <FieldError
        errors={error}
        touched={isTouched}
      />
    </>
  );
};

export default SelectFormField;
