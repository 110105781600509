import moment from 'moment';
import {
  NotificationsDTO,
} from 'src/stores/backend/NotificationsStore/types';
import {
  BaseFormViewModel,
} from '../types';

export class Notification extends BaseFormViewModel<NotificationsDTO> {
  id?: number;

  arabicText: string;

  englishText: string;

  audience: string;

  serviceProviderId?: number;

  createdAt?: string;

  constructor(data: NotificationsDTO) {
    super();
    this.id = data.id;
    this.arabicText = data.arabicText;
    this.serviceProviderId = data.serviceProviderId;
    this.englishText = data.englishText;
    this.audience = data.audience.toLowerCase().replace('_', ' ');
    this.createdAt = moment(data.createdAt).format('DD-MM-YYYY HH:mm A');
  }

  toDTO(): NotificationsDTO {
    return { } as NotificationsDTO;
  }
}

export enum FirebaseTopics {
  ALL = 'ALL',
  PATIENTS = 'PATIENT',
  DOCTORS = 'DOCTOR',
  SERVICE_PROVIDERS = 'SERVICE_PROVIDER',
  LANG_EN = 'LANG_EN',
  LANG_AR = 'LANG_AR',
}
