import {
  FieldFile,
  FieldFileCommonTypes,
  FieldFileTypes,
  isRemoteFile,
} from '../types';

export const getFileType = (file: File): FieldFileTypes => {
  if (file.type.includes('image')) {
    return FieldFileCommonTypes.IMAGE;
  }
  if (file.type.includes('video')) {
    return FieldFileCommonTypes.VIDEO;
  }
  return 'other';
};

export const getFilePreview = (file: File) => new Promise<string>((res, rej) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onabort = rej;
  reader.onerror = rej;
  reader.onload = (event) => {
    if (event.target && event.target.result) {
      res(event.target.result as string);
    }
  };
});

export const getFileName = (file: FieldFile): string => {
  if (isRemoteFile(file)) {
    return file.source.substring(file.source.lastIndexOf('/') + 1);
  }
  return file.file?.name || '';
};
