import {
  FieldFile,
  LocalFile,
} from 'src/components/GeneralFormView/FormField/types';
import {
  BaseFormViewModel,
} from 'src/screens/types';
import {
  HospitalSettlementDTO,
} from 'src/stores/backend/BillsStore/types';
import {
  uploadFile,
} from 'src/utils/aws-file-manager';

export class HospitalSettlementFormModel extends BaseFormViewModel<HospitalSettlementDTO> {
  transactionType: string;

  transactionId: string;

  date: Date;

  bankName: string;

  receipt: FieldFile[];

  billId: number;

  constructor(data: HospitalSettlementFormModel) {
    super();
    this.bankName = data.bankName;
    this.date = data.date;
    this.receipt = data.receipt;
    this.transactionId = data.transactionId;
    this.billId = data.billId;
    this.transactionType = data.transactionType;
  }

  async toDTO(): Promise<HospitalSettlementDTO> {
    const receipt = await uploadFile({
      file: (this.receipt[0] as LocalFile).file, filePath: `avatar-${Date.now()}`,
    });

    return {
      bankName: this.bankName,
      date: this.date,
      billId: this.billId,
      receipt,
      transactionId: this.transactionId,
    };
  }
}

export enum TransactionType {
  CASH = 'cash',
  BANK_ACCOUNT = 'bank account',
}
