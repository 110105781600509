import {
  baseScreen,
} from 'hoc';
import {
  useObserver,
} from 'mobx-react';
import React from 'react';

const forgetPassword: React.FC = () => useObserver(() => (
  <div>
    ForgetPassword
  </div>
));

export const ForgetPassword = baseScreen(forgetPassword, {
  allowedRoles: ['ADMIN', 'NONE'],
});
