import {
  MembershipsDTO,
} from 'src/stores/backend/MembershipStore/types';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  BaseFormViewModel,
} from '../types';

export class Membership extends BaseFormViewModel<MembershipsDTO> {
  id?: number;

  validUntil?: Date;

  discountValue: number;

  englishName: string;

  arabicName: string;

  constructor(data:MembershipsDTO) {
    super();

    this.id = data.id;
    this.englishName = data.translations?.filter((item) => item.languageCode === LanguagesKey.EN)[0].title;
    this.arabicName = data.translations?.filter((item) => item.languageCode === LanguagesKey.AR)[0].title;
    this.discountValue = data.discountValue;
    this.validUntil = data.validUntil;
  }

  toDTO(): MembershipsDTO | Promise<MembershipsDTO> {
    return {} as MembershipsDTO;
  }
}
