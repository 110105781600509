import {
  observable,
  action,
} from 'mobx';
import {
  BackendStores,
} from '..';
import {
  BaseBackendStore,
} from '../types';
import {
  governorates,
} from './requests';
import {
  Governorate,
} from './types';

export class GovernorateStore extends BaseBackendStore {
  @observable governerates: Governorate[] = [];

  @observable isLoadingGovernorates: boolean = false;

  constructor(
    public parent: BackendStores,
  ) {
    super();
  }

  @action getGovernorates = async (): Promise<Governorate[]> => {
    try {
      this.isLoadingGovernorates = true;
      this.governerates = await this.connections.backend.httpGet(governorates);
      return this.governerates;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.isLoadingGovernorates = false;
    }
  };
}
