/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  BaseBackendStore,
} from '../types';
import {
  categories,
  category,
} from './requests';
import {
  CategoriesDTO,
} from './types';
import {
  BackendStores,
} from '..';

export class CategoriesStore extends BaseBackendStore {
  @observable private _entityList: CategoriesDTO[] = [];

  @action public getAll = async (): Promise<CategoriesDTO[]> => {
    const response = await this.connections.backend
      .httpGet(`${categories}`);
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: CategoriesDTO;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: CategoriesDTO): Promise<void> {
    try {
      await this.connections.backend.httpPost(categories, data);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  }

  @action
  public async getOne(id: string): Promise<ModelOf<CategoriesDTO>> {
    const response = await this.connections.backend.httpGet(category(parseInt(id, 10)));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action updateCategory = async (id: number, data: Partial<CategoriesDTO>) => {
    try {
      const response = await this.connections.backend.httpPut(category(id), data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(category(id));
    return Promise.resolve(response);
  }
}
