import {
  baseScreen,
} from 'hoc';
import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import * as Yup from 'yup';
import {
  useHistory,
  useParams,
} from 'react-router';
import GeneralFormFields,
{
  SelectFieldItems,
} from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import ComponentWithLoading from 'src/components/ComponentWithLoading';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  CountryCodes,
} from 'utils';
import {
  FormikProps,
} from 'formik';
import {
  Button,
} from '@material-ui/core';
import {
  ServiceProviderFormViewModel,
} from './types';
import {
  ONLY_LETTERS_REGEX,
  ONLY_NUMBERS_REGEX,
} from '../utils';

const serviceProverForm: React.FC = () => {
  const {
    backend: {
      serviceProviderTypes: serviceProviderTypesStore,
      governorates: governoratesStore,
      cities: citiesStore,
      serviceProviders: serviceProviderStore,
    },
  } = useStores();
  const history = useHistory();
  const [
    serviceProviderTypes,
    setServiceProviderTypes,
  ] = useState<SelectFieldItems<number>[]>([]);
  const [
    governorates,
    setGovernorates,
  ] = useState<SelectFieldItems<string>[]>([]);
  const [
    cities,
    setCities,
  ] = useState<SelectFieldItems<string>[]>([]);
  const [
    isActive,
    setActive,
  ] = useState(true);
  const {
    id: serviceProviderId,
    mode,
  } = useParams() as any;
  const loadCities = async (val: number) => {
    const citiesData = (await citiesStore.getCities(val)).map((item) => ({
      title: item.name,
      value: item.id,
    }));
    setCities(citiesData);
  };
  const load = async () => {
    const types = (await serviceProviderTypesStore.getAll({})).map((item) => ({
      title: item.translations?.filter((el) => el.languageCode === LanguagesKey.EN)[0]?.title,
      value: item.id as number,
    }));
    setServiceProviderTypes(types);
    const governoratesData = (await governoratesStore.getGovernorates()).map((item) => ({
      title: item.name,
      value: item.id,
    }));
    setGovernorates(governoratesData);
  };
  useEffect(() => {
    load();
  }, []);
  const ref = useRef<FormikProps<ServiceProviderFormViewModel> | null>(null);
  const form = new FormFieldsBuilder<ServiceProviderFormViewModel>()
    .addField('logo', {
      type: GeneralFormFields.FILE_PICKER,
      title: i18n.t('LOGO'),
      validationSchema: Yup.array().required().length(1, i18n.t('FILE_PICKER_FILED_LENGTH')) as any,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addField('serviceProviderTypeId', {
      type: GeneralFormFields.SELECT_FIELD,
      title: i18n.t('TYPE'),
      validationSchema: Yup.array().length(1, i18n.t('FILE_PICKER_FILED_LENGTH'))
        .required(i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {
        isMultiple: false,
        items: serviceProviderTypes,
      },
    })
    .addField('englishName', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('ENGLISH_NAME'),
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD'))
        .matches(ONLY_LETTERS_REGEX, i18n.t('ACCEPTS_LETTERS_ONLY')) as any,
      fieldOptions: {},
    })
    .addField('arabicName', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('ARABIC_NAME'),
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD'))
        .matches(ONLY_LETTERS_REGEX, i18n.t('ACCEPTS_LETTERS_ONLY')) as any,
      fieldOptions: {},
    })
    .addField('countryCode', {
      title: i18n.t('COUNTRY_CODE'),
      type: GeneralFormFields.SELECT_FIELD,
      validationSchema: Yup
        .array()
        .of(Yup.string().required())
        .min(1, i18n.t('REQUIRED_FIELD'))
        .required(),
      fieldOptions: {
        isMultiple: false,
        items: CountryCodes.map((country) => ({
          title: `${country.name} (${country.dial_code})`,
          value: country.dial_code,
        })),
      },
    })
    .addField('mobileNumber', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('MOBILE_NUMBER'),
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD'))
        .max(10, i18n.t('INVALID_FIELD'))
        .matches(ONLY_NUMBERS_REGEX, i18n.t('ACCEPTS_NUMBERS_ONLY')) as any,
      fieldOptions: {},
    })
    .addField('landlineNumber', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('LANDLINE_NUMBER'),
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')).max(8, i18n.t('INVALID_FIELD'))
        .matches(ONLY_NUMBERS_REGEX, i18n.t('ACCEPTS_NUMBERS_ONLY')) as any,
      fieldOptions: {},
    })
    .addField('email', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('EMAIL'),
      validationSchema: Yup.string().email(i18n.t('INVALID_VALUE')).required(i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {},
    })
    .addField('website', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('WEBSITE'),
      validationSchema: Yup.string().url(i18n.t('INVALID_WEBSITE')).notRequired().default(null)
        .nullable() as any,
      fieldOptions: {},
    })
    .addField('instapayUrl', {
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.string().notRequired().nullable() as any,
      title: i18n.t('INSTAPAY'),
      fieldOptions: {},
    })
    .addField('location', {
      type: GeneralFormFields.LOCATION_FIELD,
      title: i18n.t('LOCATION'),
      validationSchema: Yup.object().notRequired() as any,
      fieldOptions: {},
    })
    .addField('governorateId', {
      type: GeneralFormFields.SELECT_FIELD,
      title: i18n.t('GOVERNORATE'),
      validationSchema: Yup.array().notRequired() as any,
      fieldOptions: {
        isMultiple: false,
        items: governorates,
        onValueChanged: async (val) => {
          if (ref?.current) {
            ref.current.setFieldValue('cityId', []);
          }
          loadCities(val);
        },
      },
    })
    .addField('cityId', {
      type: GeneralFormFields.SELECT_FIELD,
      validationSchema: Yup.array().notRequired() as any,
      title: i18n.t('CITY'),
      fieldOptions: {
        isMultiple: false,
        items: cities,
      },
    })
    .addField('area', {
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.string().notRequired() as any,
      title: i18n.t('AREA'),
      fieldOptions: {},
    })
    .addField('commercialRecord', {
      type: GeneralFormFields.FILE_PICKER,
      title: i18n.t('CEMMERCIAL_RECORD'),
      validationSchema: Yup.array().required().length(1, i18n.t('FILE_PICKER_FILED_LENGTH')) as any,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addField('taxId', {
      type: GeneralFormFields.FILE_PICKER,
      title: i18n.t('TAX_ID'),
      validationSchema: Yup.array().required().length(1, i18n.t('FILE_PICKER_FILED_LENGTH')) as any,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addField('certificates', {
      type: GeneralFormFields.FILE_PICKER,
      title: i18n.t('CERTIFICATES'),
      validationSchema: Yup.array().required(i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: true,
      },
    })
    .addField('signupCode', {
      type: GeneralFormFields.INPUT_FIELD,
      title: 'Signup User Code',
      validationSchema: Yup.string().notRequired().default(null).nullable() as any,
      fieldOptions: {},
    });
  if (mode !== 'view') {
    form.addField('password', {
      title: i18n.t('PASSWORD'),
      validationSchema: Yup.string().when('id', {
        is: (id: any) => !!id,
        then: Yup.string().min(8, i18n.t('INVALID_PASSWORD')).notRequired(),
        otherwise: Yup.string().min(8, i18n.t('INVALID_PASSWORD')).required(i18n.t('REQUIRED_FIELD')),
      }) as any,
      type: GeneralFormFields.INPUT_FIELD,
      fieldOptions: {
        type: 'password',
      },
    })
      .addField('confirmPassword', {
        title: i18n.t('CONFIRM_PASSWORD'),
        validationSchema: Yup.string().when('id', {
          is: (id: any) => !!id,
          then: Yup.string().oneOf([Yup.ref('password')], i18n.t('PASSWORD_MUST_MATCH')).notRequired(),
          otherwise: Yup.string().oneOf([Yup.ref('password')], i18n.t('PASSWORD_MUST_MATCH'))
            .required(i18n.t('REQUIRED_FIELD')),
        }) as any,
        type: GeneralFormFields.INPUT_FIELD,
        fieldOptions: {
          type: 'password',
        },
      });
  }

  return (
    <ComponentWithLoading
      isLoading={!serviceProviderTypes.length}
    >
      {!isActive && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 12,
          }}
        >
          <Button
            style={{
              background: '#DDD',
            }}
            onClick={async () => {
              const response = window.confirm(i18n.t('ACTIVATE_SERVICE_PROVIDERS_CONFIRMATION'));
              if (response) {
                await serviceProviderStore.updateServiceProvider(serviceProviderId, {
                  isActive: true,
                });
                history.goBack();
              }
            }}
          >
            {i18n.t('ACTIVATE')}
          </Button>
        </div>
      )}
      <GeneralFormView<any, ServiceProviderFormViewModel>
        title={i18n.t('SERVICE_PROVIDERS')}
        identifier="id"
        defaultLang=""
        viewMode={mode === 'view'}
        otherLanguages={[]}
        getRef={(innerRef) => {
          ref.current = innerRef;
        }}
        formData={
          form.formFields
        }
        isFetchSuccessful
        update={async (id, data) => {
          try {
            const value = await (new ServiceProviderFormViewModel(data)).toDTO();
            serviceProviderStore.updateServiceProvider(Number.parseInt(id, 10), value);
            history.push('/users/:id/service-providers/service-providers');
            return Promise.resolve();
          } catch (error) {
            return Promise.reject(error);
          }
        }}
        create={async (data) => {
          try {
            const value = await (new ServiceProviderFormViewModel(data)).toDTO();
            await serviceProviderStore.create(value);
            history.push('/service-providers/form-confirmation');
            return Promise.resolve(1);
          } catch (error) {
            return Promise.reject(error);
          }
        }}
        get={async () => {
          const res = await serviceProviderStore.getOne(serviceProviderId);
          const value = ServiceProviderFormViewModel.fromDTO(res);
          console.log(res);
          console.log(value);
          setActive(value.isActive);
          await loadCities(res.governorate?.id);
          return value;
        }}
      />
    </ComponentWithLoading>
  );
};

export const ServiceProverForm = baseScreen(serviceProverForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
