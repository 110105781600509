import {
  Grid,
  makeStyles,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import {
  baseScreen,
} from 'hoc';
import React from 'react';
import {
  TabsModuleProps,
} from './types';
import {
  Module,
} from '../module';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  tabs: {
    flexGrow: 1,
    width: '100%',
  },
}));

interface TabPanelProps {
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const tabsModule: React.FC<TabsModuleProps> = (props) => {
  const {
    route,
  } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Grid className={classes.root} container xs={12}>
      <div className={classes.tabs}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {route.children.map((child, index) => (
              <Tab label={child.config.name} {...a11yProps(index)} />
            ))}
          </Tabs>
        </AppBar>
        {route.children.map((child, index) => (
          <TabPanel
            index={index}
            value={value}
          >
            <Module route={child} />
          </TabPanel>
        ))}
      </div>
    </Grid>
  );
};

export const TabsModule = baseScreen(tabsModule, {
  allowedRoles: ['NONE', 'ADMIN'],
});
