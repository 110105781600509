import React,
{
  useState,
} from 'react';
import {
  LocalizationContext,
  LocalizationContextModel,
} from 'contexts';
import {
  Language,
} from 'shared';
import {
  memoize,
} from 'lodash';
import i18n from 'i18n-js';
import {
  useConnections,
  useStores,
} from 'hooks';
import {
  observer,
} from 'mobx-react';
import {
  TRANSLATIONS,
} from 'src/translations';

const localizationProvider: React.FC = (props) => {
  const stores = useStores();
  const connections = useConnections();
  const [translate, setTranslate] = useState<Pick<LocalizationContextModel, 'translate'>>({
    translate: () => '',
  });
  const setLanguage = async (language: Language) => {
    const shouldReload = language.key !== stores.ui.localization.currentLanguage.key;
    const newTranslate = memoize(
      (key, config?) => i18n.t(key, config),
      (key, config) => (config ? key + JSON.stringify(config) : key),
    );
    newTranslate.cache.clear!();
    i18n.translations = TRANSLATIONS;
    i18n.locale = language.key;
    setTranslate({
      translate: newTranslate,
    });
    stores.ui.localization.setCurrentLanguage(language);
    if (shouldReload) {
      document.location.reload();
    }
  };
  const updateTranslations = async (language: Language) => {
    connections.backend.updateDefaultHeaders({
      'Accept-Language': language.key,
    });
    // await stores.backend.staticTexts.staticTexts.updateOptions(language.key);
    // const translationMap = stores.backend.staticTexts.staticTexts.data.reduce((acc, val) => ({
    //   ...acc,
    //   [val.key]: val.value,
    // }), {});
    await setLanguage(language);
  };
  const {
    children,
  } = props;
  return (
    <LocalizationContext.Provider
      value={{
        setLanguage,
        translate: translate.translate,
        currentLanguage: stores.ui.localization.currentLanguage,
        updateTranslations,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

export const LocalizationProvider = observer(localizationProvider);
