import {
  BaseDTO as DTO,
} from 'shared';

export abstract class BaseViewModel<T extends DTO> {
  abstract toDTO(): T | Promise<T>;
}

export abstract class BaseTableViewModel<T extends DTO> extends BaseViewModel<T> {
}

export abstract class BaseFormViewModel<T extends DTO> extends BaseViewModel<T> {
}
