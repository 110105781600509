import {
  PromocodesDTO,
} from 'src/stores/backend/PromoCodesStore/types';
import {
  BaseFormViewModel,
} from '../types';

export class Promocode extends BaseFormViewModel<PromocodesDTO> {
  id?: number;

  code: string;

  discountType: string[];

  amount: string;

  consultationType: string[];

  country: string;

  endDate: Date;

  maxNumberOfUsage: string;

  startDate: Date;

  countryType: string[];

  isActive: boolean;

  constructor(data: PromocodesDTO) {
    super();
    this.id = data.id;
    this.amount = data.amount?.toString();
    this.code = data.code;
    this.discountType = [data.discountType];
    this.country = data.country;
    this.consultationType = [data.consultationType];
    this.endDate = data.endDate;
    this.maxNumberOfUsage = data.maxNumberOfUsage?.toString();
    this.startDate = data.startDate;
    this.countryType = this.country === 'All' ? ['All'] : ['specific country'];
    this.isActive = data.isActive;
  }

  toDTO(): PromocodesDTO {
    return { } as PromocodesDTO;
  }
}
