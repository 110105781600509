import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  Assets,
} from 'assets';
import AddIcon from '@material-ui/icons/Add';
import {
  useHistory,
} from 'react-router';
import {
  ServiceProviderType,
} from './types';

const serviceProverTypes: React.FC = () => {
  const {
    backend: {
      serviceProviderTypes,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  return (
    <div>
      <GeneralTableView<ServiceProviderType>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: false,
            paging: false,
          },
          title: i18n.t('SERVICE_PROVIDER_TYPES'),
          actions: [
            // {
            //   icon: () => (
            //     <img
            //       src={Assets.images.common.trash}
            //       alt={i18n.t('DELETE')}
            //     />
            //   ),
            //   onClick: async (event, data) => {
            //     if (!Array.isArray(data)) {
            //       const response = window.confirm(i18n.t('DELETE_SERVICE_PROVIDER_TYPE_CONFIRMATION'));
            //       if (response) {
            //         await serviceProviderTypes.delete(data.id as number);
            //         if (ref && ref.current) {
            //           ref.current.onQueryChange();
            //         }
            //       }
            //     }
            //   },
            // },
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const type: any = data;
                history.push(`/service-provider-type-form/${type.id}`);
              },
            },
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                history.push('/service-provider-type-form/:id');
              },
              tooltip: i18n.t('ADD_NEW_TYPE'),
            },
          ],
        }}
        options={{
          search: false,
          pageSize: 10,
          filtering: false,
        }}
        get={async (query) => {
          const res = await serviceProviderTypes.getAll(query);
          return {
            data: toJS(res).map((item) => new ServiceProviderType(item)),
            totalCount: 10,
            page: 1,
          };
        }}
        tableColumns={[
          {
            field: 'id',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'englishName',
            title: i18n.t('ENGLISH_TITLE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'arabicName',
            title: i18n.t('ARABIC_TITLE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const ServiceProverTypes = baseScreen(serviceProverTypes, {
  allowedRoles: ['ADMIN', 'NONE'],
});
