/* eslint-disable */
import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  Assets,
} from 'assets';
import AddIcon from '@material-ui/icons/Add';
import {
  useHistory,
} from 'react-router';
import {
  Nationality,
} from './types';
import TableToggleButton from 'src/components/TableToggleButton';

const NationalitiesComponent: React.FC = () => {
  const {
    backend: {
      nationalities,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  return (
    <div>
      <GeneralTableView<Nationality>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: false,
            paging: false,
            sorting: false,
          },
          title: i18n.t('MEMBERSHIPS'),
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.trash}
                  alt={i18n.t('DELETE')}
                />
              ),
              onClick: async (event, data) => {
                if (!Array.isArray(data)) {
                  const response = window.confirm(i18n.t('DELETE_SPECIALITY_CONFIRMATION'));
                  if (response) {
                    await nationalities.delete(data.id as number);
                    if (ref && ref.current) {
                      ref.current.onQueryChange();
                    }
                  }
                }
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const type: any = data;
                console.log(history, event, type);
                history.push(`/nationality-form/${type.id}`);
              },
            },
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                history.push('/nationality-form/:id');
              },
              tooltip: i18n.t('ADD_NEW_MEMBERSHIP'),
            },
        ],
      }}
      options={{
          search: false,
          pageSize: 10,
          filtering: false,
      }}
      get={async (query) => {
          const res = await nationalities.getAll(query);
          console.log(res);
          
          return {
          data: toJS(res).map((item) => new Nationality(item)),
          totalCount: 10,
          page: 1,
          };
      }}
      tableColumns={[
          {
          field: 'id',
          title: i18n.t('ID'),
          type: TableColumnTypes.NUMERIC,
          },
          {
          field: 'englishName',
          title: i18n.t('ENGLISH_TITLE'),
          type: TableColumnTypes.STRING,
          },
          {
          field: 'arabicName',
          title: i18n.t('ARABIC_TITLE'),
          type: TableColumnTypes.STRING,
          },
          {
            field: 'isWithHightPricing',
            title: i18n.t('HIGH_PRICE'),
            type: TableColumnTypes.BOOLEAN,
            columnProps: {
              editable: 'always',
              render: (data) => (
                <TableToggleButton
                  isActive={data.isWithHightPricing}
                  activationConfirmationText={i18n.t('ACTIVATE_DOCTOR_CONFIRMATION')}
                  deactivationConfirmationText={i18n.t('DEACTIVATE_DOCTOR_CONFIRMATION')}
                  onChange={async (response) => {
                    if (response) {
                      const dr: any = data;
                      await nationalities.update(dr.id, {
                        isWithHighPricing : !data.isWithHightPricing
                      });
                      if (ref && ref.current) {
                        ref.current.onQueryChange();
                      }
                    }
                  }}
                />
              ),
            },
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const Nationalities = baseScreen(NationalitiesComponent, {
  allowedRoles: ['ADMIN','NONE'],
});
