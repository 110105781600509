/* eslint-disable */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  BaseBackendStore,
} from '../types';
import {
  memberships,
  membership,
  doctorMembership
} from './requests'
import {
  MembershipsDTO
} from './types'
import {
  BackendStores,
} from '..';

export class MembershipsStore extends BaseBackendStore{
  @observable private _entityList:MembershipsDTO[]=[];

  @action public getAll=async (options:any):Promise<MembershipsDTO[]> =>{
    console.log(options);
    const response = await this.connections.backend
      .httpGet(`${memberships}`);
    return response;
  }

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  };

  @observable private _selectedEntity:MembershipsDTO;
  
  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: Partial<MembershipsDTO>): Promise<void> {
    try {
      await this.connections.backend.httpPost(memberships, data);
      return Promise.resolve();
    } catch (error:any) {
      return Promise.reject(error.response.data.message);
    }
  }

  @action
  public async getOne(id: string): Promise<ModelOf<MembershipsDTO>> {
    const response = await this.connections.backend.httpGet(membership(parseInt(id, 10)));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action update = async (id: number, data: Partial<MembershipsDTO>) => {
    try {
      const response = await this.connections.backend.httpPut(membership(id), data);
      return Promise.resolve(response);
    } catch (error:any) {
      return Promise.reject(error.response.data.message);
    }
  };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(membership(id));
    return Promise.resolve(response);
  }

  @action 
  public async linkDoctor(membershipId:number,doctorId:number,data:Pick<MembershipsDTO,"discountValue"|"validUntil">): Promise<void> {
    const response = await this.connections.backend
      .httpPost(doctorMembership(membershipId,doctorId),data);
    return Promise.resolve(response);
  }
}