import {
  SwitchProps,
} from '@material-ui/core';
import {
  KeyboardDatePickerProps,
  KeyboardTimePickerProps,
} from '@material-ui/pickers';
import {
  EditorProps,
} from 'react-draft-wysiwyg';
import * as Yup from 'yup';
import {
  ModelOf,
} from 'utils';
import {
  BaseDTO as DTO,
} from 'shared';
import {
  FormikContextType,
  FormikProps,
} from 'formik';
import {
  BaseFormViewModel,
} from '../../screens/types';
import Condition from './Utils/Condition';

// eslint-disable-next-line no-shadow
export enum GeneralFormFields {
  INPUT_FIELD = 'inputField',
  TEXT_AREA = 'textArea',
  FILE_PICKER = 'filePicker',
  NESTED_FORM = 'nestedForm',
  FIELD_ARRAY = 'fieldArray',
  SELECT_FIELD = 'selectField',
  RICH_TEXT_FIELD = 'richTextField',
  DATE_TIME_FIELD = 'dateTimeField',
  TOGGLE_FIELD = 'toggleField',
  LOCATION_FIELD = 'locationField',
  SCHEDULE = 'schedule',
}

// eslint-disable-next-line no-shadow
export enum AvailableLanguages {
  ARABIC = 'ar',
  ENGLISH = 'en',
}
export interface ScheduleFormField {
  type: GeneralFormFields.SCHEDULE;
  fieldOptions: ScheduleFieldOptions;
}

export type ScheduleFieldOptions = {
  disabled?: boolean;
};

export type Props<EntityDTO extends DTO, FormViewModel extends BaseFormViewModel<EntityDTO>> = {
  viewMode?: boolean;
  formData: FieldsData<FormViewModel>;
  defaultLang: string;
  otherLanguages: string[];
  getRef?: (ref: FormikProps<FormViewModel>) => void;
  title: string;
  identifier: keyof ModelOf<FormViewModel>;
  get: (identifier: string, language: string) => Promise<FormViewModel>;
  create?: (formValue: FormViewModel) => Promise<number>;
  update: (identifier: string, formValue: FormViewModel, language: string) => Promise<void>;
  isFetchSuccessful: boolean;
  createOrUpdateStatus?: RequestStatus;
};

// eslint-disable-next-line no-shadow
export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export interface FormCustomPreview<T> {
  component: React.ComponentType<T>;
}

export interface RouteParams {
  id: string;
}

export interface GeneralFormFieldProperties {
  initialValue: any;
  validationSchema: Yup.SchemaOf<any>;
  component: any;
}

export type FieldsData<T, K extends keyof T = keyof T, V = T> = (FormFieldData<T, K> | FormCustomPreview<V>)[];

export function isCustomFormPreview<T>(data: any): data is FormCustomPreview<T> {
  return !!(data as FormCustomPreview<T>).component;
}

export function isFormField<T, K extends keyof T = keyof T>(data: any): data is FormFieldData<T, K> {
  return !!(data as FormFieldData<T, K>).key;
}

export type FormDataObject<T> = {
  [K in keyof Partial<T>]: FormFieldData<T, K>;
};

export type InputFieldOptions = {
  type?: React.InputHTMLAttributes<unknown>['type'];
  disabled?: boolean;
};

export interface InputFormField {
  type: GeneralFormFields.INPUT_FIELD;
  fieldOptions: InputFieldOptions;
}

export type TextAreaFieldOptions = {
};

export interface TextAreaFormField {
  type: GeneralFormFields.TEXT_AREA;
  fieldOptions: TextAreaFieldOptions;
}

export interface ImagePickerFieldOption {
  ratio?: number;
  size: number;
  disabled?: boolean;
  accept: string;
  multipleImages: boolean;
}

export interface ImagePickerFormField {
  type: GeneralFormFields.FILE_PICKER;
  fieldOptions: ImagePickerFieldOption;
}

export interface NestedFormFieldOptions<T> {
  formData: FieldsData<T>;
}

export interface NestedFormFormField<T, K extends keyof T> {
  type: GeneralFormFields.NESTED_FORM;
  fieldOptions: NestedFormFieldOptions<T[K]>;
}

export interface FormFieldArrayOptions<T, K extends keyof T> {
  formData: KeylessFormFieldData<T[K] extends unknown[] ? T[K] : any[], number>;
}

export interface FormFieldArray<T, K extends keyof T> {
  type: GeneralFormFields.FIELD_ARRAY;
  fieldOptions: FormFieldArrayOptions<T, K>;
}

export interface SelectFieldItems<T> {
  title: string;
  value: T;
}

export interface SelectFieldOptions<T, K = T extends unknown[] ? T[number] : any> {
  items: Promise<SelectFieldItems<K>[]> | SelectFieldItems<K>[];
  isMultiple: boolean;
  onValueChanged?: (input: any, form?: FormikContextType<any>) => void;
}

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];

export interface SelectField<T> {
  type: GeneralFormFields.SELECT_FIELD;
  fieldOptions: SelectFieldOptions<T>;
}

export interface RichTextFieldOptions {
  editorProps?: EditorProps;
}

export interface RichTextFormField {
  type: GeneralFormFields.RICH_TEXT_FIELD;
  fieldOptions: RichTextFieldOptions;
}

// eslint-disable-next-line no-shadow
export enum DateTimeFormFieldTypes {
  DATE,
  TIME,
  DATE_TIME,
}

export interface DateOnlyFormFieldOptions {
  type: DateTimeFormFieldTypes.DATE;
  datePickerProps?: KeyboardDatePickerProps;
}

export interface TimeOnlyFormFieldOptions {
  type: DateTimeFormFieldTypes.TIME;
  timePickerProps?: KeyboardTimePickerProps;
}

export interface DateAndTimeFormFieldOptions {
  type: DateTimeFormFieldTypes.DATE_TIME;
  datePickerProps?: KeyboardDatePickerProps;
  timePickerProps?: KeyboardTimePickerProps;
}

export type DateTimeFormFieldOptions = (DateOnlyFormFieldOptions
| TimeOnlyFormFieldOptions
| DateAndTimeFormFieldOptions) & {
  onValueChanged?: (val: Date) => void;
};

export function isDateOnlyPicker(fieldOptions: DateTimeFormFieldOptions): fieldOptions is DateOnlyFormFieldOptions {
  return fieldOptions.type === DateTimeFormFieldTypes.DATE;
}

export function isTimeOnlyPicker(fieldOptions: DateTimeFormFieldOptions): fieldOptions is TimeOnlyFormFieldOptions {
  return fieldOptions.type === DateTimeFormFieldTypes.TIME;
}

export function isDateAndTimePicker(
  fieldOptions: DateTimeFormFieldOptions,
): fieldOptions is DateAndTimeFormFieldOptions {
  return fieldOptions.type === DateTimeFormFieldTypes.DATE_TIME;
}

export interface DateTimeFormField {
  type: GeneralFormFields.DATE_TIME_FIELD;
  fieldOptions: DateTimeFormFieldOptions;
}

export interface ToggleFormFieldOptions {
  switchProps?: SwitchProps;
}

export interface ToggleFormField {
  type: GeneralFormFields.TOGGLE_FIELD;
  fieldOptions: ToggleFormFieldOptions;
}

export type LocationFormFieldOptions = {
};

export interface LocationFormField {
  type: GeneralFormFields.LOCATION_FIELD;
  fieldOptions: LocationFormFieldOptions;
}

export interface MapLocation {
  longitude: number;
  latitude: number;
}

export interface FormDataCommon<T, K extends keyof T = keyof T> extends KeylessFormDataCommon<T, K> {
  key: K;
}

export interface KeylessFormDataCommon<T, K extends keyof T = keyof T> {
  title: string;
  hasTranslations?: boolean;
  condition?: (values: any, actions: Record<FormFieldActions, () => void>, currentPath: string) => Condition;
  validationSchema?: Yup.SchemaOf<T[K]>;
  initialValue?: T[K];
}

// eslint-disable-next-line no-shadow
export enum FormFieldActions {
  ENABLE,
  DISABLE,
  HIDE,
}

// eslint-disable-next-line no-shadow
export enum FormFieldStatus {
  ENABLED,
  DISABLED,
  HIDDEN,
}

export type FormFieldOptions<T, K extends keyof T> = InputFormField
| TextAreaFormField
| ImagePickerFormField
| NestedFormFormField<T, K>
| FormFieldArray<T, K>
| SelectField<T[K]>
| RichTextFormField
| DateTimeFormField
| ToggleFormField
| LocationFormField
| ScheduleFormField;

export type FormFieldData<
  T,
  K extends keyof T,
  > = FormDataCommon<T, K> & FormFieldOptions<T, K>;
export type KeylessFormFieldData<
  T,
  K extends keyof T,
  > = KeylessFormDataCommon<T, K> & FormFieldOptions<T, K>;

export default GeneralFormFields;
