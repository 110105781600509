import {
  Typography,
} from '@material-ui/core';
import React from 'react';
import i18n from 'i18n-js';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  useStyles,
} from './styles';
import {
  Props,
} from './types';

const Clinic = (props: Props) => {
  const {
    clinic,
  } = props;
  const classes = useStyles();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 0.3,
        marginRight: 10,
        marginLeft: 10,
      }}
    >
      <Typography
        style={{
          textDecoration: 'underline',
        }}
      >
        {clinic.name}
      </Typography>
      <div
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          borderWidth: 1.5,
          borderColor: 'black',
          borderStyle: 'solid',
          paddingRight: 5,
          paddingLeft: 5,
        }}
      >
        <div
          className={classes.item}
        >
          <Typography
            className={classes.itemDetail}
          >
            {i18n.t('MOBILE_NUMBER')}
          </Typography>
          <Typography
            className={classes.itemDetail}
          >
            {clinic.mobileNumber}
          </Typography>
        </div>
        <div
          className={classes.item}
        >
          <Typography
            className={classes.itemDetail}
          >
            {i18n.t('FEES')}
          </Typography>
          <Typography
            className={classes.itemDetail}
          >
            {clinic.fees}
          </Typography>
        </div>
        <div
          className={classes.item}
        >
          <Typography
            className={classes.itemDetail}
          >
            {i18n.t('ADDRESS')}
          </Typography>
          <Typography
            className={classes.itemDetail}
          >
            {clinic.address}
          </Typography>
        </div>
        <div
          className={classes.item}
        >
          <Typography
            className={classes.itemDetail}
          >
            {i18n.t('LOCATION')}
          </Typography>
          <Typography
            className={classes.itemDetail}
          >
            {clinic.address}
            {' '}
            <LocationOnIcon
              onClick={() => {
                // eslint-disable-next-line
                window.open(`http://www.google.com/maps/@${clinic.location.latitude},${clinic.location.longitude},16z`, '_blank');
              }}
            />
          </Typography>

        </div>
      </div>
    </div>
  );
};

export default Clinic;
