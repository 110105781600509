import {
  baseScreen,
} from 'hoc';
import React,
{
  useEffect,
  useState,
} from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  useHistory,
  useParams,
} from 'react-router';
import * as Yup from 'yup';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  Speciality,
} from '../specialities/types';

const specialitiesForm: React.FC = () => {
  const {
    backend: {
      specialities,
    },
    ui: {
      localization,
    },
  } = useStores();
  const [
    specialitiesList,
    setSpecialitiList,
  ] = useState<{title: string, value: string}[]>([]);

  const history = useHistory();
  const {
    id: specialityId,
  } = useParams() as any;
  useEffect(() => {
    const getSpecialities = async () => {
      const list = await specialities.getAll({});
      setSpecialitiList(list.map((item) => {
        const {
          title,
        } = item.translations
          .filter((el) => el.languageCode === localization.currentLanguage.key)[0];
        return {
          title,
          value: item?.id?.toString() as string,
        };
      }));
    };
    getSpecialities();
  }, []);
  return (
    <GeneralFormView<any, Speciality>
      title={i18n.t('SPECIALITY')}
      identifier="id"
      defaultLang=""
      otherLanguages={[]}
      formData={new FormFieldsBuilder<Speciality>()
        .addField('englishName', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('ENGLISH_TITLE'),
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
          fieldOptions: {},
        })
        .addField('arabicName', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('ARABIC_TITLE'),
          fieldOptions: {},
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
        })
        .addField('parent', {
          type: GeneralFormFields.SELECT_FIELD,
          title: i18n.t('SPECIALITY'),
          fieldOptions: {
            isMultiple: false,
            items: specialitiesList,
          },
          // validationSchema: Yup.number().nullable().default(null),
        })
        .formFields}
      isFetchSuccessful
      update={async (id, data) => {
        try {
          if (specialityId) {
            const parentId = data.parent !== undefined ? Number(data.parent.pop()) : undefined;
            await specialities.updateSpeciality(specialityId, {
              translations: [
                {
                  languageCode: LanguagesKey.AR,
                  title: data.arabicName,
                }, {
                  languageCode: LanguagesKey.EN,
                  title: data.englishName,
                },
              ],
              parentId,
            });
          }
          history.push('/system-setup/system-setup/specialities');
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      create={async (data) => {
        try {
          const parentId = data.parent !== undefined ? Number(data.parent.pop()) : undefined;
          await specialities.create({
            translations: [
              {
                languageCode: LanguagesKey.AR,
                title: data.arabicName,
              }, {
                languageCode: LanguagesKey.EN,
                title: data.englishName,
              },
            ],
            parentId,
          });
          history.push('/system-setup/system-setup/specialities');
          return Promise.resolve(1);
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async () => {
        let speciality: any = {};
        if (specialityId) {
          const res = await specialities.getOne(specialityId);
          speciality = new Speciality(res);
        }
        return Promise.resolve(speciality);
      }}
    />
  );
};

export const SpecialitiesForm = baseScreen(specialitiesForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
