export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN_PREFIX,
  androidPACKAGEName: process.env.REACT_APP_FIREBASE_ANDROID_PACKAGE_NAME || '',
  iosBundleId: process.env.REACT_APP_FIREBASE_IOS_BUNDLE_ID || '',
};

const firebasedynamiclinks = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks';

export const deepLinkingBaseUrl = `${firebasedynamiclinks}?key=${firebaseConfig.apiKey}`;
