import {
  baseScreen,
} from 'hoc';
import React,
{
  useEffect,
} from 'react';
import {
  useHistory,
} from 'react-router';
import {
  VerificationMessage,
} from 'components';
import i18n from 'i18n-js';

const formConfirmation: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push('/users/:id/service-providers/doctors');
    }, 3000);
  }, []);
  return (
    <VerificationMessage
      message={i18n.t('DOCTOR_CONFIRMATION_MESSAGE')}
      action={() => history.push('/users/:id/service-providers/doctors')}
    />
  );
};

export const DoctorFormConfirmation = baseScreen(formConfirmation, {
  allowedRoles: ['ADMIN', 'NONE'],
});
