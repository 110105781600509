import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  Assets,
} from 'assets';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {
  useHistory,
} from 'react-router';
import TableToggleButton from 'src/components/TableToggleButton';
import {
  ServiceProvider,
} from './types';

const serviceProviders: React.FC = () => {
  const {
    backend: {
      serviceProviders: serviceProvidersStore,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  return (
    <div>
      <GeneralTableView<ServiceProvider>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: true,
            paging: false,
          },
          title: i18n.t('SERVICE_PROVIDERS'),
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.trash}
                  alt={i18n.t('DELETE')}
                />
              ),
              onClick: async (event, data) => {
                if (!Array.isArray(data)) {
                  const response = window.confirm(i18n.t('DELETE_SERVICE_PROVIDERS_CONFIRMATION'));
                  if (response) {
                    await serviceProvidersStore.delete(data.id as number);
                    if (ref && ref.current) {
                      ref.current.onQueryChange();
                    }
                  }
                }
              },
            },
            {
              isFreeAction: true,
              icon: ImportExportIcon,
              onClick: () => {
                window.open(serviceProvidersStore.download(), '_blanck');
              },
              tooltip: i18n.t('EXPORT_DATA'),
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const item: any = data;
                history.push(`/service-providers-form/edit/${item.id}`);
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (event, data) => {
                const item: any = data;
                history.push(`/service-providers-form/view/${item.id}`);
              },
            },
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                history.push('/service-providers-form/new/:id');
              },
              tooltip: i18n.t('ADD_NEW_SERVICE_PROVIDER'),
            },
          ],
        }}
        options={{
          search: false,
          filtering: false,
        }}
        get={async (query) => {
          await serviceProvidersStore.entityList.updateOptions(query);
          return {
            data: serviceProvidersStore.entityList.data.map((item: any) => new ServiceProvider(item)),
            totalCount: 20,
            page: 1,
          };
        }}
        tableColumns={[
          {
            field: 'id',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'name',
            title: i18n.t('NAME'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'type',
            title: i18n.t('TYPE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'email',
            title: i18n.t('EMAIL'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'coordinatorPhone',
            title: i18n.t('COORDINATOR_PHONE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'area',
            title: i18n.t('LOCATION'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'isActive',
            title: i18n.t('IS_ACTIVE'),
            type: TableColumnTypes.BOOLEAN,
            columnProps: {
              editable: 'always',
              render: (data) => (
                <TableToggleButton
                  isActive={data.isActive}
                  activationConfirmationText={i18n.t('ACTIVATE_SERVICE_PROVIDER_CONFIRMATION')}
                  deactivationConfirmationText={i18n.t('DEACTIVATE_SERVICE_PROVIDER_CONFIRMATION')}
                  onChange={async (response) => {
                    if (response) {
                      const admin: any = data;
                      await serviceProvidersStore.updateServiceProvider(admin.id, {
                        isActive: !data.isActive,
                      });
                      if (ref && ref.current) {
                        ref.current.onQueryChange();
                      }
                    }
                  }}
                />
              ),
            },
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'isAcceptingDonation',
            title: i18n.t('IS_ACCEPTING_DONATION'),
            type: TableColumnTypes.BOOLEAN,
            columnProps: {
              editable: 'always',
              render: (data) => (
                <TableToggleButton
                  isActive={data.isAcceptingDonation}
                  activationConfirmationText={i18n.t('ACCEPT_DONATION_SERVICE_PROVIDER_CONFIRMATION')}
                  deactivationConfirmationText={i18n.t('REJECT_DONATION_SERVICE_PROVIDER_CONFIRMATION')}
                  onChange={async (response) => {
                    if (response) {
                      const admin: any = data;
                      await serviceProvidersStore.updateServiceProvider(admin.id, {
                        isAcceptingDonation: !data.isAcceptingDonation,
                      });
                      if (ref && ref.current) {
                        ref.current.onQueryChange();
                      }
                    }
                  }}
                />
              ),
            },
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const ServiceProviders = baseScreen(serviceProviders, {
  allowedRoles: ['ADMIN', 'NONE'],
});
