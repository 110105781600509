import {
  Typography,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {
  getIn,
  useFormikContext,
} from 'formik';
import React, {
  useEffect,
} from 'react';
import FieldError from '../../Utils/FieldError';
import {
  FieldView,
} from '../../Utils/FieldView';
import {
  FieldPropsCommon,
  TextAreaFieldValue,
} from '../types';
import {
  onChange,
} from '../utils';

type Props = FieldPropsCommon & TextAreaFieldValue;

const TextAreaField = <FormModel extends object>(
  props: Props,
) => {
  const {
    values,
    handleChange,
    errors,
    touched,
    setFieldTouched,
    isSubmitting,
  } = useFormikContext<FormModel>();

  const {
    disabled,
    title,
    location,
    viewMode,
  } = props;

  const value: string = getIn(values, location);
  const onValueChange = onChange(handleChange, location);
  const error = getIn(errors, location);
  const isTouched = getIn(touched, location);

  useEffect(() => {
    if (isSubmitting) {
      setFieldTouched(location);
    }
  }, [isSubmitting, location, setFieldTouched]);

  if (viewMode) {
    return (
      <FieldView
        title={title}
        value={() => (
          <Typography
            variant="body2"
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {value}
          </Typography>
        )}
      />
    );
  }

  return (
    <>
      <TextField
        style={{
          margin: 8,
        }}
        value={value}
        variant="outlined"
        multiline
        disabled={disabled}
        onBlur={() => setFieldTouched(location)}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        label={title}
      />
      <FieldError
        errors={error}
        touched={isTouched}
      />
    </>
  );
};

export default TextAreaField;
