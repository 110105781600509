import {
  baseScreen,
} from 'hoc';
import {
  useObserver,
} from 'mobx-react';
import React from 'react';
import {
  Assets,
} from 'assets';

const homeScreen: React.FC = () => useObserver(() => (
  <div>
    <img
      style={{
        width: '100%',
      }}
      src={Assets.images.screens.home.charts}
      alt="Charts"
    />
  </div>
));

export const HomeScreen = baseScreen(homeScreen, {
  allowedRoles: ['ADMIN', 'NONE'],
});
