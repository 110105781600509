import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

export const Styles = makeStyles(() => createStyles({
  flag: {
    height: 25,
  },
  container: {
    marginRight: 50,
    marginLeft: 50,
    alignSelf: 'center',
    display: 'flex',
  },
}));
