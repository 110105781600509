import {
  Users,
  Login,
  HomeScreen,
  Patients,
  PatientsForm,
  ServiceProviders,
  Admins,
  Balance,
  Requests,
  Notifications,
  Promocodes,
  SystemSetup,
  AdminFormConfirmation,
  FormConfirmation,
  ServiceProverTypes,
  Doctors,
  CustomNotificationformConfirmation,
  ServiceProverTypesForm,
  Specialities,
  SpecialitiesForm,
  AdminsForm,
  HospitalSettlement,
  Grades,
  GradesForm,
  DoctorFormConfirmation,
  InsuranceCompaniesForm,
  WeeklyBreakdown,
  DoctorDetails,
  ServiceProviderFormConfirmation,
  PromocodesForm,
  InsuranceCompanies,
  NotificationsForm,
  MonthlyBreakdown,
  PatientDetails,
  SyndicateIdVerification,
  HospitalsBalance,
  ServiceProverForm,
  DoctorsBalance,
  Memberships,
  MembershipsForm,
  Donation,
  DonationMonthlyBreakdown,
  Accountants,
  AccountantsForm,
  AccountantFormConfirmation,
  Nationalities,
  NaitonalitiesForm,
  // TestScreen,
} from 'src/screens';
import {
  Categories,
}
  from 'src/screens/categories';
import {
  CategoriesForm,
} from 'src/screens/categoriesForm';
import {
  Complaints,
} from 'src/screens/complaints';
import {
  ModuleTypes,
  Route,
} from './types';

const admins = new Route({
  path: '/admins',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Admins,
  sideNavConfig: {},
  name: 'ADMINS',
});

const notificationsForm = new Route({
  path: '/notifications-form',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: NotificationsForm,
  name: 'ADMINS',
});

const adminsForm = new Route({
  path: '/admins-form/:mode/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: AdminsForm,
  name: '',
});

const serviceProviders = new Route({
  path: '/service-providers',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  sideNavConfig: {},
  Component: ServiceProviders,
  name: 'HOSPITALS_AND_CENTERS',
});

const doctors = new Route({
  path: '/doctors',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Doctors,
  sideNavConfig: {},
  name: 'DOCTORS',
});

const serviceProvidersTab = new Route({
  path: '/service-providers',
  type: ModuleTypes.CONTAINER,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  sideNavConfig: {},
  name: 'SERVICE_PROVIDERS',
}).addChild(serviceProviders)
  .addChild(doctors);

const syndicateIdVerification = new Route({
  path: 'syndicate-ID-verification/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: SyndicateIdVerification,
  name: 'Syndicate ID Verification',
});

// const routeCA = new Route({
//   path: 'route-a',
//   type: ModuleTypes.SCREEN,
//   allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
//   Component: testScreenTable,
//   name: 'Route C A',
//   sideNavConfig: {
//     icon: Assets.images.common.placeholder,
//   },
// });

const patients = new Route({
  path: '/patients',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Patients,
  sideNavConfig: {},
  name: 'PATIENTS',
});

const patientsForm = new Route({
  path: '/patients-form/:mode/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: PatientsForm,
  name: '',
});

const accountants = new Route({
  path: '/accountants',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Accountants,
  sideNavConfig: {},
  name: 'ACCOUNTANTS',
});

const accountantsForm = new Route({
  path: '/accountants-form/:mode/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: AccountantsForm,
  name: '',
});

const accountantFormConfirmation = new Route({
  path: '/accountant-confirmation',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: AccountantFormConfirmation,
  name: '',
});

const users = new Route({
  path: '/users/:id',
  type: ModuleTypes.CONTAINER,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Users,
  name: 'USERS',
  sideNavConfig: {},
})
  .addChild(admins)
  .addChild(serviceProvidersTab)
  .addChild(patients)
  .addChild(accountants);

const doctorsBalance = new Route({
  path: '/balance/doctors',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ACCOUNTANT', 'ADMIN', 'SUPER_ADMIN'],
  sideNavConfig: {},
  Component: DoctorsBalance,
  name: 'DOCTORS',
});

const hospitalsBalance = new Route({
  path: '/balance/hospitals',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ACCOUNTANT', 'ADMIN', 'SUPER_ADMIN'],
  sideNavConfig: {},
  Component: HospitalsBalance,
  name: 'HOSPITALS',
});

const hospitalsBalanceSettlement = new Route({
  path: '/balance/hospitals/settlement/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: HospitalSettlement,
  name: '',
});

const doctorsWeeklyBreakdown = new Route({
  path: '/balance/balance/doctors/weekly-breakdown/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: WeeklyBreakdown,
  name: '',
});

const hospitalMonthlyBreakdown = new Route({
  path: '/balance/balance/hospitals/monthly-breakdown/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: MonthlyBreakdown,
  name: '',
});

const donation = new Route({
  path: 'balance/donation',
  type: ModuleTypes.CONTAINER,
  Component: Donation,
  allowedRoles: ['ACCOUNTANT', 'ADMIN', 'SUPER_ADMIN'],
  name: 'DONATION',
  sideNavConfig: {},
});

const donationMonthlyBreakdown = new Route({
  path: 'balance/donation/monthly-breakdown/:date',
  type: ModuleTypes.CONTAINER,
  Component: DonationMonthlyBreakdown,
  allowedRoles: ['ACCOUNTANT', 'ADMIN', 'SUPER_ADMIN'],
  name: 'DONATION_MONTHLY_BREAKDOWN',
});

const donationSettlement = new Route({
  path: 'balance/donation/settlement/:id',
  type: ModuleTypes.CONTAINER,
  Component: HospitalSettlement,
  allowedRoles: ['ACCOUNTANT', 'ADMIN', 'SUPER_ADMIN'],
  name: 'SETTLEMENT',
});

const balance = new Route({
  path: '/balance',
  type: ModuleTypes.CONTAINER,
  allowedRoles: ['ACCOUNTANT', 'ADMIN', 'SUPER_ADMIN'],
  sideNavConfig: {},
  Component: Balance,
  name: 'BALANCE',
})
  .addChild(doctorsBalance)
  .addChild(hospitalsBalance)
  .addChild(donation)
  .addChild(donationMonthlyBreakdown)
  .addChild(donationSettlement);

const serviceProverTypesForm = new Route({
  path: '/service-provider-type-form/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: ServiceProverTypesForm,
  name: '',
});

const specialitiesForm = new Route({
  path: '/specialities-form/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: SpecialitiesForm,
  name: '',
});

const membershipsForm = new Route({
  path: '/membership-form/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: MembershipsForm,
  name: '',
});

const nationalitiesForm = new Route({
  path: '/nationality-form/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: NaitonalitiesForm,
  name: '',
});

const specialities = new Route({
  path: '/system-setup/specialities',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Specialities,
  sideNavConfig: {},
  name: 'SPECIALITIES',
});

const memberships = new Route({
  path: '/system-setup/memberships',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Memberships,
  sideNavConfig: {},
  name: 'MEMBERSHIPS',
});

const nationalities = new Route({
  path: '/system-setup/nationalities',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Nationalities,
  sideNavConfig: {},
  name: 'NATIONALITIES',
});

const complaints = new Route({
  path: '/complaints',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Complaints,
  sideNavConfig: {},
  name: 'COMPLAINTS',
});

const categories = new Route({
  path: '/categories',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Categories,
  sideNavConfig: {},
  name: 'CATEGORIES',
});

const categoriesForm = new Route({
  path: '/categories-form/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: CategoriesForm,
  name: '',
});

const gradesForm = new Route({
  path: '/grades-form/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: GradesForm,
  name: '',
});

const promocodesForm = new Route({
  path: '/promocodes-form/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: PromocodesForm,
  name: '',
});

const grades = new Route({
  path: '/system-setup/grades',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Grades,
  sideNavConfig: {},
  name: 'GRADES',
});

const insuranceCompaniesForm = new Route({
  path: '/insurance-companies-form/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: InsuranceCompaniesForm,
  name: '',
});

const serviceProverForm = new Route({
  path: '/service-providers-form/:mode/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: ServiceProverForm,
  name: '',
});

const insuranceCompanies = new Route({
  path: '/system-setup/insurance-companies',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: InsuranceCompanies,
  sideNavConfig: {},
  name: 'INSURANCE_COMPANIES',
});
const doctorsFormConfirmation = new Route({
  path: '/doctors/form-confirmation',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: DoctorFormConfirmation,
  name: '',
});
const serviceProvidersFormConfirmation = new Route({
  path: '/service-providers/form-confirmation',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: ServiceProviderFormConfirmation,
  name: '',
});

const bookings = new Route({
  path: '/bookings',
  type: ModuleTypes.SCREEN,
  sideNavConfig: {},
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Requests,
  name: 'BOOKING_REQUESTS',
});

const notifications = new Route({
  path: '/notifications',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Notifications,
  name: 'NOTIFICATIONS',
  sideNavConfig: {},
});

const doctorDetails = new Route({
  path: '/doctor-details/:mode/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: DoctorDetails,
  name: '',
});

const patientDetails = new Route({
  path: 'patient-details/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: PatientDetails,
  name: '',
});

const promocodeFormConfirmation = new Route({
  path: '/promo-code-confirmation',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: FormConfirmation,
  name: '',
});
const customNotificationConfirmation = new Route({
  path: '/custom-notification-confirmation',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: CustomNotificationformConfirmation,
  name: '',
});

const adminFormConfirmation = new Route({
  path: '/admin-confirmation',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: AdminFormConfirmation,
  name: '',
});

const promoCodes = new Route({
  path: '/promo-codes',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: Promocodes,
  name: 'PROMO_CODES',
  sideNavConfig: {},
});

const serviceProverType = new Route({
  path: '/system-setup/service-provider-types',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: ServiceProverTypes,
  sideNavConfig: {},
  name: 'SERVICE_PROVIDER_TYPES',
});

const systemSetup = new Route({
  path: '/system-setup',
  type: ModuleTypes.CONTAINER,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: SystemSetup,
  sideNavConfig: {},
  name: 'SYSTEM_SETUP',
}).addChild(specialities)
  .addChild(insuranceCompanies)
  .addChild(serviceProverType)
  .addChild(grades)
  .addChild(memberships)
  .addChild(nationalities);

const help = new Route({
  path: '/help',
  type: ModuleTypes.CONTAINER,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  Component: SystemSetup,
  sideNavConfig: {},
  name: 'HELP_CENTER',
}).addChild(categories)
  .addChild(categoriesForm)
  .addChild(complaints);

export const AuthRoute = new Route({
  path: '/',
  type: ModuleTypes.SCREEN,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  name: 'Authentication',
  Component: Login,
});

// const EditUserrRoute = new Route({
//   path: ':id/edit',
//   type: ModuleTypes.SCREEN,
//   allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
//   name: 'Edit user',
//   Component: RegisterScreen,
//   sideNavConfig: {
//     icon: Assets.images.common.kashfLogo,
//   },
// });

export const RootRoute = new Route({
  path: '/',
  type: ModuleTypes.CONTAINER,
  allowedRoles: ['ADMIN', 'SUPER_ADMIN'],
  name: 'Home',
  Component: HomeScreen,
})
  .addChild(users)
  .addChild(systemSetup)
  .addChild(help)
  .addChild(bookings)
  .addChild(balance)
  .addChild(doctorDetails)
  .addChild(patientDetails)
  .addChild(notifications)
  .addChild(insuranceCompaniesForm)
  .addChild(serviceProverForm)
  .addChild(adminsForm)
  .addChild(accountantsForm)
  .addChild(patientsForm)
  .addChild(accountantFormConfirmation)
  .addChild(notificationsForm)
  .addChild(adminFormConfirmation)
  .addChild(serviceProverTypesForm)
  .addChild(promocodeFormConfirmation)
  .addChild(gradesForm)
  .addChild(hospitalsBalanceSettlement)
  .addChild(promocodesForm)
  .addChild(doctorsWeeklyBreakdown)
  .addChild(hospitalMonthlyBreakdown)
  .addChild(customNotificationConfirmation)
  .addChild(serviceProvidersFormConfirmation)
  .addChild(specialitiesForm)
  .addChild(membershipsForm)
  .addChild(doctorsFormConfirmation)
  .addChild(promoCodes)
  .addChild(nationalitiesForm)
  // .addChild(home)
  // .addChild(Test)
  .addChild(syndicateIdVerification)
  .addChild(doctorDetails);
