import {
  AdminsDTO,
} from 'src/stores/backend/AdminsStore/types';
import {
  BaseFormViewModel,
} from '../types';

export class Admin extends BaseFormViewModel<AdminsDTO> {
  id?: number;

  name: string;

  phone: string;

  email: string;

  role: string | string[];

  isActive: boolean;

  password: boolean;

  confirmPassword?: boolean;

  accessibility: string;

  constructor(data: AdminsDTO) {
    super();
    this.id = data.id;
    this.email = data.email;
    this.isActive = data.isActive;
    this.name = data.name;
    this.phone = data.phone;
    this.role = [data.role];
    this.accessibility = data.accessibility;
  }

  toDTO(): AdminsDTO {
    return { } as AdminsDTO;
  }
}
