import React, {
  PropsWithChildren,
} from 'react';
import MaterialTable, {
  Column,
} from 'material-table';
import {
  isNil,
} from 'lodash';
import {
  Props,
  FILTER_COMPONENTS,
  FilterTypes,
  TABLE_TO_MATERIAL_TABLE_TYPE,
  TableColumnTypes,
} from './types';

const GeneralTableView = <
  TableModel extends Record<string, any>,
  >(props: PropsWithChildren<Props<TableModel>>) => {
  const {
    tableColumns,
    get,
    tableProps = { },
    options,
  } = props;
  return (
    <MaterialTable<TableModel>
      data={get}
      options={{
        filtering: true,
        ...options,
      }}
      columns={
        tableColumns
          .map<Column<TableModel>>(
          (column) => ({
            field: column.field,
            title: column.title,
            type: TABLE_TO_MATERIAL_TABLE_TYPE[column.type],
            filterProps: column.filterProps,
            render: column.type === TableColumnTypes.LOCATION
              ? (data) => (<div>{JSON.stringify(data[column.field])}</div>)
              : undefined,
            // eslint-disable-next-line max-len
            filterComponent: (FILTER_COMPONENTS[column.type] as any)[isNil(column.filterType) ? FilterTypes.VALUE : column.filterType] as any,
            ...(column.columnProps || {}),
          }),
        )
      }
      {...tableProps}
    />
  );
};

export default GeneralTableView;
