import React from 'react';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {
  Assets,
} from 'assets';
import i18n from 'i18n-js';
import {
  Props,
} from './types';
import {
  useStyles,
} from './styles';

export const VerificationMessage: React.FC<Props> = (props) => {
  const {
    message,
    action,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <img src={Assets.images.common.logo} alt={i18n.t('KASHF')} className={classes.img} />
        <Typography className={classes.text}>
          {message}
        </Typography>
        <Button onClick={() => action()} type="submit" variant="outlined" className={classes.btn}>
          {i18n.t('OK')}
        </Button>
      </div>
    </div>
  );
};
