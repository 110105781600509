import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
  useState,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  useHistory,
} from 'react-router';
import {
  Assets,
} from 'assets';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {
  BillStatus,
} from 'src/stores/backend/DonationBillStore/types';
import {
  Button,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import DateRange from 'src/components/DateRange';
import SearchFilterDropdown from 'src/components/SearchFilterComponent';
import {
  DonationBill,
  ListFilter,
} from './types';

const donation: React.FC = () => {
  const {
    backend: {
      donationBillsStore,
    },
  } = useStores();
  const ref = useRef<any|null>(null);
  const [
    filterDisplayed,
    setFilterDisplayed,
  ] = useState(false);
  const [
    range,
    setRange,
  ] = useState<{
    min: Date,
    max: Date,
  }>({} as any);
  const [
    currentFilter,
    setCurrentFilter,
  ] = useState<ListFilter>(ListFilter.ALL);
  const onFilterChange = (val: ListFilter) => {
    setCurrentFilter(val);
    ref.current?.onQueryChange();
  };
  const history = useHistory();
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 16,
          cursor: 'pointer',
        }}
        onClick={() => setFilterDisplayed(!filterDisplayed)}
        tabIndex={0}
        role="button"
        onDoubleClick={() => {
          setFilterDisplayed(!filterDisplayed);
        }}
        onKeyDown={() => {
        }}
      >
        <span
          style={{
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {i18n.t('FILTER')}
        </span>
        <FilterListIcon />
      </div>
      {filterDisplayed && (
        <div>
          <SearchFilterDropdown
            label="Filter"
            style={{
              margin: 16,
            }}
            searchFilterDropdown={{
              filters: [
                {
                  name: i18n.t('ALL'),
                  value: 'all',
                },
              ],
              value: currentFilter || ListFilter.ALL,
              initialValue: currentFilter || ListFilter.ALL,
              onChange: onFilterChange,
            }}
          />
          <DateRange
            onChange={async (start, end) => {
              await setRange({
                min: start,
                max: end,
              });
              ref.current?.onQueryChange();
            }}
          />
          <div
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              marginBottom: 16,
            }}
          >
            <Button
              style={{
                background: '#3f51b5',
                color: '#FFF',
              }}
              onClick={async () => {
                await setFilterDisplayed(false);
                await setRange({} as any);
                await setCurrentFilter(ListFilter.ALL);
                ref.current?.onQueryChange();
              }}
            >
              {i18n.t('RESET')}
            </Button>
          </div>
        </div>
      )}

      <GeneralTableView<DonationBill>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: true,
            paging: true,
          },
          title: i18n.t('DONATION'),
          actions: [
            {
              isFreeAction: true,
              icon: ImportExportIcon,
              onClick: () => {
                window.open(donationBillsStore.download(), '_blanck');
              },
              tooltip: i18n.t('EXPORT_DATA'),
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (ev: any, data: any) => {
                history.push(`/donation/monthly-breakdown/${data.date}`);
              },
            },
          ],
        }}
        options={{
          search: false,
          pageSize: 10,
          filtering: false,
        }}
        get={async (query) => {
          const queryObj: any = {
            ...query,
            filters: [
              {
                type: 'FIXED',
                by: 'type',
                value: 'service_providers',
              },
            ],
          };
          if (currentFilter !== ListFilter.ALL) {
            queryObj.filters.push({
              type: 'FIXED',
              by: 'consultation_type',
              value: currentFilter,
            });
          }
          if (range.min) {
            queryObj.filters.push({
              type: 'RANGE',
              by: 'date',
              min: range.min,
              max: range.max,
            });
          }
          await donationBillsStore.entityList.updateOptions(queryObj);
          return {
            data: toJS(donationBillsStore.entityList.data).map((item) => new DonationBill(item)),
            totalCount: donationBillsStore.entityList.meta.itemCount,
            page: donationBillsStore.entityList.meta.page - 1,
          };
        }}
        tableColumns={[
          {
            field: 'date',
            title: i18n.t('DUE_DATE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'grossRevenue',
            title: i18n.t('GROSS_REVENUE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'kashfPercentage',
            title: i18n.t('KASHF_PERCENTAGE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'netRevenue',
            title: i18n.t('NET_REVENUE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'status',
            title: i18n.t('STATUS'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
            columnProps: {
              render: (data) => (data.status === BillStatus.SETTLED
                ? (<p>{data.status}</p>) : (
                  <Button
                    onClick={() => {
                      history.push(`/donation/settlement/${data.id}`);
                    }}
                    style={{
                      background: '#3f51b5',
                      color: '#FFF',
                    }}
                  >
                    {i18n.t('SETTLE')}
                  </Button>
                )),
            },
          },
        ]}
      />
    </div>
  );
};

export const Donation = baseScreen(donation, {
  allowedRoles: ['ADMIN', 'NONE'],
});
