import {
  identity,
  pickBy,
} from 'lodash';
import {
  FieldFile,
  FieldFileCommonTypes,
  isRemoteFile,
  LocalFile,
} from 'src/components/GeneralFormView/FormField/types';
import {
  ServiceProviderFormDTO,
} from 'src/stores/backend/ServiceProvidersStore/types';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  uploadFile,
} from 'src/utils/aws-file-manager';
import {
  BaseFormViewModel,
} from '../types';
import {
  getCountryCode,
} from '../utils';

export class ServiceProviderFormViewModel extends BaseFormViewModel<ServiceProviderFormDTO> {
  id?: number;

  logo: FieldFile[];

  certificates: FieldFile[];

  serviceProviderTypeId: number;

  englishName: string;

  arabicName: string;

  isActive: boolean;

  countryCode: string;

  mobileNumber: string;

  landlineNumber: string;

  email: string;

  website: string;

  instapayUrl: string;

  location: {
    latitude: number;
    longitude: number;
  };

  governorateId: number;

  cityId: number;

  area: string;

  password?: string;

  confirmPassword?: string;

  commercialRecord: FieldFile[];

  taxId: FieldFile[];

  signupCode: string;

  constructor(data: ServiceProviderFormViewModel) {
    super();
    const {
      id,
      area,
      cityId,
      commercialRecord,
      email,
      governorateId,
      location,
      password,
      serviceProviderTypeId,
      taxId,
      website,
      arabicName,
      certificates,
      confirmPassword,
      countryCode,
      englishName,
      landlineNumber,
      logo,
      isActive,
      mobileNumber,
      signupCode,
      instapayUrl,
    } = data;
    Object.assign(this,
      {
        id,
        arabicName,
        area,
        cityId,
        commercialRecord,
        email,
        governorateId,
        location,
        password,
        serviceProviderTypeId,
        taxId,
        website,
        certificates,
        confirmPassword,
        countryCode,
        englishName,
        isActive,
        landlineNumber,
        logo,
        mobileNumber,
        signupCode,
        instapayUrl,
      });
  }

  async toDTO(): Promise<ServiceProviderFormDTO> {
    const {
      arabicName,
      area,
      certificates,
      cityId,
      commercialRecord,
      countryCode,
      email,
      englishName,
      governorateId,
      landlineNumber,
      location,
      logo,
      mobileNumber,
      password,
      serviceProviderTypeId,
      taxId,
      website,
      signupCode,
      instapayUrl,
    } = this;
    const value = new ServiceProviderFormDTO();
    value.avatar = isRemoteFile(logo[0]) ? logo[0].source : await uploadFile({
      file: (logo[0] as LocalFile).file, filePath: `avatar-${Date.now()}`,
    });
    value.commercialRecord = isRemoteFile(commercialRecord[0]) ? commercialRecord[0].source : await uploadFile({
      file: (commercialRecord[0] as LocalFile).file, filePath: `commercial-record-${Date.now()}`,
    });
    value.taxId = isRemoteFile(taxId[0]) ? taxId[0].source : await uploadFile({
      file: (taxId[0] as LocalFile).file, filePath: `tax-ID-${Date.now()}`,
    });
    value.docs = await Promise.all(certificates.map((item, index: number) => {
      if (!!item && !isRemoteFile(item)) {
        return uploadFile({
          file: item.file, filePath: `certificate-${index}-${Date.now()}`,
        });
      }
      return Promise.resolve(item?.source);
    }));
    value.instapayUrl = instapayUrl;
    value.name = englishName;
    value.serviceProviderTypeId = (serviceProviderTypeId as any)[0] as number;
    value.translations = [
      {
        languageCode: LanguagesKey.AR,
        name: arabicName,
      },
      {
        languageCode: LanguagesKey.EN,
        name: englishName,
      },
    ];
    value.phone = `${countryCode}${mobileNumber}`;
    value.landlinePhone = landlineNumber;
    value.email = email;
    value.website = website;
    value.location = {
      type: 'Point',
      coordinates: [location.longitude, location.latitude],
    };
    value.governorateId = (governorateId as any)[0] as number;
    value.cityId = (cityId as any)[0] as number;
    value.area = area;
    value.password = password as string;
    value.signupCode = signupCode;
    return pickBy(value, identity) as ServiceProviderFormDTO;
  }

  static fromDTO = (dto: any): ServiceProviderFormViewModel => {
    const countryCode = getCountryCode(dto.user.phone);
    const phone = dto.user.phone.replace(`+${countryCode}`, '');
    return {
      arabicName: dto.user.translations?.filter((el: any) => el.languageCode === LanguagesKey.AR)[0]?.name,
      englishName: dto.user.translations?.filter((el: any) => el.languageCode === LanguagesKey.EN)[0]?.name,
      id: dto.id,
      area: dto.area,
      certificates: dto.docs?.map((item: any) => ({
        source: item,
        type: FieldFileCommonTypes.IMAGE,
      })) || [],
      cityId: [dto.city?.id] as any,
      commercialRecord: dto.commercialRecord ? [{
        source: dto.commercialRecord,
        type: FieldFileCommonTypes.IMAGE,
      }] : [],
      countryCode: [`+${countryCode}`] as any,
      email: dto.user.email,
      governorateId: [dto.governorate?.id] as any,
      landlineNumber: dto.landlinePhone,
      location: dto.location,
      logo: dto.user.avatar ? [{
        source: dto.user.avatar,
        type: FieldFileCommonTypes.IMAGE,
      }] : [],
      mobileNumber: phone,
      instapayUrl: dto.instapayUrl,
      isActive: dto.isActive,
      serviceProviderTypeId: [dto.serviceProviderType.id] as any,
      taxId: dto.taxId ? [{
        source: dto.taxId,
        type: FieldFileCommonTypes.IMAGE,
      }] : [],
      website: dto.website,
      signupCode: dto.signupCode,
    } as ServiceProviderFormViewModel;
  };
}
