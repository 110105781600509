/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';
import {
  getIn,
  useFormikContext,
} from 'formik';
import React, {
  useEffect,
} from 'react';
import {
  generateInitialValues,
} from '../..';
import FieldError from '../../Utils/FieldError';
import FormFieldCondition from '../../Utils/FormFieldCondition';
import {
  ArrayFormFieldFieldValue,
  FieldPropsCommon,
} from '../types';
import {
  onChange,
} from '../utils';

type Props<T, K extends keyof T> = FieldPropsCommon & ArrayFormFieldFieldValue<T, K>;

const ArrayFormField = <FormModel, K extends keyof FormModel>(
  props: Props<FormModel, K>,
) => {
  const {
    values,
    handleChange,
    errors,
    touched,
    isSubmitting,
    setFieldTouched,
  } = useFormikContext<FormModel>();

  const {
    fieldOptions,
    disabled,
    title,
    location,
    viewMode,
  } = props;

  const value: any[] = getIn(values, location);
  const onValueChange = onChange(handleChange, location);
  const error = getIn(errors, location);
  const isTouched = getIn(touched, location);

  useEffect(() => {
    if (isSubmitting) {
      if (!Array.isArray(error)) {
        setFieldTouched(location);
      }
    }
  }, [error, isSubmitting, location, setFieldTouched]);

  const remove = (index: number) => {
    const formValues = value;
    formValues.splice(index, 1);
    onValueChange(formValues);
  };

  const swap = (index: number, direction: 'up' | 'down') => {
    const formValues = value;
    const targetIndex = direction === 'up' ? index - 1 : index + 1;
    const temp = formValues[targetIndex];
    formValues[targetIndex] = formValues[index];
    formValues[index] = temp;
    onValueChange(formValues);
  };

  if (viewMode) {
    return (
      <Card
        style={{
          margin: 8,
        }}
      >
        <CardHeader
          title={title}
        />
        <CardContent>
          {
            !value.length && (
              <Typography>No items.</Typography>
            )
          }
          {
            value.map((item, index) => (
              <Card
                style={{
                  margin: 8,
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}`}
              >
                <CardHeader
                  title={`${title} ${index + 1}`}
                />
                <CardContent>
                  <FormFieldCondition<FormModel[K], keyof FormModel[K]>
                    fieldData={fieldOptions.formData as any}
                    location={`${location}[${index}]`}
                    viewMode={viewMode}
                  />
                </CardContent>
              </Card>
            ))
          }
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      style={{
        margin: 8,
      }}
    >
      <CardHeader
        title={title}
        action={(
          <Button
            variant="contained"
            disabled={disabled}
            onClick={() => {
              onValueChange([
                ...value,
                generateInitialValues(fieldOptions.formData as any),
              ]);
              if (!Array.isArray(error)) {
                setFieldTouched(location);
              }
            }}
          >
            Add
          </Button>
)}
      />
      <CardContent>
        {
          !value.length && (
            <Typography>No items yet. Press add to create a new item.</Typography>
          )
        }
        {
          value.map((item, index) => (
            <Card
              style={{
                margin: 8,
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}`}
            >
              <CardHeader
                title={`${title} ${index + 1}`}
              />
              <CardContent>
                <FormFieldCondition<FormModel[K], keyof FormModel[K]>
                  fieldData={fieldOptions.formData as any}
                  location={`${location}[${index}]`}
                />
              </CardContent>
              <CardActions>
                <Button
                  disabled={index === 0 || disabled}
                  onClick={() => {
                    swap(index, 'up');
                  }}
                >
                  &#8593;
                </Button>
                <Button
                  disabled={(index === value.length - 1) || disabled}
                  onClick={() => {
                    swap(index, 'down');
                  }}
                >
                  &#8595;
                </Button>
                <Button
                  variant="contained"
                  disabled={disabled}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  Remove
                </Button>
              </CardActions>
            </Card>
          ))
        }
      </CardContent>
      <CardActions>
        <FieldError
          errors={error}
          touched={isTouched}
        />
      </CardActions>
    </Card>
  );
};

export default ArrayFormField;
/* eslint-enable @typescript-eslint/no-explicit-any */
