/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  BaseBackendStore,
} from '../types';
import {
  insuranceCompanies,
  insuranceCompany,
} from './requests';
import {
  InsuranceCompaniesDTO,
} from './types';
import {
  BackendStores,
} from '..';

export class InsuranceCompaniesStore extends BaseBackendStore {
  @observable private _entityList: InsuranceCompaniesDTO[] = [];

  @action public getAll = async (options: any): Promise<InsuranceCompaniesDTO[]> => {
    console.log(options);
    const response = await this.connections.backend
      .httpGet(`${insuranceCompanies}`);
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: InsuranceCompaniesDTO;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: InsuranceCompaniesDTO): Promise<void> {
    try {
      await this.connections.backend.httpPost(insuranceCompanies, data);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  }

  @action
  public async getOne(id: string): Promise<ModelOf<InsuranceCompaniesDTO>> {
    const response = await this.connections.backend.httpGet(insuranceCompany(parseInt(id, 10)));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action updateInsuranceCompany = async (id: number, data: Partial<InsuranceCompaniesDTO>) => {
    try {
      const response = await this.connections.backend.httpPut(insuranceCompany(id), data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(insuranceCompany(id));
    return Promise.resolve(response);
  }
}
