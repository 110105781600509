import {
  baseScreen,
} from 'hoc';
import React from 'react';
import {
  useHistory,
} from 'react-router';
import {
  VerificationMessage,
} from 'components';
import i18n from 'i18n-js';

const customNotificationformConfirmation: React.FC = () => {
  const history = useHistory();
  return (
    <VerificationMessage
      message={i18n.t('CUSTOM_NOTIFIVATION_CONFIRMATION_MESSAGE')}
      action={() => history.replace('/notifications')}
    />
  );
};

export const CustomNotificationformConfirmation = baseScreen(customNotificationformConfirmation, {
  allowedRoles: ['ADMIN', 'NONE'],
});
