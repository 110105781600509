import {
  DonationBillsDTO,
  BillStatus,
} from 'src/stores/backend/DonationBillStore/types';
import {
  BaseFormViewModel,
} from '../../types';

export enum ListFilter {
  ALL = 'all',
}

export class DonationBill extends BaseFormViewModel<DonationBillsDTO> {
  id?: number;

  balance: number;

  date: Date;

  email: string;

  grossRevenue: number;

  status: BillStatus;

  netRevenue: number;

  kashfPercentage: number;

  service_provider_type: string;

  constructor(data: DonationBillsDTO) {
    super();
    this.id = data.id;
    this.balance = data.balance;
    this.date = data.end;
    this.email = data.email;
    this.grossRevenue = data.grossRevenue;
    this.status = data.status;
    this.kashfPercentage = data.kashfPercentage;
    this.netRevenue = data.netRevenue;
    this.service_provider_type = data.type;
  }

  toDTO(): DonationBillsDTO {
    return { } as DonationBillsDTO;
  }
}
