import {
  sortBy,
} from 'lodash';
import {
  BaseDTO,
  Patient,
} from 'shared';
import {
  FieldFileCommonTypes,
  RemoteFile,
} from 'src/components/GeneralFormView/FormField/types';
import moment from 'moment';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  BaseFormViewModel,
} from '../types';

export enum DAY {
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
}

export enum ListFilter {
  ALL = 'all',
  RECENTLY_VIEWED = 'recently_viewed',
  UPDATED_AT = 'updatedAt',
  CREATED_AT = 'createdAt',
}
export class Doctor extends BaseDTO {
  clinics: Clinic[];

  feesMax: number;

  refundPercentageOnCancellation:number;

  isActive: boolean;

  isBanned: boolean;

  isFreeVisit: boolean;

  nationalID: string;

  isFreeCall: boolean;

  doctorId?: number;

  link: string;

  directLink: string;

  feesMin: number;

  serviceProviders: DoctorServiceProvider[];

  newSyndicateIdURL: string;

  syndicateIdStatus: SyndicateIdStatus;

  certificates: string[];

  syndicateIdURL: string;

  nationalIdFrontURL: string;

  nationalIdBackURL: string;

  grade: Grade;

  ratings: Rating[];

  schedules: Schedule[];

  memberships?: Membership[];

  website: string;

  id: number;

  taxIdURL: string;

  homeVisitFees: number;

  isFavourite: boolean;

  onlineConsultationFees: number;

  specialities: Speciality[];

  status: DoctorStatus;

  user: User;

  languages: string[];

  isInCall: boolean;

  isASAPCalls: boolean;

  syndicateNumber: string;

  lastCheckIn: Date;

  arabicName?: string;

  instapayUrl?: string;

  urgentReservationPercentage : number;

  higherPricingPercentage: number;

  static fromDTO = (dto: Doctor): DoctorsTable => ({
    status: dto.status,
    avatar: dto.user.avatar,
    code: dto.id,
    grade: dto.grade.title,
    hospital: 'hospital name',
    lastCheckIn: dto.lastCheckIn ? moment(dto.lastCheckIn).format('DD-MM-YYYY') : '',
    newSyndicateIdURL: dto.newSyndicateIdURL,
    syndicateIdStatus: dto.syndicateIdStatus,
    name: dto.user.name,
    isActive: dto.isActive,
    isBanned: dto.isBanned,
    phone: dto.user.phone,
    specialty: dto.specialities.map((sp) => sp.title).join(' - '),
    syndicateNumber: dto.syndicateNumber,
    id: dto.id,
    isComplete: dto.user.isComplete,
    link: dto.link,
    doctorId: dto.id,
    user: dto.user,
    arabicName: dto.arabicName,
    createdAt: moment(dto.user.createdAt).format('DD-MM-YYYY'),
  });
}
export class DoctorsTable {
  code: number;

  avatar: string;

  user: User;

  link: string;

  id: number;

  doctorId: number;

  name: string;

  isComplete: boolean;

  syndicateNumber: string;

  newSyndicateIdURL: string;

  syndicateIdStatus: SyndicateIdStatus;

  specialty: string;

  grade: string;

  phone: string;

  status: DoctorStatus;

  hospital: string;

  lastCheckIn: string;

  isActive: boolean;

  isBanned: boolean;

  arabicName?: string;

  createdAt: string;
  // static toDTO = (table: DoctorsTable) => table as Doctor;
}

export interface DoctorServiceProvider {
  id: number;
}
export interface User {
  avatar: string;
  averageRating: number;
  gender: string;
  id: number;
  name: string;
  bloodGroup: string;
  coordinatorName?: string;
  maritalStatus: string;
  serviceProvider: ServiceProvider;
  email: string;
  firebaseUserId: string;
  isSmoking: boolean;
  isComplete: boolean;
  isEmailVerified: boolean;
  totalConsultations: number;
  phone: string;
  isPhoneVerified: boolean;
  patient: Patient;
  appLanguage: string;
  about: string;
  translations: UserTranslation[];
  createdAt: Date;
}
export interface UserTranslation {
  id: number;
  createdAt: Date;
  description: string;
  about: string;
  name: string;
  updatedAt: Date;
  languageCode: LanguagesKey;
}

export interface ServiceProvider {
  agreeToTermsAndPolicy: boolean;
  area: string;
  commercialRecord: string;
  coordinatorName: string;
  coordinatorPhone: string;
  createdAt: Date;
  docs: string;
  id: number;
  isActive: boolean;
  landlinePhone: string;
}

export interface Speciality {
  id: string;
  title: string;
}
export interface Rating {
  doctorId: number;
  requestId: number;
  rating: number;
  callRating?: number;
  comment?: string;
  callComment?: string;
  createdAt?: Date;
  raterUser?: User;
}

export interface Clinic {
  id: number;
  name: string;
  countryCode: number;
  mobileNumber: string;
  email: string;
  landlineNumber: string;
  fees: string;
  governorate: string;
  city: string;
  area: string;
  location: {
    latitude: number;
    longitude: number;
  };
  address: string;
  isFreeClinic?: boolean;
}

export interface Schedule {
  id?: number;
  fees: number;
  type: ScheduleType,
  workingDays: WorkingDay[];
}
export interface Grade {
  id: number;
  title: string;
}

export interface Membership {
  id: number;
  title: string;
  discountValue: number;
  validUntil?: Date;
}

export interface Nationality {
  id: number;
  name: string;
}

export enum ScheduleType {
  HOME_VISIT = 'Home Visit',
  ONLINE_CONSULTATION = 'Online Consultation',
  CLINIC = 'Clinic',
}
export enum DoctorStatus {
  'ONLINE' = 'Online',
  'OFFLINE' = 'Offline',
  'BUSY' = 'Busy',
}

export enum SyndicateIdStatus {
  NOT_APPLIED = 'none',
  NEW = 'new',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface WorkingDay {
  id: number;
  day: DAY;
  to: string;
  from: string;
  isActive: boolean;
  containsBreak: boolean;
  breakFrom?: string;
  breakTo?: string;
  order: number;
}
export class DoctorModelView extends BaseFormViewModel<Doctor> {
  id?: number;

  clinics?: Clinic[];

  grade?: Grade;

  homeVisitFees: number;

  scheduleHomeVisitFees: number;

  isASAPCalls: boolean;

  isFreeCall: boolean;

  nationalIdFrontURL: RemoteFile[];

  syndicateNumber: string;

  name: string;

  homeVisitSchedule: WorkingDay[];

  onlineConsultationSchedule: WorkingDay[];

  homeVisitScheduleId?: number;

  onlineConsultationScheduleId?: number;

  clinicScheduleId?: number;

  clinicSchedule: WorkingDay[];

  isFreeVisit: boolean;

  certificateId: RemoteFile[];

  syndicateIdURL: RemoteFile[];

  website: string;

  taxId: RemoteFile[];

  isInCall: boolean;

  languages?: string[];

  onlineConsultationFees: number;

  scheduleOnlineConsultationFees: number;

  scheduleClinicFees: number;

  nationalIdBackURL: RemoteFile[];

  ratings?: Rating[];

  specialities?: string;

  status: DoctorStatus;

  schedules: Schedule[];

  avatar: RemoteFile[];

  email: string;

  phone: string;

  aboutEn: string;

  aboutAr: string;

  constructor(dto: Doctor) {
    super();

    this.name = dto.user.name;
    if (dto.nationalIdBackURL) {
      this.nationalIdBackURL = [{
        source: dto.nationalIdBackURL,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
    if (dto.nationalIdFrontURL) {
      this.nationalIdFrontURL = [{
        source: dto.nationalIdFrontURL,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
    this.onlineConsultationFees = dto.onlineConsultationFees;
    this.phone = dto.user.phone;
    this.status = dto.status;
    if (dto.taxIdURL) {
      this.taxId = [{
        source: dto.taxIdURL,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
    if (dto.syndicateIdURL) {
      this.syndicateIdURL = [{
        source: dto.syndicateIdURL,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
    this.syndicateNumber = dto.syndicateNumber;
    this.website = dto.website;
    const homeVisitSchedule = dto.schedules
      ?.filter((schedule) => schedule.type === ScheduleType.HOME_VISIT)[0];
    const onlineConsultationSchedule = dto.schedules
      ?.filter((schedule) => schedule.type === ScheduleType.ONLINE_CONSULTATION)[0];
    const clinicSchedule = dto.schedules
      ?.filter((schedule) => schedule.type === ScheduleType.CLINIC)[0];
    if (homeVisitSchedule) {
      this.homeVisitScheduleId = homeVisitSchedule?.id;
      this.homeVisitSchedule = sortBy(homeVisitSchedule.workingDays, (o) => o.order);
      this.scheduleHomeVisitFees = homeVisitSchedule.fees;
    }
    if (onlineConsultationSchedule) {
      this.onlineConsultationSchedule = sortBy(onlineConsultationSchedule.workingDays, (o) => o.order);
      this.onlineConsultationScheduleId = onlineConsultationSchedule?.id;
      this.scheduleOnlineConsultationFees = onlineConsultationSchedule.fees;
    }
    if (clinicSchedule) {
      this.clinicScheduleId = clinicSchedule?.id;
      this.clinicSchedule = sortBy(clinicSchedule.workingDays, (o) => o.order);
      this.scheduleClinicFees = clinicSchedule.fees;
    }
    if (dto.user.avatar) {
      this.avatar = [{
        source: dto.user.avatar,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
    if (dto.certificates) {
      this.certificateId = dto.certificates.map((item) => ({
        source: item,
        type: FieldFileCommonTypes.IMAGE,
      }));
    }
    this.email = dto.user.email;
    this.grade = dto.grade;
    this.homeVisitFees = dto.homeVisitFees;
    this.id = dto.doctorId;
    this.languages = dto.languages;
    this.specialities = dto.specialities.map((item) => item.title).join(' - ');
  }

  toDTO() {
    return {} as Doctor;
  }
}
