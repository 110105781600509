import * as yup from 'yup';

function isValidURL(str: string | undefined | null) {
  if (str === null || str === undefined) {
    return false;
  }
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

const configValidationSchema = yup.object({
  REACT_APP_BASE_URL: yup
    .string()
    .required()
    .test('url', 'URL provided is not valid!', (value) => isValidURL(value)),
}).required();

export class Settings {
  static config: yup.TypeOf<typeof configValidationSchema>;

  static init() {
    const config = Object
      .entries(process.env || {})
      .filter(([key]) => key.startsWith('REACT_APP_'))
      .reduce<Record<string, any>>((acc, [key, val]) => ({
      ...acc,
      [key]: val,
    }), {});
    try {
      Settings.config = configValidationSchema.validateSync(config);
    } catch (error) {
      Settings.config = config as any;
    }
  }
}
