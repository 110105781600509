import {
  ComplaintsDTO,
} from 'src/stores/backend/ComplaintsStore/types';
import {
  BaseFormViewModel,
} from '../types';

export class Complaint extends BaseFormViewModel<any> {
  id?: number;

  status: string;

  description: string;

  category: string;

  user_name: string;

  phone: string;

  email: string;

  constructor(data: any) {
    super();
    this.id = data.id;
    this.status = data.status;
    this.description = data.description;
    this.user_name = data.user.name;
    this.phone = data.user.phone;
    this.email = data.user.email;
    this.category = data.category.translations[0].title;
  }

  toDTO(): ComplaintsDTO {
    return { } as ComplaintsDTO;
  }
}
