/* eslint-disable */
import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useStores,
} from 'hooks';
import {
  baseScreen,
} from 'hoc';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import GeneralFormFields,
{
  RequestStatus,
  DateTimeFormFieldTypes,
} from 'src/components/GeneralFormView/types';
import * as Yup from 'yup';
import {
  useHistory,
  useParams,
} from 'react-router';
import ComponentWithLoading from 'src/components/ComponentWithLoading';
import i18n from 'i18n-js';
import {
  CountryCodes,
  LoadingState,
} from 'utils';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  toJS,
} from 'mobx';
import {
  Request,
  RequestTable,
} from 'src/stores/backend/RequestsStore/types';
import moment from 'moment';
import GeneralTableView from 'src/components/GeneralTableView';
import {
  SearchObj,
} from 'src/stores/backend/DoctorsStore/types';
import SearchFilterDropdown from 'src/components/SearchFilterComponent';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {
  uploadFile,
} from 'src/utils/aws-file-manager';
import {
  isRemoteFile,
} from 'src/components/GeneralFormView/FormField/types';
import {
  ONLY_ARABIC_LETTERS_REGEX,
  ONLY_LETTERS_REGEX,
  ONLY_NUMBERS_REGEX,
} from '../utils';
import {
  DoctorModelView,
  Languages,
  SCREEN_MODES,
} from './types';
import {
  Doctor, Membership,
} from '../doctors/types';
import Clinic from './Clinic';

const doctorDetails: React.FC = () => {
  const history = useHistory();
  const {
    backend: {
      doctors,
      requests: RequestsStore,
      grades,
      specialities,
      memberships,
      clinics,
    },
    ui: {
      localization,
    },
  } = useStores();
  const [
    selectedDoctor,
    setSelectedDoctor,
  ] = useState<Doctor>();
  const [
    requestsFilterType,
    setRequestsFilterType,
  ] = useState('');
  const [
    isLoading,
    setLoading,
  ] = useState<RequestStatus>(RequestStatus.IDLE);
  const [
    gradesList,
    setGradesList,
  ] = useState<{title: string, value: string}[]>([]);
  const [
    specialitiesList,
    setSpecialitiList,
  ] = useState<{title: string, value: string}[]>([]);
  const [
    membershipsList,
    setMembershipsList,
  ] = useState<Membership[]>([]);
  const {
    id,
    mode,
  } = useParams() as any;
  const getGrades = async () => {
    const list = await grades.getAll({});
    setGradesList(list.map((item) => {
      const {
        title,
      } = item.translations
        .filter((el) => el.languageCode === localization.currentLanguage.key)[0];
      return {
        title,
        value: item?.id?.toString() as string,
      };
    }));
  };

  const getSpecialities = async () => {
    const list = await specialities.getAll({});
    setSpecialitiList(list.map((item) => {
      const {
        title,
      } = item.translations
        .filter((el) => el.languageCode === localization.currentLanguage.key)[0];
      return {
        title,
        value: item?.id?.toString() as string,
      };
    }));
  };

  const getMemberships = async ()=>{
    const list =await memberships.getAll({})
    const membershipsData=list.map((item)=>{
      const {
        title
      }=item.translations
      .filter((el) => el.languageCode === localization.currentLanguage.key)[0];
      return {
        id: item.id,
        title: title,
        discountValue: item.discountValue,
        validUntil: item.validUntil,
      }
    }) as Membership[];
    setMembershipsList(membershipsData)
  };

  const [
    doctorId,
  ] = useState(Number.parseInt(id, 10));
  useEffect(() => {
    const getData = async () => {
      getGrades();
      getSpecialities();
      getMemberships();
      
      if (doctorId) {
        const doctor = await doctors.getOne(doctorId.toFixed());
        setSelectedDoctor(doctor);
      }
    };
    getData();
  }, []);
  const ref = useRef<any|null>(null);
  const clinicsForm = new FormFieldsBuilder<DoctorModelView, DoctorModelView['clinics']>()
    .addField('name', {
      title: i18n.t('NAME'),
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
      fieldOptions: {},
    })
    .addField('countryCode', {
      title: 'Country Code',
      type: GeneralFormFields.SELECT_FIELD,
      validationSchema: Yup
        .array()
        .of(Yup.string().required())
        .min(1, i18n.t('REQUIRED_FIELD'))
        .required(),
      fieldOptions: {
        isMultiple: false,
        items: CountryCodes.map((country) => ({
          title: `${country.name} (${country.dial_code})`,
          value: country.dial_code,
        })),
      },
    })
    .addField('mobileNumber', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('MOBILE_NUMBER'),
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')).max(10, i18n.t('INVALID_FIELD'))
        .matches(ONLY_NUMBERS_REGEX, i18n.t('ACCEPTS_NUMBERS_ONLY')) as any,
      fieldOptions: {},
    })
    .addField('email', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('SECRETERY_EMAIL'),
      validationSchema: Yup.string().email(i18n.t('EMAIL_VALIDATION')).required(i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {},
    })
    .addField('address', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('ADDRESS'),
      validationSchema: Yup.string().notRequired() as any,
      fieldOptions: {},
    })
    .addField('location', {
      type: GeneralFormFields.LOCATION_FIELD,
      title: i18n.t('ADDRESS'),
      validationSchema: Yup.object().notRequired() as any,
      fieldOptions: {},
    });

  const membershipForm=new FormFieldsBuilder<DoctorModelView,DoctorModelView['membership']>()
    .addField('id',{
      type:GeneralFormFields.SELECT_FIELD,
      validationSchema: Yup.array().default(null).nullable(),
      title: i18n.t('MEMBERSHIP_DISCOUNTS'),
      fieldOptions:{
        isMultiple: false,
        items:membershipsList.map(membership=>({
        title:membership.title,
        value:membership.id
        }))
      }
    })
    .addField('discountValue',{
      type:GeneralFormFields.INPUT_FIELD,
      title:'Discount',
      validationSchema: Yup.number().required(i18n.t('REQUIRED_FIELD')),
      fieldOptions: {},
    })
    .addField('validUntil', {
      type: GeneralFormFields.DATE_TIME_FIELD,
      title: 'Valid Until',
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')).nullable() as any,
      fieldOptions: {
        type: DateTimeFormFieldTypes.DATE,
      },
    });

  const form = new FormFieldsBuilder<DoctorModelView>()
    .addField('avatar', {
      title: i18n.t('IMAGE'),
      type: GeneralFormFields.FILE_PICKER,
      validationSchema: Yup.array().required().length(1, i18n.t('FILE_PICKER_FILED_LENGTH')) as any,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addField('countryCode', {
      title: 'Country Code',
      type: GeneralFormFields.SELECT_FIELD,
      validationSchema: Yup
        .array()
        .of(Yup.string().required())
        .min(1, i18n.t('REQUIRED_FIELD'))
        .required(),
      fieldOptions: {
        isMultiple: false,
        items: CountryCodes.map((country) => ({
          title: `${country.name} (${country.dial_code})`,
          value: country.dial_code,
        })),
      },
    })
    .addField('phone', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('MOBILE_NUMBER'),
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD'))
        .max(10, i18n.t('INVALID_FIELD'))
        .matches(ONLY_NUMBERS_REGEX, i18n.t('ACCEPTS_NUMBERS_ONLY')) as any,
      fieldOptions: {},
    })
    .addField('name', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('NAME'),
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD'))
        .matches(ONLY_LETTERS_REGEX, i18n.t('ACCEPTS_LETTERS_ONLY')) as any,
      fieldOptions: {},
    })
    .addField('arabicName', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('ARABIC_NAME'),
      validationSchema: Yup.string().notRequired().nullable()
        .matches(ONLY_ARABIC_LETTERS_REGEX, i18n.t('ACCEPTS_ARABIC_LETTERS_ONLY')) as any,
      fieldOptions: {},
    })
    .addField('syndicateNumber', {
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.string().notRequired().nullable()
        .matches(ONLY_NUMBERS_REGEX, i18n.t('INVALID_SYNDICATE_ID')) as any,
      title: i18n.t('SYNDICATE_ID'),
      fieldOptions: {},
    })
    .addField('refundPercentageOnCancellation', {
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.string().notRequired().nullable()
        .matches(ONLY_NUMBERS_REGEX, i18n.t('INVALID_SYNDICATE_ID'))
        .max(2, i18n.t('INVALID_FIELD')) as any,
      title: i18n.t('REFUND_PERCENTAGE'),
      fieldOptions: {},
    })
    .addField('nationalId', {
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.string().notRequired().nullable().length(14)
        .matches(ONLY_NUMBERS_REGEX, i18n.t('INVALID_NATIONAL_ID')) as any,
      title: i18n.t('NATIONAL_ID'),
      fieldOptions: { },
    })
    .addField('grade', {
      type: GeneralFormFields.SELECT_FIELD,
      title: i18n.t('GRADE'),
      validationSchema: Yup.array().min(1, i18n.t('REQUIRED_FIELD')) as any,
      initialValue: selectedDoctor ? [selectedDoctor.grade.id.toString() as string] : [],
      fieldOptions: {
        isMultiple: false,
        items: gradesList,
      },
    })
    .addField('specialities', {
      type: GeneralFormFields.SELECT_FIELD,
      title: i18n.t('SPECIALITIES'),
      validationSchema: Yup.array().min(1, i18n.t('REQUIRED_FIELD')) as any,
      initialValue: selectedDoctor?.specialities as any || [],
      fieldOptions: {
        isMultiple: true,
        items: specialitiesList,
      },
    })
    .addField('email', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('EMAIL'),
      validationSchema: Yup.string().email(i18n.t('INVALID_FIELD')).required(i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {},
    })
    .addField('website', {
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.string().url(i18n.t('INVALID_WEBSITE')).notRequired().nullable() as any,
      title: i18n.t('WEBSITE'),
      fieldOptions: {},
    })
    .addField('instapayUrl',{
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.string().notRequired().nullable() as any,
      title: i18n.t('INSTAPAY'),
      fieldOptions: {},
    })
    .addField('higherPricingPercentage',{
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.number().notRequired().nullable() as any,
      title: 'Higher Pricing Percentage',
      fieldOptions: {},
    })
    .addField('urgentReservationPercentage',{
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.number().notRequired().nullable() as any,
      title: 'Urgent Reservation Percentage',
      fieldOptions: {},
    })
    .addField('languages', {
      type: GeneralFormFields.SELECT_FIELD,
      title: i18n.t('LANGUAGES'),
      validationSchema: Yup.array().required(i18n.t('REQUIRED_FIELD')) as any,
      fieldOptions: {
        isMultiple: true,
        items: [
          {
            title: 'عربي',
            value: Languages.AR,
          },
          {
            title: 'English',
            value: Languages.EN,
          },
          {
            title: 'Français',
            value: Languages.FR,
          },
          {
            title: 'Español',
            value: Languages.ES,
          },
          {
            title: 'italiano',
            value: Languages.IT,
          },
        ],
      },
    })
    .addField('certificateId', {
      type: GeneralFormFields.FILE_PICKER,
      title: i18n.t('CERTIFICATE_ID'),
      validationSchema: mode !== SCREEN_MODES.EDIT ? Yup.array().length(1, i18n.t('FILE_PICKER_FILED_LENGTH')) as any : Yup.array().notRequired(),
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: true,
      },
    })
    .addField('syndicateIdURL', {
      type: GeneralFormFields.FILE_PICKER,
      title: i18n.t('SYNDICATE_ID'),
      validationSchema: mode !== SCREEN_MODES.EDIT ? Yup.array().length(1, i18n.t('FILE_PICKER_FILED_LENGTH')) as any : Yup.array().notRequired(),
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addField('nationalIdFrontURL', {
      type: GeneralFormFields.FILE_PICKER,
      title: i18n.t('NATIONAL_ID_FRONT'),
      validationSchema: mode !== SCREEN_MODES.EDIT ? Yup.array().length(1, i18n.t('FILE_PICKER_FILED_LENGTH')) as any : Yup.array().notRequired(),
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addField('nationalIdBackURL', {
      type: GeneralFormFields.FILE_PICKER,
      title: i18n.t('NATIONAL_ID_BACK'),
      validationSchema: mode !== SCREEN_MODES.EDIT ? Yup.array().length(1, i18n.t('FILE_PICKER_FILED_LENGTH')) as any : Yup.array().notRequired(),
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addField('taxId', {
      type: GeneralFormFields.FILE_PICKER,
      validationSchema: mode !== SCREEN_MODES.EDIT ? Yup.array().length(1, i18n.t('FILE_PICKER_FILED_LENGTH')) as any : Yup.array().notRequired(),
      title: i18n.t('TAX_ID'),
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addField('aboutEn', {
      type: GeneralFormFields.TEXT_AREA,
      title: i18n.t('ABOUT_DOCTOR_EN'),
      validationSchema: Yup.string().max(500, i18n.t('ABOUT_LENGTH_VALIDATION')).notRequired() as any,
      fieldOptions: {},
    })
    .addField('aboutAr', {
      type: GeneralFormFields.TEXT_AREA,
      title: i18n.t('ABOUT_DOCTOR_AR'),
      validationSchema: Yup.string().max(500, i18n.t('ABOUT_LENGTH_VALIDATION')).notRequired() as any,
      fieldOptions: {},
    });

  if (mode !== SCREEN_MODES.EDIT) {
    form.addField('clinics', {
      title: i18n.t('CLINICS'),
      type: GeneralFormFields.FIELD_ARRAY,
      validationSchema: Yup.array().notRequired() as any,
      fieldOptions: {
        formData: {
          title: i18n.t('NAME'),
          type: GeneralFormFields.NESTED_FORM,
          fieldOptions: {
            formData: clinicsForm.formFields,
          },
        },
      },
    })
    .addField('memberships',{
      title: 'Memberships',
      type: GeneralFormFields.FIELD_ARRAY,
      validationSchema: Yup.array().notRequired() as any,
      fieldOptions: {
        formData: {
          title: i18n.t('NAME'),
          type: GeneralFormFields.NESTED_FORM,
          fieldOptions: {
            formData: membershipForm.formFields,
          },
        },
      },
    })
  }
  return (
    <div>
      <ComponentWithLoading
        isLoading={(doctors.selectedEntity.loadingState === LoadingState.LOADING && !!doctorId)
          || grades.entityList.loadingState === LoadingState.LOADING}
      >
        <div>
          {(!!selectedDoctor && !selectedDoctor.isActive && mode === SCREEN_MODES.VIEW) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 12,
            }}
          >
            <Button
              style={{
                background: '#DDD',
              }}
              onClick={async () => {
                await doctors.updateDoctor(selectedDoctor.id, {
                  isActive: true,
                });
                const doctor = await doctors.getOne(selectedDoctor.id.toString());
                setSelectedDoctor(doctor);
              }}
            >
              {i18n.t('ACTIVATE')}
            </Button>
          </div>
          )}

          {((selectedDoctor && selectedDoctor.user) || !doctorId) && (
          <GeneralFormView<any, DoctorModelView>
            title={i18n.t('DOCTOR')}
            identifier="id"
            defaultLang={mode === SCREEN_MODES.VIEW ? '' : 'en'}
            viewMode={mode === SCREEN_MODES.VIEW}
            createOrUpdateStatus={isLoading}
            otherLanguages={[]}
            // otherLanguages={['ar']}
            formData={form.formFields}
            isFetchSuccessful
            update={async (ID: any, data) => {
              try {
                setLoading(RequestStatus.LOADING);
                const values: any = data;
                const value: any = {
                  name: data.name,
                  arabicName: data.arabicName,
                  syndicateNumber: data.syndicateNumber,
                  phone: `${data.countryCode}${data.phone}`,
                  email: data.email,
                  specialityIds: values.specialities.map((el: string) => Number.parseInt(el, 10)),
                  gradeId: Number.parseInt(values?.grade[0], 10),
                  refundPercentageOnCancellation: Number.parseFloat(values?.refundPercentageOnCancellation),
                  website: data.website,
                  languages: data.languages,
                  avatar: data.avatar[0]?.source,
                  nationalID: data.nationalId,
                  syndicateIdURL: data.syndicateIdURL[0]?.source,
                  nationalIdBackURL: data.nationalIdBackURL[0]?.source,
                  nationalIdFrontURL: data.nationalIdFrontURL[0]?.source,
                  taxIdURL: data.taxId[0]?.source,
                  certificates: data.certificateId.map((item) => item.source),
                };
                if (!!data.avatar[0] && !isRemoteFile(data.avatar[0])) {
                  value.avatar = await uploadFile({
                    file: values.avatar[0].file, filePath: `avatar-${Date.now()}`,
                  });
                }
                if (!!data.syndicateIdURL[0] && !isRemoteFile(data.syndicateIdURL[0])) {
                  value.syndicateIdURL = await uploadFile({
                    file: values.syndicateIdURL[0].file, filePath: `syndicateIdURL-${Date.now()}`,
                  });
                }
                if (!!data.nationalIdFrontURL[0] && !isRemoteFile(data.nationalIdFrontURL[0])) {
                  value.nationalIdFrontURL = await uploadFile({
                    file: values.nationalIdFrontURL[0].file, filePath: `nationalIdFrontURL-${Date.now()}`,
                  });
                }
                if (!!data.nationalIdBackURL[0] && !isRemoteFile(data.nationalIdBackURL[0])) {
                  value.nationalIdBackURL = await uploadFile({
                    file: values.nationalIdBackURL[0].file, filePath: `nationalIdBackURL-${Date.now()}`,
                  });
                }
                if (!!data.taxId[0] && !isRemoteFile(data.taxId[0])) {
                  value.taxIdURL = await uploadFile({
                    file: values.taxId[0].file, filePath: `taxId-${Date.now()}`,
                  });
                }
                value.certificates = await Promise.all(values.certificateId.map((item: any, index: number) => {
                  if (!!item && !isRemoteFile(item)) {
                    return uploadFile({
                      file: item.file, filePath: `certificateId-${index}-${Date.now()}`,
                    });
                  }
                  return Promise.resolve(item?.source);
                }));
                value.instapayUrl = data.instapayUrl;
                value.translations = [
                  {
                    languageCode: Languages.EN,
                    name: values.name,
                    about: data.aboutEn,
                  },
                  {
                    languageCode: Languages.AR,
                    name: values.name,
                    about: data.aboutAr,
                  },
                ];
                if (!Number.isNaN(doctorId)) {
                  await doctors.updateDoctor(doctorId, value);
                  history.replace('/doctors/form-confirmation');
                }
                history.replace('/doctors/form-confirmation');
                setLoading(RequestStatus.SUCCESS);
                return Promise.resolve();
              } catch (error) {
                setLoading(RequestStatus.FAILED);
                return Promise.reject(error);
              }
            }}
            create={async (data) => {
              try {
                setLoading(RequestStatus.LOADING);
                const dr = await DoctorModelView.formatObject(data);
                //console.log(dr)
                const createdDoctorId = await doctors.create(dr);
                const drClinics:any = data.clinics;
                const drMemberships = data.memberships;
                const clinicsRequests=
                !!drClinics?
                drClinics.map(async (item: any) => {
                  await clinics.create({
                    mobileNumber: `+${item.countryCode}${item.mobileNumber}`,
                    name: item.name,
                    address: item.address,
                    location: item.location,
                    fees: 0,
                    doctorId: createdDoctorId,
                  } as any);
                  return Promise.resolve(1);
                }):[];
                const membershipsRequests=
                !!drMemberships?
                drMemberships.map(async (item) => {
                  await memberships.linkDoctor(
                      item.id,
                      doctorId,
                      {
                        discountValue:Number(item.discountValue),
                        validUntil:item.validUntil
                      }
                  );
                  return Promise.resolve(1);
                }):[];
                await Promise.all([...clinicsRequests,...membershipsRequests]);
                setLoading(RequestStatus.SUCCESS);
                history.push('/doctors/form-confirmation');
                return Promise.resolve(1);
              } catch (error) {
                console.log(error);
                setLoading(RequestStatus.FAILED);
                return Promise.reject(error);
              }
            }}
            get={async () => {
              let data: any = {};
              if (selectedDoctor) {
                data = new DoctorModelView(selectedDoctor);
                data.grade = mode === SCREEN_MODES.VIEW ? [selectedDoctor.grade.title] : data.grade;
                data.specialities = selectedDoctor.specialities.map((item) => {
                  if (mode === SCREEN_MODES.VIEW) {
                    return item.title;
                  }
                  return item.id?.toString();
                });
              }
              return Promise.resolve(data);
            }}
          />
          )}
          {(!!selectedDoctor && selectedDoctor.clinics?.length > 0 && mode === SCREEN_MODES.VIEW) && (
          <div
            style={{
              paddingTop: 20,
              paddingBottom: 40,
            }}
          >
            <Typography
              style={{
                background: 'grey',
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 10,
                paddingLeft: 10,
                marginBottom: 20,
              }}
            >
              {i18n.t('CLINICS_DETAILS')}
            </Typography>
            <div
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              {selectedDoctor.clinics.map((clinic) => (
                <Clinic
                  clinic={clinic}
                />
              ))}
            </div>
          </div>
          )}
          {(!!selectedDoctor && (mode === SCREEN_MODES.VIEW)) && (
            <>
              <SearchFilterDropdown
                label={i18n.t('STATUS')}
                style={{
                  margin: 16,
                }}
                searchFilterDropdown={{
                  filters: [
                    {
                      name: i18n.t('ALL'),
                      value: 'all',
                    },
                    {
                      name: i18n.t('HOME_VISITS'),
                      value: 'Home Visit',
                    }, {
                      name: i18n.t('ONLINE_CONSULTATION'),
                      value: 'Online Consultation',
                    },
                    {
                      name: i18n.t('CLINICS'),
                      value: 'Clinic',
                    },
                  ],
                  onChange: (val) => {
                    setRequestsFilterType(val);
                    ref.current?.onQueryChange();
                  },
                  value: 'all',
                  initialValue: 'all',
                }}
              />
              <GeneralTableView<RequestTable>
                tableProps={{
                  title: i18n.t('ACTIVITY_LOGS'),
                  tableRef: ref,
                  actions: [
                    {
                      isFreeAction: true,
                      icon: ImportExportIcon,
                      onClick: () => {
                        window.open(RequestsStore.download(), '_blanck');
                      },
                      tooltip: i18n.t('EXPORT_DATA'),
                    },
                  ],
                }}
                options={{
                  search: false,
                  pageSize: 10,
                  draggable: false,
                  filtering: false,
                }}
                get={async (query) => {
                  const filter: SearchObj = {
                    ...RequestsStore.filter,
                    filters: [
                      {
                        by: 'doctor',
                        type: 'FIXED',
                        value: selectedDoctor?.id.toString(),
                      },
                      {
                        by: 'date',
                        type: 'RANGE',
                        min: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                        max: moment().format('YYYY-MM-DD'),
                      },
                    ],
                  };
                  if (query.orderBy?.field) {
                    filter.sort = {
                      by: query.orderBy.field,
                      type: query.orderDirection.toUpperCase() as any,
                    };
                  }
                  await RequestsStore.updateFilter(filter);
                  if (requestsFilterType === 'all') {
                    const newFilter = {
                      ...filter,
                      filters: filter.filters?.filter((item) => item.by !== 'consultation_type'),
                    };
                    RequestsStore.updateFilter(newFilter);
                  } else {
                    RequestsStore.updateFilter({
                      ...filter,
                      filters: [...filter.filters as any, {
                        by: 'consultation_type',
                        value: requestsFilterType,
                        type: 'FIXED',
                      }],
                    });
                  }
                  const {
                    data,
                  } = toJS(await RequestsStore.getAll(filter) as any);
                  const requests = data.map((item: any) => Request.toTableData(item));
                  const len = requests.length;
                  return Promise.resolve({
                    data: requests,
                    totalCount: len,
                    page: 0,
                  });
                }}
                tableColumns={[
                  {
                    field: 'consultation_type',
                    title: i18n.t('TYPE'),
                    type: TableColumnTypes.STRING,
                    filterType: FilterTypes.VALUE,
                  },
                  {
                    field: 'date',
                    title: i18n.t('DATE'),
                    type: TableColumnTypes.STRING,
                  },
                  {
                    field: 'time',
                    title: i18n.t('TIME'),
                    type: TableColumnTypes.STRING,
                  },
                  {
                    field: 'status',
                    title: i18n.t('STATUS'),
                    type: TableColumnTypes.STRING,
                    filterType: FilterTypes.VALUE,
                  },
                  {
                    field: 'startedAt',
                    title: i18n.t('STARTED_AT'),
                    type: TableColumnTypes.STRING,
                    filterType: FilterTypes.VALUE,
                  },
                  {
                    field: 'finishedAt',
                    title: i18n.t('FINISHED_AT'),
                    type: TableColumnTypes.STRING,
                    filterType: FilterTypes.VALUE,
                  },
                ]}
              />
            </>
          )}
        </div>
      </ComponentWithLoading>
    </div>
  );
};
export const DoctorDetails = baseScreen(doctorDetails, {
  allowedRoles: ['ADMIN', 'NONE'],
});
