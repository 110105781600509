export interface Props {
  columns: TableColumn[];
}

export interface TableColumn {
  id?: string;
  day: DAY;
  from: string;
  to: string;
  breakFrom: string;
  breakTo: string;
  isActive: boolean;
}

export enum DAY {
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
}

export const days = [
  DAY.Saturday,
  DAY.Sunday,
  DAY.Monday,
  DAY.Tuesday,
  DAY.Wednesday,
  DAY.Thursday,
  DAY.Friday,
];

export enum ScheduleKeys {
  FROM = 'from',
  TO ='to',
  BREAK_FROM = 'breakFrom',
  BREAK_TO = 'breakTo',
}
