/* eslint-disable */
import { BaseDTO } from "shared";
import { LanguagesKey } from "src/translations/types";

export class NationalitiesDTO extends BaseDTO {
    id?: number;
    isWithHighPricing: boolean;
    translations: NatinalityTranslation[];
}

export class NatinalityTranslation {
    createdAt?: Date;
    id?: number;
    languageCode: LanguagesKey;
    name: string;
    updatedAt?: Date;
}