import {
  ExtendedAxiosRequestConfig,
  ExtendedAxiosResponse,
} from 'connections';
import {
  observable,
  action,
  computed,
} from 'mobx';
import {
  persist,
} from 'mobx-persist';
import {
  BackendStores,
} from '..';
import {
  BaseBackendStore,
} from '../types';
import {
  LoginModel,
  User,
} from './types';
import {
  refresh,
  loginURL,
  currentUserURL,
} from './requests';

export class AuthStore extends BaseBackendStore {
  @persist @observable private _accessToken: string = '';

  @persist @observable private _refreshToken: string = '';

  @persist @observable private _userId: number = 0;

  @observable public currentUser: User;

  updateTokenHeader: (token: string) => void = () => null;

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  getAccessToken = () => this._accessToken;

  getRefreshToken = () => this._refreshToken;

  get userId() {
    return this._userId;
  }

  @computed get isLoggedIn() {
    return !!this._accessToken;
  }

  @action setAccessToken(token: string) {
    this._accessToken = token;
    this.connections.backend.updateTokenHeader(token);
  }

  @action setRefreshToken(token: string) {
    this._refreshToken = token;
  }

  @action refresh = async (config?: ExtendedAxiosRequestConfig): Promise<ExtendedAxiosResponse> => {
    try {
      const response = await this.connections.backend.httpPost(refresh, {
        refreshToken: this._refreshToken,
      }, config);
      this.setAccessToken(response.accessToken);
      this.setRefreshToken(response.refreshToken);
      return Promise.resolve(response as any);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  @action login = async (data: LoginModel) => {
    try {
      const response = await this.connections.backend.httpPost(loginURL, data);
      this.setAccessToken(response.token.accessToken);
      this.setRefreshToken(response.token.refreshToken);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error?.response.data.message);
    }
  };

  @action getUser = async () => {
    try {
      const response = await this.connections.backend.httpGet(currentUserURL);
      this.currentUser = response;
      return 1;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  @action logout() {
    this._accessToken = '';
    this._refreshToken = '';
    this._userId = 0;
    this.parent.users.setRole('NONE');
  }
}
