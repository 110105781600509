import {
  Common,
} from './common';
import {
  Components,
} from './components';
import {
  Screens,
} from './screens';

export class Images {
  common = new Common();

  components = new Components();

  screens = new Screens();
}
