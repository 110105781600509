/* eslint-disable */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  BaseBackendStore,
} from '../types';
import {
  nationalities,
  nationality
} from './requests'
import {
  NationalitiesDTO
} from './types'
import {
  BackendStores,
} from '..';

export class NationalitiesStore extends BaseBackendStore {
    @observable private _entityList: NationalitiesDTO[] = [];

    @action public getAll = async (options: any): Promise<NationalitiesDTO[]> => {
        console.log(options);
        const response = await this.connections.backend
            .httpGet(`${nationalities}`);
        return response;
    }

    @observable entityList = new ListBackendEntity(
        this,
        '_entityList',
        this.getAll,
    );

    constructor(
        public parent: BackendStores,
    ) {
        super();
        this.makeObservable();
        this.registerObservableDrivers();
    };

    @observable private _selectedEntity: NationalitiesDTO;

    @observable selectedEntity = new BackendEntity(
        this,
        '_selectedEntity',
        this.getOne,
    );

    @action
    public async create(data: Partial<NationalitiesDTO>): Promise<void> {
        try {
            await this.connections.backend.httpPost(nationalities, data);
            return Promise.resolve();
        } catch (error: any) {
            return Promise.reject(error.response.data.message);
        }
    }

    @action
    public async getOne(id: string): Promise<ModelOf<NationalitiesDTO>> {
        const response = await this.connections.backend.httpGet(nationality(parseInt(id, 10)));
        this.selectedEntity = response;
        return Promise.resolve(response);
    }

    @action update = async (id: number, data: Partial<NationalitiesDTO>) => {
        try {
            const response = await this.connections.backend.httpPut(nationality(id), data);
            return Promise.resolve(response);
        } catch (error: any) {
            return Promise.reject(error.response.data.message);
        }
    };

    @action
    public async delete(id: number): Promise<void> {
        const response = await this.connections.backend
            .httpDelete(nationality(id));
        return Promise.resolve(response);
    }
}