import {
  NavigationContainer,
} from 'navigation';
import React,
{
  useEffect,
  useState,
} from 'react';
import './styles.css';
import {
  ConnectionsProvider,
  LocalizationProvider,
  ProviderBridge,
  StoresProvider,
} from 'components';
import {
  Settings,
} from 'settings';
import {
  SnackbarProvider,
} from 'notistack';

function App() {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    Settings.init();
    setInitialized(true);
  }, []);
  if (!initialized) {
    return null;
  }
  return (
    <ConnectionsProvider>
      <StoresProvider>
        <LocalizationProvider>
          <ProviderBridge>
            <SnackbarProvider>
              <NavigationContainer />
            </SnackbarProvider>
          </ProviderBridge>
        </LocalizationProvider>
      </StoresProvider>
    </ConnectionsProvider>
  );
}

export default App;
