import React,
{
  useState,
} from 'react';
import {
  Typography,
} from '@material-ui/core';
import {
  // useLocalization,
  useStores,
} from 'hooks';
import {
  Assets,
} from 'assets';
import {
  useObserver,
} from 'mobx-react';
import {
  useHistory,
} from 'react-router';
import i18n from 'i18n-js';
// import {
//   LanguagesKey,
// } from 'src/translations/types';
import {
  Styles,
} from './styles';

export const HeaderMenu = () => {
  const {
    backend: {
      auth,
    },
  } = useStores();
  const [
    displayMenu,
    setDisplayMenu,
  ] = useState(false);
  const handleClick = () => {
    setDisplayMenu(!displayMenu);
  };
  const history = useHistory();
  const useStyles = Styles;
  const classes = useStyles();
  // const navigateToChangePassword = () => {
  //   history.push('/forget-password');
  // };
  const logout = () => {
    auth.logout();
    history.replace('/');
  };
  // const {
  //   currentLanguage,
  //   // setLanguage,
  // } = useLocalization();
  // const changeLanguage = async () => {
    // const lang = currentLanguage.key === LanguagesKey.AR ? LanguagesKey.EN : LanguagesKey.AR;
    // await updateUser({
    //   appLanguage: lang,
    // });
    // setLanguage({
    //   isRTL: currentLanguage.key !== LanguagesKey.AR,
    //   key: lang,
    // });
  // };
  return useObserver(() => (
    <div
      className={classes.images}
      role="button"
      onClick={handleClick}
      onKeyDown={handleClick}
      tabIndex={0}
    >
      <img
        src={Assets.images.common.bell}
        alt="Notifications"
        className={classes.bell}
      />
      <div className={classes.avatarContainer}>
        <img
          src={auth.currentUser.avatar}
          alt={auth.currentUser.name}
          className={classes.avatar}
        />
        <Typography
          className={classes.name}
        >
          {auth.currentUser.name}
        </Typography>
        <img
          src={Assets.images.common.chevronBottom}
          alt={auth.currentUser.name}
          className={classes.chevronBottom}

        />
      </div>
      { displayMenu && (
      <ul className={classes.optionsContainer}>
        {/* <li className={classes.li}>
          <div
            role="button"
            onClick={navigateToChangePassword}
            onKeyDown={navigateToChangePassword}
            tabIndex={0}
          >
            Change password
          </div>
        </li> */}
        {/* <li className={classes.li}>
          <div
            role="button"
            onClick={changeLanguage}
            onKeyDown={changeLanguage}
            tabIndex={0}
          >
            {i18n.t('CHANGE_LANGUAGE')}
          </div>
        </li> */}
        <li className={classes.li}>
          <div
            role="button"
            onClick={logout}
            onKeyDown={logout}
            tabIndex={0}
          >
            {i18n.t('LOGOUT')}
          </div>
        </li>
      </ul>
      )}
    </div>
  ));
};
