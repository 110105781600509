import React, {
  useState,
  useEffect,
} from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Input,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {
  isNil,
} from 'lodash';
import {
  NumberRangeFilterProps,
} from './types';

const formatNumberDefault = (number: number) => `${number}`;

const NumberRangeFilter = <RowData extends Record<string, unknown>>(props: NumberRangeFilterProps<RowData>) => {
  const {
    columnDef,
    onFilterChanged,
  } = props;

  const {
    tableData,
    filterProps = {},
  } = columnDef;

  const {
    formatNumber = formatNumberDefault,
  } = filterProps;

  const [modalOpen, setModalOpen] = useState(false);
  const [startNumber, setStartNumber] = useState<number>(0);
  const [endNumber, setEndNumber] = useState<number>(0);
  const [filter, setFilter] = useState<{
    from: number;
    to: number;
  } | undefined>();

  const openDialog = () => {
    setModalOpen(true);
  };

  const closeDialog = () => {
    setModalOpen(false);
  };

  const clearFilter = () => {
    setFilter(undefined);
    onFilterChanged(tableData.id.toString(), undefined);
  };

  const resetModalValues = () => {
    if (filter) {
      setStartNumber(filter.from);
      setEndNumber(filter.to);
    } else {
      setStartNumber(0);
      setEndNumber(0);
    }
  };

  const onFromValueChange = (number: number) => {
    if (!isNil(number) && !Number.isNaN(number)) {
      setStartNumber(number);
      if (endNumber < number) {
        setEndNumber(number);
      }
    }
  };

  const onToValueChange = (number: number) => {
    if (!isNil(number) && !Number.isNaN(number)) {
      setEndNumber(number);
      if (number < startNumber) {
        setStartNumber(number);
      }
    }
  };

  const onPressOpenFilter = () => {
    openDialog();
  };

  const onPressClear = () => {
    setStartNumber(0);
    setEndNumber(0);
    clearFilter();
  };

  const onPressCancel = () => {
    closeDialog();
  };

  const onPressFilter = () => {
    const newFilter = {
      from: startNumber,
      to: endNumber,
    };
    closeDialog();
    setFilter(newFilter);
    onFilterChanged(columnDef.tableData.id.toString(), newFilter);
  };

  const onDialogClose = () => {
    closeDialog();
  };

  useEffect(() => {
    if (!modalOpen) {
      resetModalValues();
    }
  }, [
    modalOpen,
  ]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={onPressOpenFilter}
        >
          <Typography variant="button">
            {
              (filter)
                ? (
                  `From: ${formatNumber(filter.from)}\nTo: ${formatNumber(filter.to)}`
                )
                : (
                  'Select Range'
                )
            }
          </Typography>
        </Button>
        {
          !!filter && (
            <IconButton
              onClick={onPressClear}
            >
              <ClearIcon />
            </IconButton>
          )
        }
      </div>
      <Dialog
        open={modalOpen}
        onClose={onDialogClose}
        maxWidth={false}
      >
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Input
              type="number"
              value={startNumber}
              onChange={(e) => onFromValueChange(e.target.value ? parseInt(e.target.value, 10) : 0)}
            />
            <Input
              type="number"
              value={endNumber}
              onChange={(e) => onToValueChange(e.target.value ? parseInt(e.target.value, 10) : 0)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onPressFilter}
          >
            <Typography>Filter</Typography>
          </Button>
          <Button
            onClick={onPressCancel}
          >
            <Typography>Cancel</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NumberRangeFilter;
