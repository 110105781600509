import {
  Button,
} from '@material-ui/core';
import {
  baseScreen,
} from 'hoc';
import React,
{
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import ComponentWithLoading from 'src/components/ComponentWithLoading';
import {
  Doctor,
} from '../doctors/types';

const syndicateIdVerification: React.FC = (() => {
  const history = useHistory();
  const {
    id,
  } = useParams() as any;
  const {
    backend: {
      doctors: {
        approveSyndicateID,
        getOne,
      },
    },
  } = useStores();
  const [
    data,
    setData,
  ] = useState<Doctor>();
  useEffect(() => {
    const load = async () => {
      const res = await getOne(id);
      setData(res);
    };
    load();
  }, []);
  return (
    <ComponentWithLoading
      isLoading={!data?.newSyndicateIdURL}
    >
      <img
        src={data?.newSyndicateIdURL}
        alt=""
        style={{
          width: 800,
          height: 500,
          display: 'block',
          margin: 'auto',
          marginTop: 50,
        }}
      />
      <div
        style={{
          marginTop: 40,
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Button
          variant="contained"
          onClick={async () => {
            await approveSyndicateID(id);
            history.replace('/users/:id/service-providers/doctors');
          }}
          color="primary"
          style={{
            width: 400,
          }}
        >
          {i18n.t('APPROVE')}
        </Button>
      </div>
    </ComponentWithLoading>
  );
});

export const SyndicateIdVerification = baseScreen(syndicateIdVerification, {
  allowedRoles: ['ADMIN', 'NONE'],
});
