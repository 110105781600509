import {
  FieldFile,
  FieldFileCommonTypes,
} from 'src/components/GeneralFormView/FormField/types';
import {
  InsuranceCompaniesDTO,
} from 'src/stores/backend/InsuranceCompaniesStore/types';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  BaseFormViewModel,
} from '../types';

export class InsuranceCompanyFormViewModel extends BaseFormViewModel<InsuranceCompaniesDTO> {
  id?: number;

  englishName?: string;

  arabicName?: string;

  logo: FieldFile[];

  constructor(data: InsuranceCompaniesDTO) {
    super();
    this.id = data.id;
    this.englishName = data.translations?.filter((item) => item.languageCode === LanguagesKey.EN)[0]?.title;
    this.arabicName = data.translations?.filter((item) => item.languageCode === LanguagesKey.AR)[0]?.title;
    if (data.logoURL) {
      this.logo = [{
        source: data.logoURL,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
  }

  toDTO(): InsuranceCompaniesDTO {
    return { } as InsuranceCompaniesDTO;
  }
}
