import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({
  container: {
    paddingTop: 10,
    paddingBottom: 10,
    minWidth: 'calc(100vw - 308px)',
    height: 108,
    marginLeft: 4,
  },
  logo: {
    height: 120,
    marginLeft: 20,
    marginRight: 20,
  },
  header: {
    flex: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flex: 1,
    borderBottomColor: '#AAA',
    borderBottomWidth: 3,
    borderBottomStyle: 'dashed',
  },
  searchContainer: {
    alignSelf: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  search: {
    position: 'relative',
  },
  searchIcon: {
    height: 19,
    position: 'absolute',
    top: 6,
    left: 6,
  },
  navItems: {
    marginTop: 16,
    marginLeft: 48,
    marginRight: 48,
    display: 'flex',
    position: 'relative',
  },
  navItem: {
    marginRight: 20,
    display: 'flex',
  },
  searchInput: {
    height: 24,
    width: 300,
    paddingLeft: 28,
    borderRadius: 25,
  },
  itemName: {
    textDecoration: 'none',
    fontWeight: 600,
    color: 'black',
    marginRight: 12,
    cursor: 'pointer',
  },
  iconDown: {
    height: 16,
    marginTop: 4,
  },
  menuItemsContainer: {
    width: 200,
    position: 'absolute',
    borderWidth: 1,
    borderColor: '#DDD',
    borderStyle: 'solid',
    background: 'white',
    flexDirection: 'column',
    zIndex: 20,
  },
  menuItem: {
    cursor: 'pointer',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    borderBottomStyle: 'solid',
    paddingRight: 20,
    borderBottomWidth: 1,
    borderColor: '#DDD',
    '&:hover': {
      backgroundColor: '#DDD',
    },
  },
}));
