import React from 'react';
import Spinner from '../Spinner';
import {
  ComponentWithLoadingProps,
} from './types';

const ComponentWithLoading: React.FC<ComponentWithLoadingProps> = (props) => {
  const {
    isLoading,
    children,
  } = props;
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return children;
};

export default ComponentWithLoading;
