import {
  Fonts,
} from './fonts';
import {
  Images,
} from './images';

export class Assets {
  static fonts = new Fonts();

  static images = new Images();
}
