/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  BaseBackendStore,
} from '../types';
import {
  grades,
  grade,
} from './requests';
import {
  GradesDTO,
} from './types';
import {
  BackendStores,
} from '..';

export class GradesStore extends BaseBackendStore {
  @observable private _entityList: GradesDTO[] = [];

  @action public getAll = async (options: any): Promise<GradesDTO[]> => {
    console.log(options);
    const response = await this.connections.backend
      .httpGet(`${grades}`);
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: GradesDTO;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: GradesDTO): Promise<void> {
    try {
      await this.connections.backend.httpPost(grades, data);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  }

  @action
  public async getOne(id: string): Promise<ModelOf<GradesDTO>> {
    const response = await this.connections.backend.httpGet(grade(parseInt(id, 10)));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action updateGrade = async (id: number, data: Partial<GradesDTO>) => {
    try {
      const response = await this.connections.backend.httpPut(grade(id), data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  };

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(grade(id));
    return Promise.resolve(response);
  }
}
