import {
  action,
  observable,
} from 'mobx';
import {
  persist,
} from 'mobx-persist';
import {
  BackendStores,
} from '..';
import {
  User,
} from '../auth/types';
import {
  BaseBackendStore,
} from '../types';
import {
  adminUri,
  usersRoles,
} from './requests';

export class UserStore extends BaseBackendStore {
  @persist @observable role: 'ACCOUNTANT' | 'ADMIN' | 'USER' | 'GUEST' | 'NONE' = 'NONE';

  @observable roles: string[] = [];

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
  }

  @action setRole(role: 'ACCOUNTANT' | 'ADMIN' | 'USER' | 'GUEST' | 'NONE') {
    this.role = role;
  }

  @action updateUser = async (data: Partial<User>) => {
    await this.connections.backend.httpPut(adminUri(this.parent.auth.currentUser.id), data);
  };

  @action getRoles = async () => {
    this.roles = await this.connections.backend.httpGet(usersRoles());
  };
}
