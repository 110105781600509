import LuxonUtils from '@date-io/luxon';
import * as Luxon from 'luxon';
import { } from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  getIn,
  useFormikContext,
} from 'formik';
import React, {
  useEffect,
} from 'react';
import {
  isDateOnlyPicker,
  isTimeOnlyPicker,
} from '../../types';
import FieldError from '../../Utils/FieldError';
import {
  FieldView,
} from '../../Utils/FieldView';
import {
  DateTimeFieldValue,
  FieldPropsCommon,
} from '../types';
import {
  onChange,
} from '../utils';

type Props = FieldPropsCommon & DateTimeFieldValue;

const DateTimeField = <FormModel extends object>(props: Props) => {
  const {
    fieldOptions,
    location,
    title,
    viewMode,
    disabled,
  } = props;

  const {
    values,
    handleChange,
    isSubmitting,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext<FormModel>();
  const value: Date = getIn(values, location);
  const onValueChange = onChange(handleChange, location);
  const error = getIn(errors, location);
  const isTouched = getIn(touched, location);

  useEffect(() => {
    if (isSubmitting) {
      setFieldTouched(location);
    }
  }, [isSubmitting, location, setFieldTouched]);

  if (viewMode) {
    return (
      <FieldView
        title={title}
        value={() => (
          <div>
            {
            isTimeOnlyPicker(fieldOptions) && !isDateOnlyPicker(fieldOptions) && (
              Luxon.DateTime.fromJSDate(value).toLocaleString(Luxon.DateTime.TIME_SIMPLE as any)
            )
          }
            {
            !isTimeOnlyPicker(fieldOptions) && isDateOnlyPicker(fieldOptions) && (
              Luxon.DateTime.fromJSDate(value).toLocaleString(Luxon.DateTime.DATE_MED_WITH_WEEKDAY as any)
            )
          }
            {
            !isTimeOnlyPicker(fieldOptions) && !isDateOnlyPicker(fieldOptions) && (
              Luxon.DateTime.fromJSDate(value).toLocaleString(Luxon.DateTime.DATETIME_MED as any)
            )
          }
          </div>
        )}
      />
    );
  }

  return (
    <div
      style={{
        margin: 8,
      }}
    >
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <>
          {
            !isTimeOnlyPicker(fieldOptions) && (
              <KeyboardDatePicker
                label={title}
                value={value}
                disabled={disabled}
                onBlur={() => setFieldTouched(location)}
                {...(fieldOptions.datePickerProps ? fieldOptions.datePickerProps : {})}
                onChange={(date) => {
                  if (date) {
                    onValueChange(date);
                    if (fieldOptions.onValueChanged) {
                      fieldOptions.onValueChanged(date);
                    }
                  }
                }}
              />
            )
          }
          {
            !isDateOnlyPicker(fieldOptions) && (
              <KeyboardTimePicker
                {...(fieldOptions.timePickerProps ? fieldOptions.timePickerProps : {})}
                label={`${title} Time Picker`}
                value={value}
                disabled={disabled}
                onBlur={() => setFieldTouched(location)}
                onChange={(date) => {
                  if (date) {
                    onValueChange(date);
                  }
                }}
              />
            )
          }
          <FieldError
            errors={error}
            touched={isTouched}
          />
        </>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DateTimeField;
