import React,
{
  useRef,
} from 'react';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  Assets,
} from 'assets';
import {
  baseScreen,
} from 'hoc';
import {
  Request,
  RequestTable,
} from 'src/stores/backend/RequestsStore/types';
import GeneralTableView from 'src/components/GeneralTableView';
import SearchFilterDropdown from 'src/components/SearchFilterComponent';
import i18n from 'i18n-js';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const requests: React.FC = () => {
  const {
    backend: {
      requests: requestsData,
    },
  } = useStores();
  const ref = useRef<any|null>(null);
  return (
    <div>
      <SearchFilterDropdown
        label={i18n.t('STATUS')}
        style={{
          margin: 16,
        }}
        searchFilterDropdown={{
          filters: [
            {
              name: i18n.t('ALL'),
              value: 'all',
            },
            {
              name: i18n.t('HOME_VISITS'),
              value: 'Home Visit',
            }, {
              name: i18n.t('ONLINE_CONSULTATION'),
              value: 'Online Consultation',
            },
            {
              name: i18n.t('CLINICS'),
              value: 'Clinic',
            },
          ],
          onChange: (val) => {
            const {
              filter,
            } = requestsData;
            if (val === 'all') {
              const newFilter = {
                ...filter,
                filters: filter.filters?.filter((item) => item.by !== 'consultation_type'),
              };
              requestsData.updateFilter(newFilter);
            } else {
              requestsData.updateFilter({
                ...filter,
                filters: [...filter?.filters as any || [], {
                  by: 'consultation_type',
                  value: val,
                  type: 'FIXED',
                }],
              });
            }
            ref.current?.onQueryChange();
          },
          value: 'all',
          initialValue: 'all',
        }}
      />

      <GeneralTableView<RequestTable>
        tableProps={{
          tableRef: ref,
          title: i18n.t('SERVICES'),
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (event, data) => {
                console.log('VIEW:', data);
              },
            },
            {
              isFreeAction: true,
              icon: ImportExportIcon,
              onClick: () => {
                window.open(requestsData.download(), '_blanck');
              },
              tooltip: i18n.t('EXPORT_DATA'),
            },
          ],
        }}
        options={{
          search: true,
          filtering: false,
          pageSize: 10,
          actionsColumnIndex: 15,
        }}
        get={async (query) => {
          await requestsData.entityList.updateOptions(query);
          return {
            data: toJS(requestsData.entityList.data)?.map((item) => Request.toTableData(item)),
            totalCount: requestsData.entityList.meta?.itemCount,
            page: requestsData.entityList.meta?.page - 1,
          };
        }}
        tableColumns={[
          {
            field: 'id',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'consultation_type',
            title: i18n.t('TYPE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'date',
            title: i18n.t('DATE'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'time',
            title: i18n.t('TIME'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'serviceProvider',
            title: i18n.t('SERVICE_PROVIDER'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'status',
            title: i18n.t('STATUS'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'doctor',
            title: i18n.t('DOCTOR'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'doctorMobileNumber',
            title: i18n.t('DOCTOR_PHONE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'patient',
            title: i18n.t('PATIENT'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'patientMobileNumber',
            title: i18n.t('PATIENT_PHONE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const Requests = baseScreen(requests, {
  allowedRoles: ['NONE', 'ADMIN'],
});
